import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fetchAgents, fetchLeadSource, fetchLeadStatus, fetchLeadTypes, fetchLocations, fetchProbability } from '../ec-leads-helper';
import { useLeadFilters } from '../../context/eclead-filter-context';
import { UserDetailsContext } from '../../context/user-details-context';

function ECLeadFilters({ setCurrentPage, setGoToPage, openFilters, setOpenFilters, setFilterApply, setSearchString,
    setSearchType, setLeadStatus, setLeadSource, setLeadType, setProposalSent, setStatusFrom, setLocation, setProbability, setAgent
}) {
    const theme = useTheme();
    const { loggedUser } = useContext(UserDetailsContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [filterValueChange, setFilterValueChange] = useState(false);
    const [leadSourceList, setLeadSourceList] = useState([]);
    const [leadTypeList, setLeadTypeList] = useState([]);
    const [dbLocationList, setDBLocationsList] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [probabilityList, setProbabilityList] = useState([]);
    const [leadStatusList, setLeadStatusList] = useState([]);
    const proposalSentOption = [
        { id: 3, proposalSent: "Any Status" },
        { id: 1, proposalSent: "Yes" },
        { id: 2, proposalSent: "No" }
    ];
    const statusFromOption = [
        { id: 8, option: "Any days" },
        { id: 1, option: "Within 3 days" },
        { id: 2, option: "Within 10 days" },
        { id: 3, option: "Within 15 days" },
        { id: 4, option: "Within 30 days" },
        { id: 5, option: "Within 60 days" },
        { id: 6, option: "Within 90 days" },
        { id: 7, option: "More than 90 days" }
    ];

    const { leadFilters, setLeadFilters } = useLeadFilters();

    const leadsFilterTextField = [
        { id: 1, label: 'Status', value: leadFilters.leadStatus, name: 'leadStatus', optionName: 'status', tabIndex: 3 },
        { id: 2, label: 'Source', value: leadFilters.leadSource, name: 'leadSource', optionName: 'source', tabIndex: 5 }
    ];

    useEffect(() => {
        fetchLeadSource(setLeadSourceList);
        fetchLeadTypes(setLeadTypeList);
        fetchLeadStatus(setLeadStatusList);
        fetchLocations(setDBLocationsList);
        fetchAgents(setAgentsList);
        fetchProbability(setProbabilityList);
    }, []);

    const handleLeadFilterChange = (fieldName, value) => {
        setFilterValueChange(true);
        setLeadFilters((leadFilters) => {
            return {
                ...leadFilters,
                [fieldName]: value
            };
        });
    };

    const clearLeadFilter = () => {
        setLeadFilters({
            searchString: "", searchType: "", leadStatus: "", leadSource: "", leadType: "",
            proposalSent: "", statusFrom: null, location: "", probability: "", agent: "", filterApply: false
        });
        setSearchString(null); setSearchType(null); setLeadStatus(null); setLeadSource(null);
        setLeadType(null); setProposalSent(null); setStatusFrom(null); setLocation(null);
        setProbability(null); setAgent(null); setFilterApply(false);
        setCurrentPage(1); setGoToPage(1);
    }

    const applyLeadFilters = () => {
        setLeadFilters({
            ...leadFilters,
            filterApply: true
        });
        setSearchString(leadFilters.searchString); setSearchType(leadFilters.searchType);
        setLeadStatus(leadFilters.leadStatus); setLeadSource(leadFilters.leadSource);
        setLeadType(leadFilters.leadType); setProposalSent(leadFilters.proposalSent);
        setStatusFrom(leadFilters.statusFrom); setLocation(leadFilters.location);
        setProbability(leadFilters.probability); setAgent(leadFilters.agent);
        setFilterApply(true); setCurrentPage(1); setGoToPage(1); setOpenFilters(false); setFilterValueChange(false);
    }

    return (
        <Dialog fullScreen={fullScreen} open={openFilters} fullWidth maxWidth='sm'
            onClose={() => setOpenFilters(false)} aria-labelledby="responsive-dialog-title">
            <DialogTitle sx={{ padding: '28px 30px', color: '#3b4758', fontFamily: 'DM Sans', fontSize: '1.5rem', fontWeight: 600, opacity: 1 }} id="responsive-dialog-title">
                Filters
            </DialogTitle>
            <IconButton aria-label="close" onClick={() => setOpenFilters(false)}
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ padding: '30px 30px 15px', paddingTop: '0px' }}>
                <>
                    <Typography gutterBottom sx={{ paddingBottom: '12px', fontFamily: 'DM Sans', fontSize: '1rem', color: '#000000', fontWeight: 500 }}>Search By</Typography>
                    <div className='ec-lead-filter-row'>
                        <div className='ec-lead-filter-col'>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">{<span>Search by<span style={{ color: 'red' }}>*</span></span>}</InputLabel>
                                <Select tabIndex={1} labelId="demo-simple-select-label" id="demo-simple-select" label="Search by"
                                    name="searchType" value={leadFilters.searchType} onChange={(e) => handleLeadFilterChange("searchType", e.target.value)}>
                                    <MenuItem value="Full Name">Full Name (Starts with)</MenuItem>
                                    <MenuItem value="Company Name">Company Name (Starts with)</MenuItem>
                                    <MenuItem value="Contact Number">Contact Number</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='ec-lead-filter-col'>
                            <TextField tabIndex={2} id="outlined-required" label={<span>Search Text<span style={{ color: 'red' }}>*</span></span>}
                                name="searchString" value={leadFilters.searchString} onChange={(e) => handleLeadFilterChange("searchString", e.target.value)}
                                inputProps={{ maxLength: 100 }} />
                        </div>
                    </div>
                </>
                <>
                    <Typography gutterBottom sx={{ padding: '15px 0px', fontFamily: 'DM Sans', fontSize: '1rem', color: '#000000', fontWeight: 500 }}>Filter By</Typography>
                    <div className='ec-lead-filter-row'>
                        <div className='ec-lead-filter-col'>
                            {leadsFilterTextField.map((filterBy, index) => (
                                <FormControl key={index}>
                                    <InputLabel id="demo-simple-select-label">{filterBy.label}</InputLabel>
                                    <Select tabIndex={filterBy.tabIndex} labelId="demo-simple-select-label" id="demo-simple-select" label={filterBy.label}
                                        name={filterBy.name} value={leadFilters[filterBy.name]} onChange={(e) => handleLeadFilterChange(filterBy.name, e.target.value)} >
                                        <MenuItem key={index} value={`Any ${filterBy.label}`}>{`Any ${filterBy.label}`}</MenuItem>
                                        {(filterBy.name === 'leadSource' ? leadSourceList :
                                            filterBy.name === 'leadStatus' ? leadStatusList : []).map((filterOption, index) => (
                                                <MenuItem key={index} value={filterOption[filterBy.optionName]}>{filterOption[filterBy.optionName]}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            ))}
                        </div>
                        <div className='ec-lead-filter-col'>
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Status From</InputLabel>
                                <Select tabIndex={4} labelId="demo-simple-select-label" id="demo-simple-select" label="Status From"
                                    name="statusFrom" value={leadFilters.statusFrom} onChange={(e) => handleLeadFilterChange("statusFrom", e.target.value)}>
                                    {statusFromOption.map((statusFrom, index) => (
                                        <MenuItem key={index} value={statusFrom.option}>{statusFrom.option}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Lead Type</InputLabel>
                                <Select tabIndex={6} labelId="demo-simple-select-label" id="demo-simple-select" label="Lead Type"
                                    name="leadType" value={leadFilters.leadType} onChange={(e) => handleLeadFilterChange("leadType", e.target.value)}>
                                    <MenuItem value='Any Lead Type'>Any Lead Type</MenuItem>
                                    {leadTypeList.map((leadType, index) => (
                                        <MenuItem key={index} value={leadType.leadtype}>{leadType.leadtype}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className='ec-lead-filter-location-row'>
                        <Autocomplete tabIndex={7} disablePortal id="combo-box-demo" name="location" value={dbLocationList.find(
                            (option) => `${option.city} - ${option.state}` === leadFilters.location
                        ) || null} options={dbLocationList}
                            onChange={(e, value) => handleLeadFilterChange('location', value ? `${value.city} - ${value.state}` : null)}
                            getOptionLabel={(option) => `${option.city} - ${option.state}`}
                            renderInput={(params) => <TextField {...params} label='Location' />}
                        />
                    </div>

                    <div className='ec-lead-filter-row'>
                        <div className='ec-lead-filter-col'>
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Probability</InputLabel>
                                <Select tabIndex={9} labelId="demo-simple-select-label" id="demo-simple-select" label="Probability"
                                    name="probability" value={leadFilters.probability} onChange={(e) => handleLeadFilterChange("probability", e.target.value)}>
                                    <MenuItem value="Any Probability">Any Probability</MenuItem>
                                    {probabilityList.map((probability, index) => (
                                        <MenuItem key={index} value={probability.probabilityStatus}>{probability.probabilityStatus}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='ec-lead-filter-col'>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Proposal Sent</InputLabel>
                                <Select tabIndex={8} labelId="demo-simple-select-label" id="demo-simple-select" label='Proposal Sent'
                                    name='proposalSent' value={leadFilters.proposalSent} onChange={(e) => handleLeadFilterChange('proposalSent', e.target.value)} >
                                    {proposalSentOption.map((proposalSent, index) => (
                                        <MenuItem key={index} value={proposalSent.proposalSent}>{proposalSent.proposalSent}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    {loggedUser && loggedUser.role !== 'LEAD_AGENT' &&
                        <div className='ec-lead-filter-location-row'>
                            <Autocomplete tabIndex={10} disablePortal id="combo-box-agent" name="agent" value={agentsList.find(
                                (option) => option.id === leadFilters.agent
                            ) || null} options={agentsList}
                                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                onChange={(e, value) => handleLeadFilterChange("agent", value ? value.id : null)}
                                renderInput={(params) => <TextField {...params} label="Agent" />}
                            />
                        </div>
                    }
                </>
            </DialogContent>
            <DialogActions sx={{ padding: '12px 30px 28px' }}>
                <Button autoFocus variant="outlined" onClick={clearLeadFilter} sx={{
                    color: '#e563a4', textTransform: 'capitalize', '&:hover': { color: '#e326a4' }
                }} disabled={leadFilters.searchType === "" && leadFilters.searchString === "" && leadFilters.leadStatus === "" &&
                    leadFilters.leadSource === "" && leadFilters.leadType === "" && leadFilters.proposalSent === "" &&
                    leadFilters.statusFrom === null && (leadFilters.location === "" || leadFilters.location === null) && leadFilters.probability === "" && leadFilters.agent === ""}>
                    Clear Filter
                </Button>
                <Button autoFocus variant="contained" onClick={applyLeadFilters} sx={{
                    backgroundColor: '#e563a4', textTransform: 'capitalize', '&:hover': { backgroundColor: '#e326a4' }
                }} disabled={!filterValueChange || (leadFilters.searchType !== "" && leadFilters.searchString === "") ||
                    (leadFilters.searchType === "" && leadFilters.searchString !== "")}>
                    Search and Apply Filters
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ECLeadFilters;
