import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';

const { baseUrl } = getConfig();

export const fetchUserList = async (setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setStateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount) => {
    setIsLoading(true);
    setApiFailed(false);
    try {
        let requestBody = {
            pageNo: currentPage,
            pageSize: recordsPerPage,
        };
        if (privateInstaAccounts != null) {
            requestBody.privateInstaAccounts = privateInstaAccounts;
        }
        if (incompleteRegistration != null) {
            requestBody.incompleteRegistration = incompleteRegistration;
        }
        if (selectedCities.length > 0) {
            requestBody.cities = selectedCities;
        }
        if (selectedStates.length > 0) {
            requestBody.states = selectedStates;
        }
        if (selectedCategories.length > 0) {
            requestBody.areaOfInterests = selectedCategories;
        }
        if (followersCountFrom != null && followersCountFrom !== "") {
            requestBody.followersFrom = followersCountFrom;
        }
        if (followersCountTo != null && followersCountTo !== "") {
            requestBody.followersTo = followersCountTo;
        }
        if (creationTimeRangeFrom != null && creationTimeRangeFrom !== "") {
            requestBody.fromDate = creationTimeRangeFrom;
        }
        if (creationTimeRangeTo != null && creationTimeRangeTo !== "") {
            requestBody.toDate = creationTimeRangeTo;
        }

        if (searchString != null && searchString !== "") {
            requestBody.searchString = searchString;
        }

        setCityShowFilterAltOffIcon(selectedCities.length > 0);
        setStateShowFilterAltOffIcon(selectedStates.length > 0);
        setCategoryShowFilterAltOffIcon(selectedCategories.length > 0);
        const response = await fetch(`${baseUrl}/admin/get-users`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        if (response.ok) {
            if (data.message === "No filtered data found.") {
                setUserList([]);
                setTotalCount(0);
            } else {
                setUserList(data.users);
                setTotalCount(data.totalCount);
            }
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error("Error fetching user list:", data.message);
        }
    } catch (error) {
        console.error("Error fetching user list:", error);
    } finally {
        setIsLoading(false);
        setApiFailed(true);
    }
};

export const fetchEnterpriseClients = async (setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setStateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount) => {
    setIsLoading(true);
    setApiFailed(false);
    try {
         let requestBody = {
            pageNo: currentPage,
            pageSize: recordsPerPage,
        };
        if (privateInstaAccounts != null) {
            requestBody.privateInstaAccounts = privateInstaAccounts;
        }
        if (incompleteRegistration != null) {
            requestBody.incompleteRegistration = incompleteRegistration;
        }
        if (selectedCities.length > 0) {
            requestBody.cities = selectedCities;
        }
        if (selectedStates.length > 0) {
            requestBody.states = selectedStates;
        }
        if (selectedCategories.length > 0) {
            requestBody.areaOfInterests = selectedCategories;
        }
        if (followersCountFrom != null && followersCountFrom !== "") {
            requestBody.followersFrom = followersCountFrom;
        }
        if (followersCountTo != null && followersCountTo !== "") {
            requestBody.followersTo = followersCountTo;
        }
        if (creationTimeRangeFrom != null && creationTimeRangeFrom !== "") {
            requestBody.fromDate = creationTimeRangeFrom;
        }
        if (creationTimeRangeTo != null && creationTimeRangeTo !== "") {
            requestBody.toDate = creationTimeRangeTo;
        }

        if (searchString != null && searchString !== "") {
            requestBody.searchString = searchString;
        }

        setCityShowFilterAltOffIcon(selectedCities.length > 0);
        setStateShowFilterAltOffIcon(selectedStates.length > 0);
        setCategoryShowFilterAltOffIcon(selectedCategories.length > 0);
        const response = await fetch(
            `${baseUrl}/admin/get-enterprise-clients`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                body: JSON.stringify(requestBody),

            });

        const data = await response.json();
        if (response.ok) {
            if (data.message === "No filtered data found.") {
                setUserList([]);
                setTotalCount(0);
            } else {
                setUserList(data.users||[]);
                setTotalCount(data.totalCount||0);
            }
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error(
                "Error fetching enterprise client:",
                response.message
            );
        }
    } catch (error) {
        console.error("Error fetching enterprise client:", error);
    } finally {
        setIsLoading(false);
    }
};

export const downloadUserDetailsAsCSV = async (privateInstaAccounts, incompleteRegistration, setCsvLoading, selectedCities, selectedStates, followersCountFrom, followersCountTo, selectedCategories, searchString, fromDate, toDate) => {
    setCsvLoading(true);
    try {
        const filters = {
            privateInstaAccounts,
            incompleteRegistration,
            cities: selectedCities,
            states: selectedStates,
            followersFrom: followersCountFrom || '',
            followersTo: followersCountTo || '',
            areaOfInterests: selectedCategories,
            searchString,
            fromDate: fromDate || null,
            toDate: toDate || null
        };

        const response = await fetch(`${baseUrl}/admin/users/download`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(filters),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "userdetails.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading user details as CSV:', error);
    } finally {
        setCsvLoading(false);
    }
};