import { getConfig } from '../common/env-config';

const { baseUrl, appId } = getConfig();

export const initOTPless = () => {
    const otplessInit = Reflect.get(window, 'otplessInit');
    const loadScript = () => {
        const script = document.createElement("script")
        script.src = "https://otpless.com/v2/auth.js";
        script.id = "otpless-sdk";
        script.setAttribute("data-appid", appId);
        document.body.appendChild(script)
    }
    if (otplessInit) {
        otplessInit()
    }
    else {
        loadScript()
    }

    window.otpless = (otplessUser) => {
        const currentURL = window.location.href;
        const urlWithoutParams = currentURL.split("?")[0];
        window.history.pushState(null, "", urlWithoutParams);
        const phoneNumber = otplessUser.identities.map(identity => identity.identityValue).join(', ');

        const { token } = otplessUser;

        fetch(`${baseUrl}/get-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phoneNumber: phoneNumber,
                token: token,
                source: localStorage.getItem("source")
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                localStorage.setItem('phoneNumber', phoneNumber);
                localStorage.setItem("accessToken", data.accessToken);
                localStorage.setItem("refreshToken", data.refreshToken);
                localStorage.setItem("profileExist", data.profileExist);
                localStorage.setItem("role", data.role);
                localStorage.setItem("access", data.access);

                if (data.profileExist === false) {
                    window.location.href = '/login';
                } else {
                    if (data.role === 'ADMIN') {
                        window.location.href = "/admin-dashboard";
                    } else if (data.role === 'CREATOR') {
                        window.location.href = "/dashboard";
                    } else if (data.role === 'ENTERPRISE_CLIENT') {
                        window.location.href = "/discovery";
                    } else if (data.role === 'LEAD_AGENT') {
                        window.location.href = "/enterprise-client/ec-leads-dashboard";
                    } else if (data.role === 'LEAD_MANAGER') {
                        window.location.href = "/enterprise-client/ec-leads-dashboard";
                    } else {
                        window.location.href = "/error";
                    }
                }
            })
            .catch(error => {
                console.error('Error in verifying token:', error);
                alert('Verification failed. Please try again.');
            });
    };
}