import React, { createContext, useState, useEffect } from 'react';
import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';

const { baseUrl } = getConfig();

const UserDetailsContext = createContext();
const WindowWidthContext = createContext();

const UserDetailsProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${baseUrl}/user/get-user-profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("role", data.role);
        localStorage.setItem("access", data.access);
        setLoggedUser(data);
      } else if (response.status === 499) {
        refreshToken();
      }
    } catch (error) {
      console.error("Failed to fetch userdetails, Error: ", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <UserDetailsContext.Provider value={{ loggedUser, fetchUserDetails }}>
      {children}
    </UserDetailsContext.Provider>
  );
};

const WindowWidthProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowWidthContext.Provider value={windowWidth}>
      {children}
    </WindowWidthContext.Provider>
  );
};

export { UserDetailsProvider, UserDetailsContext, WindowWidthProvider, WindowWidthContext };