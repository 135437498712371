import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { initOTPless } from "../utils/initOtpless";
import logo from '../../assets/creator-logo.svg';
import "../../css/signin.css";
import "../../css/landing-page.css";
import signinImg from '../../assets/signin.png';
import { Backdrop, Box, Button, Fade, Modal, TextField, Typography } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { getConfig } from "../common/env-config";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { WindowWidthContext } from "../context/user-details-context";

const Signin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const windowWidth = useContext(WindowWidthContext);

  const queryParams = new URLSearchParams(location.search);
  let source = queryParams.get('source');
  const storedSource = localStorage.getItem('source');
  if (source != null && (!storedSource || source !== storedSource)) {
    if (source.includes('?')) {
      source = source.substring(0, source.indexOf('?'));
    }
    localStorage.setItem('source', source);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsValue = urlParams.get("ex");
    if (paramsValue) {
      openModal();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const appendParams = (param, value) => {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);

    if (!urlParams.has(param)) {
      urlParams.append(param, value);
    }
    url.search = urlParams.toString();
    window.history.pushState({}, '', url);
  }

  const openModal = () => {
    appendParams("ex", "true");
    initOTPless();
    const modalContainer = document.getElementById("modalContainer");
    const modal = document.getElementById("otpless-login-page");
    modalContainer.style.display = "flex";
    modal.style.display = modal.style.display === "block" ? "none" : "block";
  };

  const closeModal = (e) => {
    const currentURL = window.location.href;
    const urlWithoutParams = currentURL.split("?")[0];
    window.history.pushState(null, "", urlWithoutParams);
    const modalContainer = document.getElementById("modalContainer");
    if (e.target === modalContainer) {
      modalContainer.style.display = "none";
    }
  };

  const [openDevLogin, setOpenDevLogin] = useState(false);
  const handleDevLoginOpen = () => setOpenDevLogin(true);
  const handleDevLoginClose = () => setOpenDevLogin(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { baseUrl } = getConfig();

  const devLoginModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  };

  const getDevLoginToken = () => {
    fetch(`${baseUrl}/get-token-dev-login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phoneNumber: '91' + phoneNumber,
        source: localStorage.getItem("source")
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        localStorage.setItem('phoneNumber', '91' + phoneNumber);
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("profileExist", data.profileExist);
        localStorage.setItem("role", data.role);
        localStorage.setItem("access", data.access);

        if (data.profileExist === false) {
          window.location.href = '/login';
        } else {
          if (data.role === 'ADMIN') {
            window.location.href = "/admin-dashboard";
          } else if (data.role === 'CREATOR') {
            window.location.href = "/dashboard";
          } else if (data.role === 'ENTERPRISE_CLIENT') {
            window.location.href = "/discovery";
          } else if (data.role === 'LEAD_AGENT') {
            window.location.href = "/enterprise-client/ec-leads-dashboard";
          } else if (data.role === 'LEAD_MANAGER') {
            window.location.href = "/enterprise-client/ec-leads-dashboard";
          } else {
            window.location.href = "/error";
          }
        }
      })
      .catch(error => {
        console.error('Error in verifying token:', error);
        alert('Verification failed. Please try again.');
      });
  }
  const handleBackClick = () => {
    navigate(-1)
  }
  return (
    <div className="signin-container">
      <div className="left-signin">
        <div className="back-arrow-login-container">
          <ArrowBackIcon onClick={handleBackClick}></ArrowBackIcon>
        </div>
        <div className="signin-left-content">
          <div className="signintitle">Ditch DM pitches<br></br>Step smartly into Oye Creators</div>
          <div className="signintitle-content">Get thousands of voices for live marketing magic<br></br>Seize the chance to join India's premier no-commission creator portal</div>
          <div className="signin-campain-container">
            <img src={signinImg} alt="" className="signin-img"></img>
          </div>
        </div>
      </div>

      <div className="right-signin mobile">
        <div className="signin-backarrow">
          {windowWidth <= 799 &&
            <ArrowBackIcon onClick={handleBackClick}></ArrowBackIcon>
          }
        </div>
        <div className="signin-right-container">
          <div className="right-content">
            <div className="aaalogo">
              <img src={logo} alt="logo" className="creator-logo" />
            </div>
            <div className="signin-paragraph mobile">
              <span className="signin-text mobile">Go viral, get famous. Simple, quick, unforgettable</span>
              <br></br><br></br>
              Connect with your true audience and get them buzzing about you globally
              <br></br><br></br>
              <span>By Signing in, you agree to <a href="/terms-of-service" target="_blank" className="privacy-policy-link-text">Terms</a> and <a href="/privacy-policy" target="_blank" className="privacy-policy-link-text">Policy</a></span>
            </div>
            <div className="siginContainer">
              <div className="modal-container" id="modalContainer" onClick={closeModal}>
                <div className="modal">
                  <div id="otpless-login-page"></div>
                </div>
              </div>
              <div className="signin-button-container">
                <button className="login-button" onClick={openModal}>Login</button>
                <button className="login-button register-button" onClick={openModal}>Register</button>
              </div>
            </div>
            {(window.location.href.includes('localhost') || window.location.href.includes('development.oyecreators.com')) &&
              <div className="dev-login-container">
                <Button color="secondary" sx={{ borderRadius: '21px', padding: '9px 38px', textTransform: 'capitalize' }} variant="contained" onClick={handleDevLoginOpen}>Dev Login</Button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openDevLogin}
                  onClose={handleDevLoginClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={openDevLogin}>
                    <Box sx={devLoginModalStyle}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '600' }} id="transition-modal-title" variant="h5" component="h2">
                        OyeCreators Dev Login
                      </Typography>
                      <Typography sx={{ textAlign: 'center', marginTop: '15px' }} id="transition-modal-title" variant="h6" component="h2">
                        Let's Sign In
                      </Typography>
                      <TextField sx={{ marginTop: '15px' }} id="outlined-basic" label="Enter Phone" variant="outlined" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              91
                            </InputAdornment>
                          ),
                        }} inputProps={{ maxLength: 10 }} />
                      <Button sx={{
                        marginTop: '15px', backgroundColor: '#e563a4', borderRadius: '21px', padding: '9px 38px', textTransform: 'capitalize', '&:hover': {
                          backgroundColor: '#e326a4',
                        }
                      }} variant="contained" onClick={getDevLoginToken}>Continue</Button>
                    </Box>
                  </Fade>
                </Modal>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  );
};

export default Signin;