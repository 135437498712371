import { getConfig } from "../common/env-config";
import { refreshToken } from "../common/refresh-token-repo";
import moment from 'moment';

const { baseUrl } = getConfig();

export const getECLeadsOverview = async (setECLeadsOverview, setLoading) => {
    try {
        setLoading(false);
        let filters = {};

        const response = await fetch(`${baseUrl}/admin/ec-leads-overview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify(filters)
        });

        const data = await response.json();
        if (response.ok) {
            setECLeadsOverview({
                leadsInProgress: data.leadsInProgress,
                leadsWon: data.leadsWon,
                leadsLost: data.leadsLost,
                newLeads: data.newLeads
            });
            setLoading(false);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Failed To Fetch EC Leads Overview, Error: ', data.message);
        }
    } catch (error) {
        console.error('Failed To Fetch EC Leads Overview, Error: ', error);
    }
};

export const getECLeads = async (createData, setLoading, currentPage, rowsPerPage, setTotalECLeads, setECLeads, setApiFailed,
    searchString, searchType, leadStatus, leadSource, leadType, proposalSent, statusFrom, location, probability, agent, sortingType, sortBy
) => {
    try {
        setLoading(true);
        let filters = {
            pageNo: currentPage,
            pageSize: rowsPerPage
        };
        if (searchString != null && searchString !== "") {
            filters.searchString = searchString;
        }
        if (searchType != null && searchType !== "") {
            filters.searchType = searchType;
        }
        if (leadStatus != null && leadStatus !== "" && !leadStatus.includes('Any')) {
            filters.leadStatus = leadStatus;
        }
        if (leadSource != null && leadSource !== "" && !leadSource.includes('Any')) {
            filters.leadSource = leadSource;
        }
        if (leadType != null && leadType !== "" && !leadType.includes('Any')) {
            filters.leadType = leadType;
        }
        if (proposalSent != null && proposalSent !== "" && !proposalSent.includes('Any')) {
            filters.proposalSent = proposalSent;
        }
        if (statusFrom != null && statusFrom !== "" && !statusFrom.includes('Any')) {
            if (statusFrom === 'Within 3 days') {
                filters.statusFrom = 3;
            } else if (statusFrom === 'Within 10 days') {
                filters.statusFrom = 10;
            } else if (statusFrom === 'Within 15 days') {
                filters.statusFrom = 15;
            } else if (statusFrom === 'Within 30 days') {
                filters.statusFrom = 30;
            } else if (statusFrom === 'Within 60 days') {
                filters.statusFrom = 60;
            } else if (statusFrom === 'Within 90 days') {
                filters.statusFrom = 90;
            } else if (statusFrom === 'More than 90 days') {
                filters.statusFrom = 100;
            }
        }
        if (location != null && location !== "") {
            filters.location = location;
        }
        if (probability != null && probability !== "" && !probability.includes('Any')) {
            filters.probability = probability;
        }
        if (agent != null && agent !== "" && agent !== 'Any Agent') {
            filters.agent = agent;
        }
        if (sortBy != null && sortBy !== "" && sortingType != null && sortingType !== "") {
            filters.sortBy = sortBy;
            filters.sortingType = sortingType;
        }
        const response = await fetch(`${baseUrl}/admin/get-internal-ec-leads`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify(filters),
        });
        const data = await response.json();
        if (response.ok) {
            const ecLeadsResponse = {
                totalECLeads: data.totalECLeads,
                ecLead: data.ecLead.map((item) => createData(item.leadId, item.companyName, item.leadType, item.fullName, item.location, item.status, item.statusFrom, item.statusFromDays, item.proposalSent, item.probabilityStatus, item.contactMobile, item.contactEmail, item.source, item.agent, item.agentId))
            }
            setTotalECLeads(ecLeadsResponse.totalECLeads);
            setECLeads(ecLeadsResponse.ecLead);
            setApiFailed(ecLeadsResponse.ecLead.length === 0);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            setApiFailed(true);
            setTotalECLeads();
            console.error('Error fetching Leads:', data.message);
        }
        setLoading(false);
    } catch (error) {
        console.error('Error fetching Leads:', error);
    }
}

export const fetchECLead = async (setLeadsDetails, ecLeadId, setLoading, handleReopenLeadOpen) => {
    try {
        setLoading(true);
        const response = await fetch(`${baseUrl}/admin/get-internal-ec-lead?leadId=${ecLeadId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });
        const data = await response.json();
        if (response.ok) {
            setLeadsDetails({
                fullName: data.fullName, contactMobile: data.contactMobile, contactEmail: data.contactEmail,
                leadType: data.leadType, companyName: data.companyName, location: data.location, source: data.source,
                proposalSent: data.proposalSent, status: data.status, probabilityStatus: data.probabilityStatus,
                mobileVerified: data.mobileVerified, emailVerified: data.emailVerified, agentId: data.agentId,
                notes: data.notes != null
                    ? data.notes.map((item) => ({
                        id: item.id,
                        note: item.note,
                        isDeleted: item.deleted,
                        updatedOn: item.updatedOn != null ? moment(item.updatedOn).format("ll") : null,
                        createdBy: item.createdBy
                    }))
                    : [{ id: null, note: null, deleted: false, updatedOn: null, createdBy: null }],
                leadPackageId: data.leadPackageId,
                packageOffer: data.packageOffer != null ? data.packageOffer : {
                    packageId: null, packageName: "", packageDescription: "", updatedBy: null, packageOfferBy: "",
                    updatedOn: null, deleted: false, leadPackageProducts: [{
                        productType: { productTypeId: null, productTypeName: "" },
                        product: {
                            productId: null, productName: "", productDescription: "",
                            productPrice: null, productPriceOffered: null
                        }
                    }
                    ]
                }
            });
            setLoading(data.length === 0);
            if (handleReopenLeadOpen != null && data.status === 'Lost') {
                handleReopenLeadOpen();
            }
        } else if (response.status === 499) {
            refreshToken();
        } else {
            setLoading(true);
            console.error('Error fetching Lead:', data.message);
        }
    } catch (error) {
        console.error('Error fetching Lead:', error);
    }
}

export const fetchLeadSource = async (setLeadSource) => {
    try {
        const response = await fetch(`${baseUrl}/admin/get-leads-source`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setLeadSource(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching Lead source:', data.message);
        }
    } catch (error) {
        console.error('Error fetching Lead source:', error);
    }
};

export const fetchLeadTypes = async (setLeadType) => {
    try {
        const response = await fetch(`${baseUrl}/admin/get-leads-types`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setLeadType(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching Lead types:', data.message);
        }
    } catch (error) {
        console.error('Error fetching Lead types:', error);
    }
};

export const fetchLeadStatus = async (setLeadStatus) => {
    try {
        const response = await fetch(`${baseUrl}/admin/get-ec-leads-status`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setLeadStatus(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching Lead status:', data.message);
        }
    } catch (error) {
        console.error('Error fetching Lead status:', error);
    }
};

export const fetchLocations = async (setDBLocations) => {
    try {
        const response = await fetch(`${baseUrl}/admin/get-location`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setDBLocations(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching locations:', data.message);
        }
    } catch (error) {
        console.error('Error fetching locations:', error);
    }
};

export const fetchAgents = async (setAgents) => {
    try {
        const response = await fetch(`${baseUrl}/admin/get-agents`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setAgents(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching agents:', data.message);
        }
    } catch (error) {
        console.error('Error fetching agents:', error);
    }
};

export const fetchProbability = async (setProbability) => {
    try {
        const response = await fetch(`${baseUrl}/admin/get-probability`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setProbability(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching probability:', data.message);
        }
    } catch (error) {
        console.error('Error fetching probability:', error);
    }
};

export const getPackages = async (setPackages) => {
    try {
        const response = await fetch(`${baseUrl}/admin/packages`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setPackages(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching packages:', data.message);
        }
    } catch (error) {
        console.error('Error fetching packages:', error);
    }
};

export const getPackageProductDetails = async (packageId, setPackageProducts) => {
    try {
        const response = await fetch(`${baseUrl}/admin/package-product?packageId=${packageId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setPackageProducts(data.packageProducts);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching package products:', data.message);
        }
    } catch (error) {
        console.error('Error fetching package products:', error);
    }
};

export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
export const assignAgent = async (agentId, leadIds) => {
    try {
        const response = await fetch(`${baseUrl}/admin/assign-agent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            },
            body: JSON.stringify({ agentId, leadIds })
        });

        const data = await response.json();
        if (response.ok) {
            console.log('Agent assigned successfully.');
            return true;

        } else if (response.status === 499) {
            refreshToken();
            return false;
        } else {
            console.error('Failed to assign agent, Error:', data.message);
            return false;
        }
    } catch (error) {
        console.error('Failed to assign agent, Error:', error);
        return false;
    }
};
export const getInternalECLeadDaily = async (agentId, setLeadDaily) => {
    try {
        const response = await fetch(`${baseUrl}/admin/get-internal-ec-leaddaily?agentId=${agentId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setLeadDaily(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching lead daily details:', data.message);
        }
    } catch (error) {
        console.error('Error fetching lead daily details:', error);
    }
};