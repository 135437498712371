
import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';

const { baseUrl } = getConfig();

export const fetchCategories = async (setCategories) => {
    try {
        const response = await fetch(`${baseUrl}/interests`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setCategories(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching categories:', data.message);
        }
    } catch (error) {
        console.error('Error fetching categories:', error);
    }
};

export const fetchCities = async (setCities) => {
    try {
        const response = await fetch(`${baseUrl}/get-cities`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
            }
        });

        const data = await response.json();
        if (response.ok) {
            setCities(data);
        } else if (response.status === 499) {
            refreshToken();
        } else {
            console.error('Error fetching cities:', data.message);
        }
    } catch (error) {
        console.error('Error fetching cities:', error);
    }
};