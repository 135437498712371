import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';
import { useNavigate } from 'react-router-dom';
import { getCampaignsList } from '../campaign/campaign-repository';
import moment from "moment";
import { UserDetailsContext, WindowWidthContext } from '../context/user-details-context';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function TopCampaign({ topCampaign }) {
    const navigate = useNavigate();
    const windowWidth = useContext(WindowWidthContext);
    const { baseUrl } = getConfig();
    const { loggedUser } = useContext(UserDetailsContext);
    const [creators, setCreators] = useState([]);
    const [campaignList, setCampaignList] = useState({ campaigns: [] });
    const [loading, setLoading] = useState(true);
    const recordsPerPage = 5;
    const date = new Date();
    const currentDate = date.toISOString().slice(0, 10);

    // eslint-disable-next-line 
    const fetchTopCreators = async () => {
        try {
            let filters = {
                followers: true,
                pageNo: 1,
                pageSize: recordsPerPage,
            };

            const response = await fetch(`${baseUrl}/influencer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                body: JSON.stringify(filters)
            });

            const data = await response.json();
            if (response.ok) {
                const transformedResponse = {
                    totalResults: data.totalResults,
                    influencers: data.influencers.map(item => ({
                        userName: item.userName,
                        fullName: item.fullName,
                        followersCount: item.followersCount,
                        mediaCount: item.mediaCount,
                        engagement: item.engagement,
                        engagementRate: item.engagementRate,
                        category: item.category,
                        profilePictureId: item.profilePictureId,
                        location: item.location,
                        creatorScore: item.creatorScore
                    }))
                };
                setCreators(transformedResponse.influencers);
            } else if (response.status === 499) {
                refreshToken();
            } else {
                console.error('Error fetching influencers:', data.message);
            }
        } catch (error) {
            console.error('Error fetching influencers:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTopCreators();
        // eslint-disable-next-line
        getCampaignsList('/all-campaigns', setCampaignList, null, 1, 5);
        // eslint-disable-next-line
    }, []);

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        } else {
            const roundedNumber = Math.round(num * 100) / 100;
            if (Number.isInteger(roundedNumber)) {
                return roundedNumber.toString();
            } else {
                return roundedNumber.toFixed(2);
            }
        }
    };

    const campaignColumns = [
        { id: 1, label: 'Campaign Name', minWidth: windowWidth <= 799 ? 180 : 320 },
        { id: 2, label: 'City', minWidth: windowWidth <= 799 ? 180 : 200 },
        { id: 3, label: 'Date of Submission', minWidth: windowWidth <= 799 ? 180 : 200 },
        { id: 4, label: 'Project Type', minWidth: 180 },
        { id: 5, label: '', minWidth: 180 },
    ];
    if (windowWidth < 799) {
        campaignColumns.splice(1, 0, { id: 2, label: 'Apply Campaign', minWidth: 180 });
        const index = campaignColumns.findIndex(column => column.id === 5);
        if (index !== -1) {
            campaignColumns.splice(index, 1);
        }
    }

    const creatorColumns = [
        { id: 1, label: 'Name', minWidth: windowWidth <= 799 ? 200 : 280 },
        { id: 2, label: 'Followers', minWidth: 200 },
        { id: 3, label: 'Engagement', tooltip: 'Engagement indicates the average number of engagements on each post.', minWidth: 200 },
        { id: 4, label: 'Engagement Rate', tooltip: 'Engagement Rate is the ratio of likes divided by followers.', minWidth: 200 },
        { id: 5, label: 'Creator Score', minWidth: 200 },
    ];

    const handleViewAll = () => {
        if (topCampaign) {
            navigate('/all-campaigns/live');
        } else {
            navigate('/discovery');
        }
    }

    const handleCampaignClick = (campaign) => {
        navigate('/all-campaigns/details', { state: { campaignid: campaign.campaignId } });
    };

    return (
        <Box sx={{ width: windowWidth <= 799 ? '100vw' : '100%' }}>
            <Paper sx={{ width: windowWidth <= 799 ? '100vw' : '100%', mb: 2, backgroundColor: '#f4f5f8', padding: windowWidth <= 799 ? '26px 29px 26px 0px' : '26px 29px 26px 29px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <div className='top-campaign-text'>{topCampaign ? 'Top 5 Campaigns' : 'Top 5 Creators'}</div>
                <TableContainer sx={{ overflowX: 'auto', flexGrow: 1, scrollbarWidth: 'none', '-ms-overflow-style': 'none', padding: '0px 11px 0px 11px' }}>
                    <Table className='top-campaign-table' aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {(topCampaign ? campaignColumns : creatorColumns).map((column) => (
                                    <TableCell sx={{ backgroundColor: 'transparent', fontFamily: 'DM Sans', color: '#7D8FA9', opacity: 1, fontSize: '15px', fontWeight: 600 }} key={column.id}
                                        style={{ minWidth: column.minWidth }}>
                                        <div className='top-campaign-tooltip'>
                                            {column.label}
                                            {column.tooltip && <Tooltip title={column.tooltip}>
                                                <InfoOutlinedIcon sx={{ m: 1 }} />
                                            </Tooltip>}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {topCampaign ?
                            <TableBody>

                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    campaignList.campaigns.map((campaign) => (
                                        <TableRow key={campaign.campaignId} className='top-campaign-row'>
                                            <TableCell className='top-creator-name'>
                                                <div className='campaign-name-column'>
                                                    {loading && !campaign ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <div className="_img_19x11_1 _avatar_1ig5e_24" style={{
                                                            backgroundImage: `url(${campaign.profilePictureId
                                                                ? `${baseUrl}/download-insta-profile/${campaign.profilePictureId}`
                                                                : <svg width="16" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="_verified_dbbth_91">
                                                                    <path d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z" fill="#4AABED">
                                                                    </path>
                                                                </svg>
                                                                })`
                                                        }}
                                                        >
                                                        </div>
                                                    )}
                                                    <div className='top-creator-text'>
                                                        <div className='top-campaign-name' style={{ maxWidth: '15ch', overflowWrap: 'anywhere' }}>{campaign.campaignName}</div>
                                                        <div className='top-campaign-category'>{campaign.categoryTypes[0]}</div>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            {windowWidth <= 799 &&
                                                <TableCell>
                                                    {campaign.userId !== loggedUser.userId && campaign.lastDate >= currentDate
                                                        ? (
                                                            <Button variant="contained" sx={{
                                                                width: '128px',
                                                                backgroundColor: campaign.applicationStatus != null ?
                                                                    campaign.applicationStatus === 'Apply' ? '#fff' : '#e563a4' : '#e563a4',
                                                                color: campaign.applicationStatus != null ?
                                                                    campaign.applicationStatus === 'Apply' ? '#5d5c5c' : '#fff' : '#fff',
                                                                border: campaign.applicationStatus != null ?
                                                                    campaign.applicationStatus === 'Apply' ? '1px solid #928e8e' : 'none' : 'none',
                                                                textTransform: 'capitalize',
                                                                '&:hover': {
                                                                    backgroundColor: campaign.applicationStatus != null ?
                                                                        campaign.applicationStatus === 'Apply' ? 'black' : '#e326a4' : '#e326a4',
                                                                    color: '#fff'
                                                                }
                                                            }} onClick={() => { handleCampaignClick(campaign) }}
                                                                endIcon={<ArrowForwardIcon />}>
                                                                {campaign.applicationStatus != null ? campaign.applicationStatus === 'Apply' ? 'Withdraw' : 'Apply Now' : 'Apply Now'}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button variant="contained" disabled={true} sx={{
                                                                textTransform: 'capitalize', '&:disabled': {
                                                                    backgroundColor: '#FFFFFF', color: '#586a84',
                                                                    opacity: 0.3, border: '0.7868627309799194px solid #586A84'
                                                                }
                                                            }} endIcon={<ArrowForwardIcon />}>Apply Now</Button>
                                                        )
                                                    }
                                                </TableCell>
                                            }
                                            <TableCell>
                                                <div className='top-campaign-row-text'>{campaign.location[0].city}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div className='top-campaign-row-text'>{moment(campaign.lastDate).format("l")}</div>
                                            </TableCell>
                                            <TableCell>
                                                <div className='top-campaign-row-text'>{campaign.projectType}</div>
                                            </TableCell>
                                            {windowWidth >= 799 &&

                                                <TableCell>
                                                    {campaign.userId !== loggedUser.userId && campaign.lastDate >= currentDate
                                                        ? (
                                                            <Button variant="contained" sx={{
                                                                width: '128px',
                                                                backgroundColor: campaign.applicationStatus != null ?
                                                                    campaign.applicationStatus === 'Apply' ? '#fff' : '#e563a4' : '#e563a4',
                                                                color: campaign.applicationStatus != null ?
                                                                    campaign.applicationStatus === 'Apply' ? '#5d5c5c' : '#fff' : '#fff',
                                                                border: campaign.applicationStatus != null ?
                                                                    campaign.applicationStatus === 'Apply' ? '1px solid #928e8e' : 'none' : 'none',
                                                                textTransform: 'capitalize',
                                                                '&:hover': {
                                                                    backgroundColor: campaign.applicationStatus != null ?
                                                                        campaign.applicationStatus === 'Apply' ? 'black' : '#e326a4' : '#e326a4',
                                                                    color: '#fff'
                                                                }
                                                            }} onClick={() => { handleCampaignClick(campaign) }}
                                                                endIcon={<ArrowForwardIcon />}>
                                                                {campaign.applicationStatus != null ? campaign.applicationStatus === 'Apply' ? 'Withdraw' : 'Apply Now' : 'Apply Now'}
                                                            </Button>
                                                        )
                                                        : (
                                                            <Button variant="contained" disabled={true} sx={{
                                                                textTransform: 'capitalize', '&:disabled': {
                                                                    backgroundColor: '#FFFFFF', color: '#586a84',
                                                                    opacity: 0.3, border: '0.7868627309799194px solid #586A84'
                                                                }
                                                            }} endIcon={<ArrowForwardIcon />}>Apply Now</Button>
                                                        )
                                                    }
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )))}
                            </TableBody>
                            :
                            <>
                                <TableBody>
                                    {loading ? (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        creators.map((creator, index) => (
                                            <TableRow key={creator.userName} className='top-campaign-row'>
                                                <TableCell className='top-creator-name'>
                                                    <>
                                                        <div className='campaign-name-column'>
                                                            {loading ? (
                                                                <CircularProgress />
                                                            )
                                                                : (
                                                                    <div className="_img_19x11_1 _avatar_1ig5e_24" style={{
                                                                        backgroundImage: `url(${creator.profilePictureId
                                                                            ? `${baseUrl}/download-insta-profile/${creator.profilePictureId}`
                                                                            : <svg width="16" height="16" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="_verified_dbbth_91">
                                                                                <path d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z" fill="#4AABED">
                                                                                </path>
                                                                            </svg>
                                                                            })`
                                                                    }}
                                                                    >
                                                                    </div>
                                                                )}
                                                            <div className='top-creator-text'>
                                                                <div className='top-campaign-name'>{creator.fullName}</div>
                                                                <div className='top-campaign-category'>@{creator.userName}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='top-creator-text'>
                                                        <div className='top-campaign-row-text'>{formatNumber(creator.followersCount)}</div>
                                                        <div className='top-campaign-category'>Followers</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='top-creator-text'>
                                                        <div className='top-campaign-row-text'>{formatNumber(creator.engagement)}</div>
                                                        <div className='top-campaign-category'>Engagements</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='top-creator-text'>
                                                        <div className='top-campaign-row-text'>{parseFloat(creator.engagementRate).toFixed(2)}%</div>
                                                        <div className='top-campaign-category'>Engagement Rate</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='top-creator-text'>
                                                        <div className='top-campaign-row-text'>{creator.creatorScore === null ? 'Coming soon' : 'Coming soon'}</div>
                                                        <div className='top-campaign-category'>Creator Score</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )))}
                                </TableBody>
                            </>
                        }
                    </Table>
                </TableContainer>
                <div className='view-all-button'>
                    <Button variant="contained" sx={{
                        backgroundColor: '#FFFFFF', color: '#586a84', border: '0.75px solid #586A84',
                        textTransform: 'capitalize', '&:hover': {
                            backgroundColor: 'black', color: '#FFFFFF'
                        }
                    }} onClick={() => handleViewAll()} endIcon={<ArrowForwardIcon />}>View all
                    </Button>
                </div>
            </Paper>
        </Box>
    )
}

export default TopCampaign;
