import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../css/campaign.css";
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, TextField, FormControlLabel, Checkbox, Button, Autocomplete, Chip, Paper, Radio, RadioGroup, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { campaignNameText } from "../../mui/mui-styling";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import CustomAlert from "../common/alert";
import Alert from '@mui/material/Alert';
import { fetchEnterpriseClients, useCreateCampaign, useUpdateCampaign } from "./campaign-repository";
import { fetchCategories, fetchCities } from '../common/common-apis-repo';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MuiChipsInput } from "mui-chips-input";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { UserDetailsContext } from "../context/user-details-context";
import DialogBox from "../common/dialog-box";

function CreateCampaign() {
  const location = useLocation();
  const { campaign, fromPage } = location.state || {};
  const [userId, setUserId] = useState(campaign ? campaign.userId : location.state?.userId);
  const userName = location.state?.userName;
  const enterpriseClient = location.state?.enterpriseClient;
  const { loggedUser } = useContext(UserDetailsContext);
  const createCampaign = useCreateCampaign();
  const updateCampaign = useUpdateCampaign();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const reactQuillRef = useRef();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const extractAttachmentIds = (updatedAttachments) => {
    return updatedAttachments.map(attachment => {
      if (typeof attachment === "string") {
        return attachment.split(" / ")[0];
      }
      return null;
    }).filter(id => id !== null);
  };

  const [campaignData, setCampaignData] = useState({
    brandHandle: campaign?.brandHandle || null,
    firstRowOptions: [
      { name: "productionAvailable", label: "Production Available", value: (campaign && campaign.productionAvailable) || "No" },
      { name: "travelAllowance", label: "Travel Allowance", value: (campaign && campaign.travelAllowance) || "No" }
    ],
    secondRowOptions: [
      { name: "stayAllowance", label: "Stay Allowance", value: (campaign && campaign.stayAllowance) || "No" },
      { name: "foodAllowance", label: "Food Allowance", value: (campaign && campaign.foodAllowance) || "No" }
    ],
    lastDate: campaign?.lastDate ? dayjs(campaign.lastDate) : dayjs().add(3, 'day'),
    campaignName: campaign?.campaignName || null,
    description: campaign?.description || null,
    projectType: campaign?.projectType || 'Cash',
    categoryTypes: campaign?.categoryTypes || [],
    customCategory: campaign?.customCategory || [],
    newCategory: null,
    selectedCategories: [],
    locations: campaign?.location || [],
    remark: campaign?.remark || null,
    creators: campaign?.creators || 1,
    specifyKeywords: campaign?.specifyKeywords || [],
    mandatoryKeywords: campaign?.mandatoryKeywords || false,
    attachments: campaign?.attachments || [],
    attachmentId: campaign?.attachments ? extractAttachmentIds(campaign.attachments) : []
  });

  const [categories, setCategories] = useState([]);
  const [showCategories, setShowCategories] = useState([]);
  const [enterpriseClients, setEnterpriseClients] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEC, setSelectedEC] = useState(null);
  const [error, setError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openDialog, setOpenDialog] = useState(false);
  const [cities, setCities] = useState([]);
  const [openBillingDetailAlert, setOpenBillingDetailAlert] = useState(false);
  const [privateInstaAccounts] = useState(false);
  const [incompleteRegistration] = useState(false);
  const [selectedCities] = useState([]);
  const [selectedStates] = useState([]);
  const [selectedCategories] = useState([]);
  const [followersCountFrom] = useState();
  const [followersCountTo] = useState();
  const [fromDate] = useState(null);
  const [toDate] = useState(null);
  const [searchString] = useState(null);

  const handleChange = (event, value, nameOverride) => {
    let name, parsedValue, type, checked;

    if (nameOverride) {
      name = nameOverride;
      parsedValue = value;
      type = 'text';
    } else {
      ({ name, value, type, checked } = event.target);
      parsedValue = type === 'number' ? parseInt(value, 10) : value;
    }

    let updatedData = { ...campaignData };
    switch (name) {
      case "selectedCategories":
        if (Array.isArray(parsedValue) && parsedValue.includes("Others")) {
          handleDialogOpen();
          parsedValue = parsedValue.filter(item => item !== 'Others');
        }
        updatedData.selectedCategories = parsedValue;
        updatedData.categoryTypes = categories.filter(category => parsedValue.includes(category));
        break;
      case "locations":
        updatedData.locations = parsedValue;
        break;
      case "selectedEC":
        if (parsedValue) {
          setUserId(parsedValue.userId);
          setSelectedEC(parsedValue);
          setSelectedUser(parsedValue);
        } else {
          setUserId(null);
          setSelectedEC(null);
          setSelectedUser(null);
        }
        updatedData.selectedEC = parsedValue;
        break;
      case "projectType":
        updatedData.projectType = parsedValue;
        break;
      case "brandHandle":
        const regexPattern = /^[a-zA-Z0-9._]+$/;
        updatedData.brandHandle = parsedValue;
        setError(!regexPattern.test(parsedValue));
        break;
      case "mandatoryKeywords":
        updatedData.mandatoryKeywords = !updatedData.mandatoryKeywords;
        break;
      case "lastDate":
        const dateValue = dayjs(parsedValue);
        updatedData.lastDate = dateValue.isBefore(dayjs()) ? dayjs() : dateValue;
        break;
      case "remark":
        updatedData.remark = parsedValue;
        break;
      case "description":
        updatedData.description = parsedValue;
        break;
      case "newCategory":
        updatedData.newCategory = parsedValue;
        break;
      case "specifyKeywords":
        if (Array.isArray(parsedValue) && parsedValue.length <= 20) {
          updatedData.specifyKeywords = parsedValue;
        } else {
          showAlert('Maximum limit of keywords reached', 'error');
        }
        break;
      default:
        updatedData[name] = type === 'checkbox' ? checked : parsedValue;
        break;
    }

    setCampaignData(updatedData);

    const isFieldDirty = field => {
      if (Array.isArray(field)) {
        return field.length > 0;
      }
      return field !== '' && field !== null;
    };

    let isFormDirty = [
      updatedData.brandHandle,
      updatedData.campaignName,
      updatedData.description,
      updatedData.newCategory,
      updatedData.remark,
      updatedData.specifyKeywords,
      updatedData.locations,
      updatedData.selectedCategories,
      updatedData.categoryTypes,
      updatedData.customCategory,
      updatedData.attachments,
      updatedData.attachmentId
    ].some(isFieldDirty) ||
      updatedData.firstRowOptions.some(option => option.value === 'Yes') ||
      updatedData.secondRowOptions.some(option => option.value === 'Yes') ||
      updatedData.creators > 1 ||
      updatedData.mandatoryKeywords;

    if (updatedData.projectType === 'cash') {
      isFormDirty = false;
    }

    if (campaign && (campaign.someValue !== updatedData.someValue)) {
      isFormDirty = true;
    }

    setIsFormDirty(isFormDirty);
  };

  const handleFirstRowOptions = (index) => () => {
    setCampaignData((prevData) => {
      const newOptions = [...prevData.firstRowOptions];
      newOptions[index].value = newOptions[index].value === "Yes" ? "No" : "Yes";
      return { ...prevData, firstRowOptions: newOptions };
    });
  };

  const handleSecondRowOptions = (index) => () => {
    setCampaignData((prevData) => {
      const newOptions = [...prevData.secondRowOptions];
      newOptions[index].value = newOptions[index].value === "Yes" ? "No" : "Yes";
      return { ...prevData, secondRowOptions: newOptions };
    });
  };

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.getEditor();
    if (unprivilegedEditor.getLength() > 2000 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const filesPerRow = window.matchMedia("(max-width: 768px)").matches ? 2 : 4;

  const allowedFileFormats = [".pdf", ".doc", ".docx", ".ppt", ".pptx", ".png", ".jpg", ".jpeg", ".gif"];

  const handleDropOrChange = (files) => {
    const newAttachments = [...campaignData.attachments, ...files];
    const totalSize = newAttachments.reduce((acc, file) => {
      const fileSize = typeof file === 'string' ? undefined : file.size;
      return acc + (fileSize || 0);
    }, 0);

    if (totalSize > 10 * 1024 * 1024) {
      showAlert('Total file size should not exceed 10MB.', 'error');
      return;
    }

    const isValidFiles = newAttachments.every(file => {
      if (typeof file === 'string') {
        return true;
      } else if (file.name !== undefined && file.name.includes('.')) {
        const extension = `.${file.name.trim().split('.').pop()}`.toLowerCase();
        return allowedFileFormats.includes(extension);
      }
      return false;
    });

    if (!isValidFiles) {
      showAlert('Only PDF, WORD, PPT, PNG, JPG, JPEG, or GIF formats are allowed.', 'error');
      return;
    }

    setCampaignData((prevData) => ({
      ...prevData,
      attachments: newAttachments,
      attachmentId: extractAttachmentIds(newAttachments),
    }));
  };

  const handleFileChange = (event) => {
    handleDropOrChange(event.target.files);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    handleDropOrChange(event.dataTransfer.files);
  };

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleAttachmentDelete = (fileIndex) => {
    setCampaignData((prevData) => {
      const updatedAttachments = prevData.attachments.filter((_, index) => index !== fileIndex);
      const updatedAttachmentIds = extractAttachmentIds(updatedAttachments);
      return {
        ...prevData,
        attachments: updatedAttachments,
        attachmentId: updatedAttachmentIds,
      };
    });
  };

  const handleCampaignAction = (status) => {
    if (dayjs().isAfter(campaignData.lastDate, 'day')) {
      showAlert('Last date of application is over, please change the date', 'error');
      return;
    }

    if (campaignData.creators < 1) {
      showAlert('Please enter number of creator', 'error');
      return;
    }

    if (status === 'Draft' || (selectedUser && selectedUser.additionalDetails)) {
      setIsFormDirty(false);
      const args = [
        campaignData.campaignName,
        campaignData.description,
        campaignData.specifyKeywords,
        campaignData.projectType,
        campaignData.categoryTypes,
        campaignData.customCategory,
        campaignData.mandatoryKeywords,
        campaignData.locations,
        campaignData.creators,
        status,
        campaignData.remark,
        campaignData.firstRowOptions,
        campaignData.secondRowOptions,
        campaignData.lastDate,
        campaignData.attachments,
        campaignData.attachmentId,
        setAlertOpen,
        setAlertMessage,
        setAlertSeverity,
        userId === null || userId === undefined ? loggedUser.userId : userId,
        campaignData.brandHandle
      ];

      (campaign && campaign.campaignId) ? updateCampaign((campaign && campaign.campaignId), ...args) : createCampaign(...args);
    } else if (loggedUser.role === 'ENTERPRISE_CLIENT') {
      if (status === 'Draft' || (loggedUser && loggedUser.additionalDetails)) {
        setIsFormDirty(false);
        const args = [
          campaignData.campaignName,
          campaignData.description,
          campaignData.specifyKeywords,
          campaignData.projectType,
          campaignData.categoryTypes,
          campaignData.customCategory,
          campaignData.mandatoryKeywords,
          campaignData.locations,
          campaignData.creators,
          status,
          campaignData.remark,
          campaignData.firstRowOptions,
          campaignData.secondRowOptions,
          campaignData.lastDate,
          campaignData.attachments,
          campaignData.attachmentId,
          setAlertOpen,
          setAlertMessage,
          setAlertSeverity,
          userId === null || userId === undefined ? loggedUser.userId : userId,
          campaignData.brandHandle
        ];

        (campaign && campaign.campaignId) ? updateCampaign((campaign && campaign.campaignId), ...args) : createCampaign(...args);
      } else {
        setOpenBillingDetailAlert(true);
      }
    }
  };

  const handleKeywordsChange = (newKeywords) => {
    const totalChips = newKeywords.length;
    if (totalChips <= 20) {
      setCampaignData((prevData) => ({
        ...prevData,
        specifyKeywords: newKeywords,
      }));
    } else {
      showAlert('Maximum limit of keywords reached', 'error');
      return;
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    fetchEnterpriseClients(setEnterpriseClients, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, followersCountFrom, followersCountTo, selectedCategories, searchString, fromDate, toDate);
    fetchCategories((categories) => {
      const updatedCategories = [...categories, ...campaignData.customCategory];
      setShowCategories(updatedCategories);
      setCategories(categories);
    });
    fetchCities(setCities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId !== null) {
      const selectedClient = enterpriseClients.find(client => client.userId === userId);
      setSelectedEC(selectedClient || null);
      setSelectedUser(selectedClient || null);
    }
  }, [userId, enterpriseClients]);

  const handleNewCategorySubmit = () => {
    const updatedShowCategories = [...showCategories, campaignData.newCategory];
    setShowCategories(updatedShowCategories);
    setCampaignData((prevData) => ({
      ...prevData,
      selectedCategories: [...prevData.selectedCategories, prevData.newCategory],
      newCategory: '',
    }));
    setOpenDialog(false);
  };

  const categoriesTheme = createTheme({
    components: {
      MuiChip: {
        styleOverrides: {
          label: { maxWidth: '60px', paddingLeft: '8px', paddingRight: '8px' }
        }
      }
    }
  });

  useEffect(() => {
    const selected = [...(campaign?.categoryTypes || []), ...(campaign?.customCategory || [])];
    const filteredSelected = selected.filter(category => category.trim() !== "");
    setCampaignData((prevData) => ({
      ...prevData,
      selectedCategories: filteredSelected,
    }));
  }, [campaign]);

  useEffect(() => {
    setCampaignData((prevData) => ({
      ...prevData,
      customCategory: prevData.selectedCategories.filter(category => !categories.includes(category)),
    }));
  }, [categories, campaignData.selectedCategories]);

  useEffect(() => {
    if (isFormDirty) {
      const handleBeforeUnload = (e) => {
        const currentPath = window.location.pathname;
        const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
        if (isFormDirty && monitoredPaths.includes(currentPath)) {
          const message = 'You have unsaved changes. Are you sure you want to leave?';
          e.returnValue = message;
          return message;
        }
      };

      const handleNavClick = (e) => {
        if (isFormDirty) {
          const message = 'You have unsaved changes. Are you sure you want to leave?';
          if (!window.confirm(message)) {
            e.preventDefault();
            e.stopImmediatePropagation();
          } else {
            setIsFormDirty(false);
          }
        }
      };

      const handlePopState = (e) => {
        const currentPath = window.location.pathname;
        const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
        if (isFormDirty && monitoredPaths.includes(currentPath)) {
          const message = 'You have unsaved changes. Are you sure you want to leave?';
          const userConfirmed = window.confirm(message);
          if (!userConfirmed) {
            window.history.pushState(null, '', window.location.pathname);
          } else {
            setIsFormDirty(false);
          }
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handlePopState);
      window.history.pushState(null, '', window.location.pathname);

      const navLinks = document.querySelectorAll('.nav-link');
      navLinks.forEach((link) => {
        link.addEventListener('click', handleNavClick);
      });

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('popstate', handlePopState);
        navLinks.forEach((link) => {
          link.removeEventListener('click', handleNavClick);
        });
      };
    }
  }, [isFormDirty]);

  return (
    <>
      {!loggedUser ?
        <>
        </>
        :
        <div className="campaign-page">
          {enterpriseClient &&
            <Alert severity="info">{`You are creating Campaign on behalf of Enterprise Client ${userName}`}</Alert>
          }
          <h1>{campaign ? "Update Campaign" : "Create Campaign"}</h1>
          <div className="add-campaign-name">
            <TextField sx={campaignNameText} name="campaignName" value={campaignData.campaignName}
              className="textfield" placeholder="Name your campaign" onChange={handleChange} inputProps={{ maxLength: 50 }} />
          </div>
          <span>
            <div className="socialmedia-btn dark"> <InstagramIcon sx={{ fontSize: 20 }} /> <label>Instagram</label> </div>
          </span>
          <p className="highlight-note">About Campaign</p>
          <div className="description-outer-box">
            <ReactQuill
              theme="snow" onKeyDown={checkCharacterCount} ref={reactQuillRef} value={campaignData.description} onChange={(value) => handleChange(null, value, 'description')} name="description" placeholder="Description.."
              className="campaign-description"
              modules={{
                toolbar: [
                  [{ 'header': [] }, { 'font': [] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ color: [] }, { background: [] }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['link', 'image', 'video']
                ],
              }}
              formats={[
                'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent', 'link', 'image', 'video', 'color', 'background'
              ]}
            />
          </div>
          <div className="campaign-row">
            <div className="category-type">
              <p className="category-type-label highlight-note">Select Category Type<span className="asterisk">*</span></p>
              <ThemeProvider theme={categoriesTheme}>
                <Autocomplete
                  className='campaign-categories-dropdown'
                  value={campaignData.selectedCategories}
                  multiple
                  limitTags={3}
                  id="categories"
                  name="selectedCategories"
                  options={[...showCategories.filter(category => category.trim() !== ""), "Others"]}
                  disableCloseOnSelect
                  getOptionLabel={(option) => { if (Array.isArray(option) && option.length === 0) { return ''; } return option; }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  )}
                  style={{ width: 500 }}
                  renderInput={(params) => (<TextField {...params} placeholder="Categories" />)}
                  onChange={(event, value) => handleChange(event, value, 'selectedCategories')}
                  renderTags={(value, getTagProps) => (
                    <>
                      {value.filter(tag => tag !== "").slice(0, 3).map((option, index) => (
                        <Chip key={index} title={option} label={option} {...getTagProps({ index })} />
                      ))}
                      {value.length > 3 && (
                        <Chip label={`${value.length - 3}+`} {...getTagProps({ index: value.length - 1 })} />
                      )}
                    </>
                  )}
                  PaperComponent={({ children }) => (
                    <Paper style={{ maxHeight: '300px', overflowY: 'auto' }}> {children} </Paper>
                  )}
                />
                <Dialog open={openDialog} onClose={handleDialogClose}>
                  <DialogTitle>Add New Category</DialogTitle>
                  <DialogContent>
                    <DialogContentText>Please enter your new category:</DialogContentText>
                    <TextField autoFocus margin="dense" name="newCategory" label="New Category" fullWidth
                      value={campaignData.newCategory} onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={handleNewCategorySubmit} disabled={!campaignData.newCategory}>Add</Button>
                  </DialogActions>
                </Dialog>
              </ThemeProvider>
            </div>
            <div className="campaign-city">
              <p className="highlight-note">Select City<span className="asterisk">*</span></p>
              <Box>
                <ThemeProvider theme={categoriesTheme}>
                  <Autocomplete
                    className='custom-city'
                    sx={{ width: "500px" }}
                    multiple
                    name="locations"
                    limitTags={3}
                    id="city"
                    value={campaignData.locations}
                    options={cities}
                    disableCloseOnSelect
                    getOptionLabel={(option) => (typeof option.city === 'string' ? `${option.city} - ${option.state}` : '')}
                    onChange={(event, value) => handleChange(event, value, 'locations')}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        {option.city} - {option.state}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} placeholder='City' />
                    )}
                    renderTags={(value, getTagProps) => (
                      <>
                        {value.slice(0, 3).map((option, index) => (
                          <Chip key={index} label={`${option.city} - ${option.state}`} {...getTagProps({ index })} />
                        ))}
                        {value.length > 3 && (
                          <Chip label={`${value.length - 3}+`} {...getTagProps({ index: value.length - 1 })} />
                        )}
                      </>
                    )}
                  />
                </ThemeProvider>
              </Box>
            </div>
          </div>
          <div className="campaign-row">
            <div>
              <p className="highlight-note">Number of Creators</p>
              <TextField
                type="number"
                name="creators"
                sx={{ width: '500px' }}
                value={campaignData.creators}
                onChange={handleChange}
                inputProps={{ min: 1 }}
              />
            </div>
            {loggedUser.role === 'ADMIN'
              ? <div>
                <p className="category-type-label highlight-note">Select Enterprise Client<span className="asterisk">*</span></p>
                <ThemeProvider theme={categoriesTheme}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    name="selectedEC"
                    options={enterpriseClients}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    value={selectedEC}
                    sx={{ width: 500 }}
                    onChange={(event, value) => handleChange(event, value, 'selectedEC')}
                    renderInput={(params) => <TextField {...params} label="Enterprise Client" />}
                  />
                </ThemeProvider>
              </div>
              : <div>
                <p className="category-type-label highlight-note">Brand Handle<span className="asterisk">*</span></p>
                <TextField sx={{ width: 500 }} name="brandHandle"
                  id="outlined-basic" label="Brand Handle" variant="outlined"
                  value={campaignData.brandHandle} onChange={handleChange}
                  error={error}
                  helperText={error ? "Only letters, numbers, dots, and underscores are allowed" : ""}
                />
              </div>
            }
          </div>
          <div className="campaign-row">
            <div>
              <p className="highlight-note">Select Project Type</p>
              <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} name="projectType" value={campaignData.projectType} onChange={handleChange}>
                <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                <FormControlLabel sx={{ margin: '0 3em 0 2em' }} value="Barter" control={<Radio />} label="Barter" />
                <FormControlLabel value="Both" control={<Radio />} label="Both" />
              </RadioGroup>
            </div>
            {loggedUser.role === 'ADMIN' &&
              <div>
                <p className="category-type-label highlight-note">Brand Handle<span className="asterisk">*</span></p>
                <TextField sx={{ width: 500 }} name="brandHandle"
                  id="outlined-basic" label="Brand Handle" variant="outlined"
                  value={campaignData.brandHandle} onChange={handleChange}
                  error={error}
                  helperText={error ? "Only letters, numbers, dots, and underscores are allowed" : ""}
                />
              </div>
            }
          </div>
          <div>
            <div className="option-row">
              {campaignData.firstRowOptions.map((option, index) => (
                <div key={index} className="toggle-section">
                  <div className="highlight-note">{option.label}</div>
                  <div className={`toggle-container ${option.value === "Yes" ? 'active' : ''}`} onClick={handleFirstRowOptions(index)}>
                    <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} defaultValue={"No"} value={option.value} aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                      <FormControlLabel sx={{ marginRight: '3em' }} value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </div>
                </div>
              ))}
            </div>
            <div className="option-row">
              {campaignData.secondRowOptions.map((option, index) => (
                <div key={index} className="toggle-section">
                  <div className="highlight-note">{option.label}</div>
                  <div className={`toggle-container ${option.value === "Yes" ? 'active' : ''}`} onClick={handleSecondRowOptions(index)}>
                    <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} defaultValue={"No"} value={option.value} aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                      <FormControlLabel sx={{ marginRight: '3em' }} value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="keywords-container">
            <p className="highlight-note">Specify #hashtags, @mentions or keywords for content you would like to track?</p>
            <p className="note">We collect all posts, stories and reels that contain #hashtags, @mentions (also close matches without symbols "#", "@") or keywords in the description and title</p>
            <MuiChipsInput
              className="campaign-textarea"
              placeholder="#hashtags, @mentions, keywords"
              value={campaignData.specifyKeywords}
              onChange={handleKeywordsChange}
            />
          </div>
          <div className="campaign-options">
            <FormControlLabel
              control={<Checkbox checked={campaignData.mandatoryKeywords} name="mandatoryKeywords" onChange={handleChange} />}
              label={
                <div className="label-container">
                  All #keywords/#hashtags, words and phrases are mandatory
                  <div className="information-badge">
                    i
                    <span className="tooltip">We will notify you if any of the #hashtags/@mentions/tags/keywords are missing from creators posts</span>
                  </div>
                </div>
              }
            />
          </div>
          <div className="start-date-container">
            <p className="start-date-label">
              Select a last date for the content collection
              <div className="information-badge">i
                <span className="tooltip">Please specify the start date of the campaign. We will start tracking creators' content from that day forward.</span>
              </div>
            </p>
            <p>Last date of application</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker name="lastDate"
                value={campaignData.lastDate}
                disablePast={true}
                onChange={(newValue) => handleChange(null, newValue, 'lastDate')}
                format="MMMM DD, YYYY"
                renderInput={(params) => (<TextField {...params} variant="outlined" />)}
              />
            </LocalizationProvider>
          </div>

          <div className="file-uploader-container">
            <div className="file-upload-label">Upload Files</div>
            <div className="file-drop-area" onDrop={handleDrop} onDragOver={handleDragOver}>
              <p>Drag & drop files here</p>
              <input type="file" onChange={handleFileChange} accept={allowedFileFormats} className="file-input" multiple />
              <button className="browse-files-btn" onClick={() => document.querySelector('.file-input').click()}>Browse files</button>
            </div>
            <p className="dimlight-note">Please note: You can upload files in PDF, WORD, PPT, PNG, JPG, JPEG, or GIF formats. The maximum file size allowed is 10MB.</p>
            {campaignData.attachments && campaignData.attachments.length > 0 && (
              <div className="selected-file">
                <div className="selected-file-label">Selected Files:</div>
                <div className="file-list-outer-container" style={{ display: 'grid', gridTemplateColumns: `repeat(${filesPerRow}, 1fr)` }}>
                  {campaignData.attachments.map((attachment, index) => {
                    let fileName = "";
                    if (typeof attachment === "string") {
                      fileName = attachment.split(" / ")[1];
                    } else if (attachment instanceof File) {
                      fileName = attachment.name;
                    }
                    return (
                      <div key={index} className="selected-file-list-row">
                        <ul className="file-list-container">
                          <li>
                            <span className="selected-file-name">{fileName}</span>
                            <span className="remove-file" onClick={() => handleAttachmentDelete(index)}>x</span>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="campaign-remark-container">
            <p className="highlight-note">Other Remarks</p>
            <TextField className="campaign-remarks" value={campaignData.remark} name="remark" placeholder="Add your remarks" onChange={handleChange} inputProps={{ maxLength: 500 }} multiline></TextField>
          </div>
          <div>
            <p className="highlight-note">Created By: {campaign ? campaign.createdByDetails : loggedUser ? `${loggedUser.firstName} ${loggedUser.lastName}` : ""}</p>
          </div>
          <div className="create-campaign-footer">
            <p className="note-for-date">Cannot be modified after campaign launch</p>

            <div className="start-campaign-container">
              {(fromPage === 'details' && (campaign && campaign.status) !== 'Draft') || (campaign && campaign.status) === 'Pending' ? (
                <>
                  {(loggedUser.role === 'ADMIN' && (campaign && campaign.status) === 'Live') || (loggedUser.role === 'ADMIN' && (campaign && campaign.status) === 'Pending') ? (
                    <Button className="choose-btn" disabled={!campaignData.campaignName || campaignData.selectedCategories.length === 0 || campaignData.locations.length === 0 || !campaignData.brandHandle || !selectedEC || error} onClick={() => handleCampaignAction((campaign && campaign.status) === 'Live' ? 'Live' : 'Pending')}>
                      Update Campaign
                    </Button>
                  ) : (
                    <Button className="choose-btn" disabled={!campaignData.campaignName || campaignData.selectedCategories.length === 0 || campaignData.locations.length === 0 || !campaignData.brandHandle || (loggedUser.role === 'ADMIN' && !selectedEC) || error} onClick={() => handleCampaignAction('Pending')}>
                      Update Campaign
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button className="choose-btn" disabled={!campaignData.campaignName || campaignData.selectedCategories.length === 0 || campaignData.locations.length === 0 || !campaignData.brandHandle || (loggedUser.role === 'ADMIN' && !selectedEC) || error} onClick={() => handleCampaignAction('Pending')}>
                    Send For Approval
                  </Button>
                  <Button className="draft-btn" disabled={!campaignData.campaignName || (loggedUser.role === 'ADMIN' && !selectedEC) || error} onClick={() => handleCampaignAction('Draft')}>
                    Save As Draft
                  </Button>
                </>
              )}
            </div>

          </div>
          <div className="alert-msg">
            <CustomAlert open={alertOpen} severity={alertSeverity} message={alertMessage} onClose={() => setAlertOpen(false)} />
          </div>
          <DialogBox userDetails={loggedUser} openAlert={openBillingDetailAlert} setOpenAlert={setOpenBillingDetailAlert} forEC={true} enterpriseClient={true} />
        </div>
      }
    </>
  );
}

export default CreateCampaign;
