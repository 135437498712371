import React, { useEffect, useState, useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import '../../../css/package-offer.css';
import { getPackages, getPackageProductDetails } from '../ec-leads-helper';
import CloseIcon from '@mui/icons-material/Close';
import { WindowWidthContext } from "../../context/user-details-context";

export const PackageOfferDialog = ({ fromPage, open, onClose, handleLeadChange, packageOfferDetails }) => {
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [packages, setPackages] = useState([]);
    const [packageProducts, setPackageProducts] = useState([]);
    const [fieldValueChanged, setFieldValueChange] = useState(false);
    const [prevValue, setPrevValue] = useState(false);
    const windowWidth = useContext(WindowWidthContext);

    const [packageOffer, setPackageOffer] = useState({
        packageId: "", packageName: "", packageDescription: "", updatedBy: null,
        packageOfferBy: "", updatedOn: null, deleted: false,
        leadPackageProducts: [{
            productType: { productTypeId: null, productTypeName: "" },
            product: {
                productId: null, productName: "", productDescription: "",
                productPrice: "", productPriceOffered: ""
            }
        }]
    });

    useEffect(() => {
        if (open) {
            getPackages(setPackages);
        }
        if (packageOffer.packageId) {
            getPackageProductDetails(packageOffer.packageId, setPackageProducts);
        }

        if (packageOfferDetails != null && packageOfferDetails.packageId != null && !prevValue) {
            setPrevValue(true);
            setPackageOffer({
                packageId: packageOfferDetails != null && packageOfferDetails.packageId != null ? packageOfferDetails.packageId : "",
                packageName: packageOfferDetails != null && packageOfferDetails.packageName != null ? packageOfferDetails.packageName : "",
                packageDescription: packageOfferDetails != null && packageOfferDetails.packageDescription != null ? packageOfferDetails.packageDescription : "",
                updatedBy: packageOfferDetails != null && packageOfferDetails.updatedBy != null ? packageOfferDetails.updatedBy : null,
                packageOfferBy: packageOfferDetails != null && packageOfferDetails.packageOfferBy != null ? packageOfferDetails.packageOfferBy : "",
                updatedOn: packageOfferDetails != null && packageOfferDetails.updatedOn != null ? packageOfferDetails.updatedOn : null,
                deleted: packageOfferDetails != null && packageOfferDetails.deleted != null ? packageOfferDetails.deleted : false,
                leadPackageProducts: packageOfferDetails && packageOfferDetails.leadPackageProducts ?
                    packageOfferDetails.leadPackageProducts.map((product) => ({
                        productType: {
                            productTypeId: product.productType ? product.productType.productTypeId : null,
                            productTypeName: product.productType ? product.productType.productTypeName : ""
                        },
                        product: {
                            productId: product.product ? product.product.productId : null,
                            productName: product.product ? product.product.productName : "",
                            productDescription: product.product ? product.product.productDescription : "",
                            productPrice: product.product ? product.product.productPrice : "",
                            productPriceOffered: product.product ? product.product.productPriceOffered : ""
                        }
                    })) : [{
                        productType: { productTypeId: null, productTypeName: "" },
                        product: {
                            productId: null, productName: "", productDescription: "",
                            productPrice: "", productPriceOffered: ""
                        }
                    }]
            });
        }
    }, [open, packageOffer.packageId, packageOfferDetails, prevValue]);

    const handlePackageOfferChange = (fieldName, value) => {
        setFieldValueChange(true);
        const isSameValue = packageOffer[fieldName] === value;
        const isNotEmptyOrNull = value !== '' && value !== null;

        setPackageOffer((packageOffer) => {
            if (fieldName.startsWith('packageOffer.')) {
                const existingProductIndex = packageOffer.leadPackageProducts.findIndex(
                    (p) => p.productType.productTypeId === value.productType.productTypeId
                );

                let updatedProducts = [...packageOffer.leadPackageProducts];
                if (existingProductIndex !== -1) {
                    if (value.product.productId === null) {
                        updatedProducts = packageOffer.leadPackageProducts.filter(
                            item => item.productType.productTypeId !== value.productType.productTypeId
                        );
                    } else {
                        updatedProducts[existingProductIndex] = {
                            ...updatedProducts[existingProductIndex],
                            ...value,
                        };
                    }
                } else {
                    updatedProducts.push(value);
                }

                updatedProducts = updatedProducts.filter(
                    (product) => product.product.productId !== null || product.productType.productTypeId !== null
                );

                return {
                    ...packageOffer,
                    leadPackageProducts: updatedProducts,
                };
            }
            if (fieldName === 'packageId') {
                const selectedPackage = packages.find(item => item.packageid === value);

                return {
                    ...packageOffer,
                    'packageId': value,
                    'packageName': selectedPackage.packagename,
                    'packageDescription': selectedPackage.packagename,
                    'leadPackageProducts': [{
                        productType: { productTypeId: null, productTypeName: "" },
                        product: {
                            productId: null, productName: "", productDescription: "",
                            productPrice: "", productPriceOffered: ""
                        }
                    }]
                };
            }
        });

        if (isNotEmptyOrNull && !isSameValue) {
            setIsFormDirty(true);
        } else if (isSameValue) {
            setIsFormDirty(false);
        } else {
            setIsFormDirty(false);
        }
    };

    const handleProductTypeChange = (productTypeId, productId, priceOffered) => {
        let selectedLeadPackageProducts = {}
        const productType = packageProducts.find(item => item.productTypeId === productTypeId);

        if (productId === 'PleaseSelect') {
            selectedLeadPackageProducts = {
                productType: {
                    productTypeId: productType.productTypeId,
                    productTypeName: productType.productTypeName
                },
                product: {
                    productId: null, productName: "", productDescription: "",
                    productPrice: "", productPriceOffered: ""
                }
            }
        } else {
            const product = productType.products.find(item => item.productId === productId);
            selectedLeadPackageProducts = {
                productType: {
                    productTypeId: productType.productTypeId,
                    productTypeName: productType.productTypeName
                },
                product: {
                    productId: product && product.productId,
                    productName: product && product.productName,
                    productDescription: product && product.productDescription,
                    productPrice: product && product.productPrice,
                    productPriceOffered: priceOffered
                }
            }
        }

        handlePackageOfferChange('packageOffer.leadPackageProducts', selectedLeadPackageProducts);
    };

    const handleClear = () => {
        setPackageOffer({
            packageId: "", packageName: "", packageDescription: "", updatedBy: null, packageOfferBy: "",
            updatedOn: null, deleted: false,
            leadPackageProducts: [{
                productType: { productTypeId: null, productTypeName: "" },
                product: {
                    productId: null, productName: "", productDescription: "",
                    productPrice: "", productPriceOffered: ""
                }
            }]
        });
        setPackageProducts([]);
        handleLeadChange('packageOffer', {
            packageId: "", packageName: "", packageDescription: "", updatedBy: null, packageOfferBy: "",
            updatedOn: null, deleted: false,
            leadPackageProducts: [{
                productType: { productTypeId: null, productTypeName: "" },
                product: {
                    productId: null, productName: "", productDescription: "",
                    productPrice: "", productPriceOffered: ""
                }
            }]
        });
    };

    const handleSaveOffer = () => {
        handleLeadChange('packageOffer', packageOffer);
        setFieldValueChange(false)
        onClose();
    };

    useEffect(() => {
        if (isFormDirty) {
            const handleBeforeUnload = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    e.returnValue = message;
                    return message;
                }
            };

            const handleNavClick = (e) => {
                if (isFormDirty) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    if (!window.confirm(message)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    } else {
                        setIsFormDirty(false);
                        if (packageOfferDetails.packageId == null) {
                            handleClear();
                        }
                        setPrevValue(false);
                    }
                }
            };

            const handleClearClick = (e) => {
                if (isFormDirty) {
                    const message = 'You have unsaved changes. Are you sure you want to clear?';
                    if (!window.confirm(message)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            const handlePopState = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    const userConfirmed = window.confirm(message);
                    if (!userConfirmed) {
                        window.history.pushState(null, '', window.location.pathname);
                    } else {
                        setIsFormDirty(false);
                        if (packageOfferDetails != null && packageOfferDetails.packageId != null) {
                            handleClear();
                        }
                        setPrevValue(false);
                    }
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            window.history.pushState(null, '', window.location.pathname);

            const navLinks = document.querySelectorAll('.nav-link');
            navLinks.forEach((link) => {
                link.addEventListener('click', handleNavClick);
            });

            const clearClick = document.querySelectorAll('.clear-button');
            clearClick.forEach((link) => {
                link.addEventListener('click', handleClearClick);
            });

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
                navLinks.forEach((link) => {
                    link.removeEventListener('click', handleNavClick);
                });
                clearClick.forEach((link) => {
                    link.removeEventListener('click', handleClearClick);
                });
            };
        }
        // eslint-disable-next-line
    }, [isFormDirty]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{packageOfferDetails.packageId != null
                ? 'Edit Package Offer' : 'Package Offer'}</DialogTitle>
            <IconButton aria-label="close" onClick={onClose} className="nav-link"
                sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ padding: '16px 24px' }}>
                <Typography gutterBottom sx={{ paddingBottom: '2px', fontFamily: 'DM Sans', fontSize: '1rem', color: '#000000', fontWeight: 500 }}>Package Details</Typography>
                <div className='package-row'>
                    <div className='package-col'>
                        <FormControl sx={{ width: windowWidth <= 799 ? '100%' : '550px' }}>
                            <InputLabel id="package-type-select-label">Package Type</InputLabel>
                            <Select labelId="package-type-select-label" id="package-type-select"
                                label="Package Type"
                                value={packageOffer.packageId}
                                onChange={(e) => handlePackageOfferChange('packageId', e.target.value)}
                            >
                                {packages.map((pkg) => (
                                    <MenuItem key={pkg.packageid} value={pkg.packageid}>{pkg.packagename}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {packageProducts.map((productType) => (
                        <div key={productType.productTypeId} className="products">
                            <div>{productType.productTypeName}</div>
                            <div className="product-section">
                                <FormControl style={{ width: '382px', marginBottom: '12px' }} >
                                    <InputLabel id={`product-select-label-${productType.productTypeId}`}>Product</InputLabel>
                                    <Select labelId={`product-select-label-${productType.productTypeId}`}
                                        id={`product-select-${productType.productTypeId}`}
                                        label="Product" name="product"
                                        value={packageOffer.leadPackageProducts.find(item => item.productType.productTypeId === productType.productTypeId)?.product?.productId || ''}
                                        onChange={(e) => handleProductTypeChange(productType.productTypeId, e.target.value, null)}
                                    >
                                        <MenuItem value='PleaseSelect'>Please Select</MenuItem>
                                        {productType.products.map((product) => (
                                            <MenuItem key={product.productId} value={product.productId}>{product.productName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    id={`outlined-required-price-${productType.productTypeId}`}
                                    label={<span>Price</span>} name="price"
                                    value={packageOffer.leadPackageProducts.find(
                                        item => item.productType.productTypeId === productType.productTypeId
                                    ) ? packageOffer.leadPackageProducts.find(
                                        item => item.productType.productTypeId === productType.productTypeId
                                    ).product.productPrice || '' : ''}
                                    inputProps={{ maxLength: 50, readOnly: true }} disabled
                                />
                                <TextField
                                    id={`outlined-required-price-offered-${productType.productTypeId}`}
                                    label={<span>Price Offered
                                        {packageOffer.leadPackageProducts.find(
                                            item => item.productType.productTypeId === productType.productTypeId
                                        ) &&
                                            packageOffer.leadPackageProducts.find(
                                                item => item.product.productId !== null
                                            ) && <span style={{ color: 'red' }}>*</span>}
                                    </span>}
                                    name="priceoffered"
                                    value={packageOffer.leadPackageProducts.find(
                                        item => item.productType.productTypeId === productType.productTypeId
                                    ) ? packageOffer.leadPackageProducts.find(
                                        item => item.productType.productTypeId === productType.productTypeId
                                    ).product.productPriceOffered || "" : ""}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    onChange={(e) => handleProductTypeChange(productType.productTypeId, packageOffer.leadPackageProducts.find(
                                        item => item.productType.productTypeId === productType.productTypeId
                                    ) ? packageOffer.leadPackageProducts.find(
                                        item => item.productType.productTypeId === productType.productTypeId
                                    ).product.productId || '' : '', e.target.value)}
                                    disabled={!packageOffer.leadPackageProducts.find(
                                        item => item.productType.productTypeId === productType.productTypeId
                                    ) ||
                                        !packageOffer.leadPackageProducts.find(
                                            item => item.product.productId !== null
                                        )}
                                    error={packageOffer.leadPackageProducts.find(item => item.productType.productTypeId === productType.productTypeId)
                                        && packageOffer.leadPackageProducts.find(item => item.productType.productTypeId === productType.productTypeId)
                                            .product.productPriceOffered > packageOffer.leadPackageProducts.find(item => item.productType.productTypeId === productType.productTypeId)
                                                .product.productPrice
                                    }
                                    helperText={packageOffer.leadPackageProducts.find(item => item.productType.productTypeId === productType.productTypeId)
                                        && packageOffer.leadPackageProducts.find(item => item.productType.productTypeId === productType.productTypeId)
                                            .product.productPriceOffered > packageOffer.leadPackageProducts.find(item => item.productType.productTypeId === productType.productTypeId)
                                                .product.productPrice ? 'Price Offer should less than product price' : ''}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: '12px 24px 24px' }}>
                {fromPage === 'Edit Lead'
                    ? <Button className="nav-link" variant="outlined" onClick={onClose}
                        sx={{ color: '#e563a4', textTransform: 'capitalize', '&:hover': { color: '#e326a4' } }}>Cancel</Button>
                    : <Button className="clear-button" variant="outlined" onClick={handleClear}
                        sx={{ color: '#e563a4', textTransform: 'capitalize', '&:hover': { color: '#e326a4' } }}>Clear</Button>
                }
                <Button variant="contained" sx={{
                    backgroundColor: '#e563a4', textTransform: 'capitalize', '&:hover': { backgroundColor: '#e326a4' }
                }} disabled={!fieldValueChanged || packageOffer.leadPackageProducts.some(item =>
                    item.product.productPriceOffered == null || item.product.productPriceOffered === '' ||
                    (item.product.productPriceOffered > item.product.productPrice)
                ) || packageOffer.leadPackageProducts.length < 1} onClick={handleSaveOffer}>Save Offer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PackageOfferDialog;
