import { Button, Card, CardActions, CardContent, Checkbox, Divider, FormControlLabel, Skeleton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { UserDetailsContext, WindowWidthContext } from '../../context/user-details-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchECLead, toTitleCase } from '../ec-leads-helper';
import EditIcon from '@mui/icons-material/Edit';

function ViewInternalECLead() {
    const { loggedUser } = useContext(UserDetailsContext);
    const location = useLocation();
    const navigate = useNavigate();
    const windowWidth = useContext(WindowWidthContext);
    const ecLeadId = location.state?.ecLeadId;
    const [loading, setLoading] = useState(false);
    const [expanded, setSetExpanded] = useState(false);
    const [leadDetails, setLeadsDetails] = useState({
        fullName: "", contactMobile: "", contactEmail: "", leadType: "", companyName: "",
        location: null, source: "", proposalSent: false, packageName: null, status: "Prospect",
        probabilityStatus: null, mobileVerified: false, emailVerified: false, agentId: null, notes: [{ id: null, note: null, deleted: false, updatedOn: null, createdBy: null }], newNote: null,
        packageOffer: {
            packageId: null, packageName: "", packageDescription: "", updatedBy: null, packageOfferBy: "",
            updatedOn: null, deleted: false, leadPackageProducts: [{
                productType: { productTypeId: null, productTypeName: "" },
                product: {
                    productId: null, productName: "", productDescription: "",
                    productPrice: null, productPriceOffered: null
                }
            }
            ]
        }
    });

    useEffect(() => {
        fetchECLead(setLeadsDetails, ecLeadId, setLoading, null);
    }, [ecLeadId]);

    const handleBack = () => {
        navigate('/enterprise-client/ec-leads-dashboard');
    }

    const handleToggleExpand = () => {
        setSetExpanded(!expanded);
    }

    const editECLead = () => {
        navigate("/enterprise-client/create-internal-ec-lead", { state: { ecLeadId, fromPage: 'View' } });
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && (
                <Skeleton variant="rectangular" width="100%" height="100%"
                    sx={{
                        position: 'absolute', top: 0, left: 0, zIndex: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)'
                    }}
                />
            )}
            <Card sx={{ minWidth: 275, padding: '20px', position: 'relative', zIndex: 0, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <CardContent>
                    <div className='view-lead-title-row'>
                        <Typography variant="body2" sx={{
                            flex: windowWidth <= 799 ? '1 1 100%' : '1 1 auto', color: '#3b4758', fontFamily: 'DM Sans',
                            fontSize: windowWidth <= 799 ? '24px' : '1.5rem', fontWeight: 600, opacity: 1
                        }}>
                            Lead Details
                        </Typography>
                        {loggedUser && loggedUser.access.includes('Lead:Edit') && leadDetails && leadDetails.status !== 'Won' && loggedUser.userId === leadDetails.agentId &&
                            <Button variant="contained" onClick={() => editECLead()} sx={{
                                backgroundColor: '#7b5ede', '&:hover': {
                                    backgroundColor: '#5a32e4'
                                }
                            }} startIcon={<EditIcon />}>Edit</Button>
                        }
                    </div>
                    <div className={windowWidth <= 799 ? 'create-lead-row-1 create-lead-row-1-mobile' : 'create-lead-row-1'}>
                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Full Name:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.fullName ? toTitleCase(leadDetails.fullName) : 'N/A'}</Typography>
                            </div>

                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Contact Mobile:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.contactMobile ? leadDetails.contactMobile : 'N/A'}</Typography>
                            </div>
                        </div>

                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Company Name:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.companyName ? leadDetails.companyName : 'N/A'}</Typography>
                            </div>

                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Contact Email:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600, textTransform: 'lowercase' }}>{leadDetails.contactEmail ? leadDetails.contactEmail : 'N/A'}</Typography>
                            </div>
                        </div>

                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Location:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.location ? leadDetails.location : 'N/A'}</Typography>
                            </div>
                        </div>
                    </div>
                    <Divider sx={{ margin: '7px 0px', borderWidth: '1px', borderColor: 'rgb(0 0 0 / 21%)' }} />
                    <div className={windowWidth <= 799 ? 'create-lead-row-1 create-lead-row-1-mobile' : 'create-lead-row-1'}>
                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Source:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.source ? leadDetails.source : 'N/A'}</Typography>
                            </div>

                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Probability:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.probabilityStatus ? leadDetails.probabilityStatus : 'N/A'}</Typography>
                            </div>
                        </div>

                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Lead Type:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.leadType ? leadDetails.leadType : 'N/A'}</Typography>
                            </div>
                        </div>

                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <div className='view-lead-item'>
                                <div className="view-lead-heading-text">Status:</div>
                                <Typography sx={{ color: '#7D8FA9', fontSize: '16px', fontFamily: 'DM Sans', fontWeight: 600 }}>{leadDetails.status ? leadDetails.status : 'N/A'}</Typography>
                            </div>
                        </div>
                    </div>
                    {leadDetails.proposalSent &&
                        <div className={windowWidth <= 799 ? 'create-lead-row-2 create-lead-row-2-mobile' : 'create-lead-row-2'}>
                            <div className='proposal-sent-col-1'>
                                <FormControlLabel control={<Checkbox name="proposalSent" checked={leadDetails.proposalSent} sx={{
                                    color: '#f48fb1', '&.Mui-checked': { color: '#f48fb1' }
                                }} />} label="Proposal Sent" />
                            </div>

                            <div className={windowWidth <= 799 ? 'proposal-sent-col-2 proposal-sent-col-2-mobile' : 'proposal-sent-col-2'}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant="h6">
                                            {leadDetails.packageOffer.packageName}
                                        </Typography>
                                        {leadDetails.packageOffer.leadPackageProducts.map((item) => (
                                            <Typography variant="body2" color="text.secondary">
                                                {`${item.productType.productTypeName}, ${item.product.productName}, ${item.product.productPriceOffered}`}
                                            </Typography>
                                        )
                                        )}
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    }
                    <div className='create-lead-row-3'>
                        <Typography variant="body2" sx={{ fontSize: '1rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Notes
                        </Typography>
                    </div>
                    <div className='create-lead-row-3'>
                        {(leadDetails.notes
                            .filter(note => !note.deleted))
                            .sort((a, b) => b.id - a.id)
                            .slice(0, expanded ? leadDetails.notes.length : 2).map((notes, index) => (
                                notes.note != null
                                    ? (
                                        <Card sx={{ minWidth: 275, marginBottom: '12px', }}>
                                            <CardContent sx={{ padding: '12px 12px 0px 12px', '&.MuiCardContent-root:last-child': { paddingBottom: '0px !important' } }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                                        {notes.updatedOn}
                                                    </Typography>
                                                    <Typography color="text.secondary">
                                                        {notes.createdBy}
                                                    </Typography>
                                                </div>
                                                <Typography dangerouslySetInnerHTML={{ __html: notes.note }} />
                                            </CardContent>
                                        </Card>
                                    ) : (
                                        <Typography key={index} variant="body2" sx={{
                                            fontSize: 'x-large', display: 'flex', justifyContent: 'center', color: 'gray',
                                            textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'
                                        }} >
                                            No Notes Added
                                        </Typography>
                                    )
                            ))}
                        {leadDetails.notes.length > 2 && <Button sx={{
                            disaplay: 'flex', justifyContent: 'flex-end', color: '#e563a4', '&:hover': { color: '#e326a4' }
                        }} onClick={() => handleToggleExpand()}>{expanded ? 'Show Less' : 'Read More'}</Button>}
                    </div>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined" sx={{ color: '#e563a4', '&:hover': { color: '#e326a4' } }} onClick={handleBack}>Close</Button>
                </CardActions>
            </Card>
        </div >
    )
}

export default ViewInternalECLead;
