import React, { useContext, useEffect, useState } from 'react'
import { Alert, Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import LeadsTableToolbar from './leads-table-toolbar';
import LeadsTableHead from './leads-table-head';
import '../../../css/leads.css';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { exportLeads, getLeads, getLeadsTags, updateLeadStatus, updateLeadTag } from '../leads-helper';
import CommonPagination from '../../common/common-pagination';
import { StyledTableRow, getStatusStyles } from '../../../mui/mui-styling';
import moment from 'moment';
import { WindowWidthContext } from '../../context/user-details-context';

function createData(id, fullName, phoneNumber, emailAddress, createdTime, status, tagList) {
    const tags = tagList ? tagList.split(',').map(tag => tag.trim()) : [];
    return { id, fullName, phoneNumber, emailAddress, createdTime, status, tags };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function LeadsManagement() {
    const windowWidth = useContext(WindowWidthContext);
    const [selected, setSelected] = useState([]);
    const [rowsPerPage, setRowPerPage] = useState(10);
    const [selectedLead, setSelectedLead] = useState([]);
    const [leads, setLeads] = useState([]);
    const [newLeadCount, setNewLeadCount] = useState(null);
    const [notifyCount, setnotifyCount] = useState(null);
    const [inDiscussionCount, setinDiscussionCount] = useState(null);
    const [rejectedCount, setrejectedCount] = useState(null);
    const [registeredCount, setregisteredCount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalLeads, setTotalLeads] = useState();
    const [apiFailed, setApiFailed] = useState(false);
    const [campaignFilterValue, setCampaignFilterValue] = useState(null);
    const [dateFilterValue, setDateFilterValue] = useState({ from: "", to: "" });
    const [statusFilterValue, setStatusFilterValue] = useState(null);
    const [tagsFilterValue, setTagsFilterValue] = useState(null);
    const [searchString, setSearchString] = useState(null);
    const [isStatusEditing, setIsStatusEditing] = useState(false);
    const [isTagEditing, setIsTagEditing] = useState(false);
    const [leadsStatus, setLeadsStatus] = useState([]);
    const [leadsTags, setLeadsTags] = useState([]);
    const [newTags, setNewTags] = useState([]);
    const [tagUpdated, setTagUpdated] = useState(null);
    const [updateSuccess, setUpdateSuccess] = useState(null);
    const [goToPage, setGoToPage] = useState(1);
    const [statusSuccessAlert, setStatusSuccessAlert] = useState(false);
    const [statusFailedAlert, setStatusFailedAlert] = useState(false);
    const [tagSuccessAlert, setTagSuccessAlert] = useState(false);
    const [tagFailedAlert, setTagFailedAlert] = useState(false);

    useEffect(() => {
        getLeads(createData, setLoading, currentPage, rowsPerPage, setTotalLeads, setLeads, setApiFailed, campaignFilterValue, dateFilterValue, statusFilterValue, tagsFilterValue, searchString,
            setNewLeadCount, setnotifyCount, setinDiscussionCount, setrejectedCount, setregisteredCount
        );
    }, [currentPage, tagUpdated, updateSuccess, campaignFilterValue, dateFilterValue, statusFilterValue, tagsFilterValue, rowsPerPage, searchString]);

    useEffect(() => {
        getLeadsTags(setLeadsTags);
    }, [tagUpdated]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setStatusFailedAlert(false);
            setStatusSuccessAlert(false);
            setTagFailedAlert(false);
            setTagSuccessAlert(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, [updateSuccess, tagUpdated]);

    const handleSelectAllClick = (event) => {
        if (selected.length === leads.length) {
            setSelected([]);
        } else {
            const newSelected = leads.map((lead) => lead.phoneNumber);
            setSelected(newSelected);
        }
    };

    const handleClick = (event, phoneNumber) => {
        event.stopPropagation();
        setSelected((prevSelected) => {
            if (prevSelected.includes(phoneNumber)) {
                return prevSelected.filter((selectedId) => selectedId !== phoneNumber);
            } else {
                return [...prevSelected, phoneNumber];
            }
        });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setSelected([]);
        setGoToPage(value);
    };

    const StatusChip = ({ row }) => {
        return <Button variant="contained" sx={getStatusStyles(row.status)}
            endIcon={row.status !== 'Registered' && <EditIcon onClick={() => changeStatus(row)} />}>{row.status}
        </Button>
    };

    const StatusDropdown = ({ status }) => {
        return (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">{status}</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={handleEditStatus}
                >
                    {leadsStatus.filter((leadStatus) => leadStatus !== 'Registered')
                        .map((leadStatus) => (
                            <MenuItem value={leadStatus}>{leadStatus}</MenuItem>
                        ))}
                </Select>
            </FormControl>
        );
    };

    const changeStatus = (lead) => {
        setIsStatusEditing(true);
        setSelectedLead(lead);
    }

    const changeTag = (lead) => {
        setIsStatusEditing(false);
        setIsTagEditing(true);
        setSelectedLead(lead);

        if (lead.tags) {
            setNewTags(lead.tags);
        } else {
            setNewTags([]);
        }
    };

    const handleEditStatus = (event) => {
        updateLeadStatus(event.target.value, selectedLead.phoneNumber, setIsStatusEditing, setUpdateSuccess, setStatusSuccessAlert, setStatusFailedAlert, null, setSelected);
    }

    const handleNewTagsChange = (_, newValue) => {
        setNewTags(newValue);
        setIsStatusEditing(false);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setCurrentPage(goToPage === '' ? 1 : goToPage);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && e.target.value.length === 1) {
            setGoToPage(1);
            setCurrentPage(1);
            setIsStatusEditing(false);
        }
    };

    const handleBlur = () => {
        if (goToPage === '') {
            setGoToPage(1);
            setCurrentPage(1);
            setIsStatusEditing(false);
        }
    };

    const handleExport = () => {
        exportLeads(campaignFilterValue, dateFilterValue, statusFilterValue, tagsFilterValue, searchString);
    }

    const handleRowPerPageChange = (value) => {
        setRowPerPage(value);
        setGoToPage(1);
        setCurrentPage(1);
        setSelected([]);
        setIsStatusEditing(false);
    }
    return (
        <div>
            <div className='leads-alert-message'>
                {(statusSuccessAlert || statusFailedAlert) &&
                    <Alert severity={statusSuccessAlert ? "success" : "error"}>{statusSuccessAlert ? "Status Update Successfully." : "Something Went Worng"}</Alert>
                }
                {(tagSuccessAlert || tagFailedAlert) &&
                    <Alert severity={tagSuccessAlert ? "success" : "error"}>{tagSuccessAlert ? "Tag Added Successfully." : "Something Went Worng"}</Alert>
                }
            </div>
            <Box sx={{ width: windowWidth <= 799 ? '100vw' : '100%' }}>
                <Paper sx={{ width: windowWidth <= 799 ? '100vw' : '100%', mb: 2, backgroundColor: '#f4f5f8', padding: '0px 20px 20px 20px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                    <LeadsTableToolbar windowWidth={windowWidth} leadsTags={leadsTags} setCurrentPage={setCurrentPage} setGoToPage={setGoToPage} selected={selected}
                        leadsStatus={leadsStatus} updateSuccess={updateSuccess} setUpdateSuccess={setUpdateSuccess} setSearchString={setSearchString}
                        setStatusSuccessAlert={setStatusSuccessAlert} setStatusFailedAlert={setStatusFailedAlert} setSelected={setSelected} campaignFilterValue={campaignFilterValue} setCampaignFilterValue={setCampaignFilterValue}
                        handleExport={handleExport} setIsTagEditing={setIsTagEditing} setTagUpdated={setTagUpdated} loading={loading} apiFailed={loading} setTagSuccessAlert={setTagSuccessAlert} setTagFailedAlert={setTagFailedAlert} />
                    <Alert icon={false} severity="success" sx={{ display: 'flex', justifyContent: 'center', fontSize: '1.2rem', whiteSpace: 'pre-wrap', fontFamily: 'DM Sans', color: '#3b4758', opacity: 1, fontWeight: 600 }}>
                        New Lead: {apiFailed || loading ? 0 : newLeadCount},&#8203;
                        Notified: {apiFailed || loading ? 0 : notifyCount},&#8203;
                        In-Discussion: {apiFailed || loading ? 0 : inDiscussionCount},&#8203;
                        Rejected: {apiFailed || loading ? 0 : rejectedCount},&#8203;
                        Registered: {apiFailed || loading ? 0 : registeredCount}
                    </Alert>
                    <TableContainer sx={{ padding: '0px 15px 25px 15px' }}>
                        <Table aria-labelledby="tableTitle">
                            <LeadsTableHead handleSelectAllClick={handleSelectAllClick}
                                selected={selected} setSelected={setSelected} leadsStatus={leadsStatus} setLeadsStatus={setLeadsStatus}
                                leadsTags={leadsTags} setDateFilterValue={setDateFilterValue} statusFilterValue={statusFilterValue}
                                setStatusFilterValue={setStatusFilterValue} tagsFilterValue={tagsFilterValue}
                                setTagsFilterValue={setTagsFilterValue} setCurrentPage={setCurrentPage} setGoToPage={setGoToPage} setIsStatusEditing={setIsStatusEditing}
                            />
                            <TableBody>
                                {
                                    loading ?
                                        <TableRow>
                                            <TableCell align="center" colSpan={8} style={{ borderBottom: 'none', padding: 0 }}>
                                                <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {loading ? <CircularProgress /> : null}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        :
                                        apiFailed
                                            ? (
                                                <TableRow>
                                                    <TableCell align="center" colSpan={8} style={{ borderBottom: 'none', padding: 0 }}>
                                                        <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {apiFailed && !loading ? <div className="nodata-found">No Leads Found</div> : null}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            : (leads.map((row, index) => {
                                                const isItemSelected = selected.includes(row.phoneNumber);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <StyledTableRow className='top-campaign-row'
                                                        tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox color="secondary" checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId, }}
                                                                onClick={(event) => handleClick(event, row.phoneNumber)}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '20px 10px' }} align="center" component="th" id={labelId} scope="row">
                                                            <Tooltip title={row.fullName} arrow>
                                                                <div className='leads-row-text'>{row.fullName}</div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 100 }} align="center">
                                                            <Tooltip title={row.phoneNumber} arrow>
                                                                <div className='leads-row-text'>{row.phoneNumber}</div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 180 }} align="center">
                                                            <Tooltip title={row.emailAddress} arrow>
                                                                <div className='leads-row-text'>{row.emailAddress}</div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 100 }} align="center">
                                                            <Tooltip title={row.createdTime !== null && moment(row.createdTime).format("l")} arrow>
                                                                <div className='leads-row-text'>{row.createdTime !== null && moment(row.createdTime).format("l")}</div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '20px 10px' }} align="center">
                                                            {isStatusEditing && selectedLead.phoneNumber === row.phoneNumber
                                                                ? <StatusDropdown status={row.status} />
                                                                : <StatusChip row={row} />
                                                            }
                                                        </TableCell>
                                                        <TableCell sx={{ maxWidth: 170, padding: '20px 10px', textAlign: '-webkit-center' }} align="center">
                                                            <Button color='secondary'
                                                                variant="contained"
                                                                sx={{
                                                                    width: '100%',
                                                                    borderRadius: '50px',
                                                                    textTransform: 'capitalize',
                                                                    '&:hover': { backgroundColor: '#FFFFFF', color: '#586a84' },
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    overflow: 'hidden'
                                                                }}
                                                                onClick={() => changeTag(row)}
                                                                endIcon={<AddIcon />}
                                                            >
                                                                <Box component="span"
                                                                    sx={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        flexGrow: 1,
                                                                        marginRight: '8px'
                                                                    }}
                                                                >
                                                                    {row.tags.length > 0 ? row.tags[0] : 'Add Tags'}
                                                                </Box>
                                                                {row.tags.length > 1 && (
                                                                    <Box component="span" sx={{ whiteSpace: 'nowrap', marginRight: '8px' }}>
                                                                        +{row.tags.length - 1}
                                                                    </Box>
                                                                )}
                                                            </Button>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            }))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={windowWidth <= 799 ? 'leads-table-mobile' : 'leads-table-footer'}>
                        <div className='leads-table-footer'>
                            <div>
                                Showing {apiFailed || loading ? 0 : ((currentPage - 1) * rowsPerPage) + 1} - {apiFailed || loading ? 0 : Math.min(currentPage * rowsPerPage, totalLeads)} of {apiFailed || loading ? 0 : totalLeads}
                            </div>
                            <div className={windowWidth <= 799 ? 'go-to-page-mobile' : 'go-to-page'}>
                                Go to page :
                                <TextField type="number" id="outlined-size-normal" defaultValue="1" style={{ width: 'fit-content', backgroundColor: '#fff' }}
                                    variant="outlined" value={goToPage} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown}
                                    onBlur={handleBlur} onChange={(e) => {
                                        let value = parseInt(e.target.value, 10);
                                        if (isNaN(value)) {
                                            setGoToPage('');
                                        } else if (value < 1 || value > Math.ceil(totalLeads / rowsPerPage)) {
                                            setGoToPage('');
                                        } else {
                                            setGoToPage(value);
                                        }
                                    }}
                                    inputProps={{ min: 1, max: apiFailed || loading ? 1 : Math.ceil(totalLeads / rowsPerPage) }} />
                            </div>
                        </div>
                        <div className='leads-table-footer'>
                            <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', columnGap: '13px' }}>
                                <div>Records Per page :</div>
                                <FormControl>
                                    <Select sx={{ backgroundColor: '#fff', width: 'fit-content' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={rowsPerPage}
                                        onChange={(e) => handleRowPerPageChange(e.target.value)}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <CommonPagination currentPage={currentPage} totalPages={Math.ceil(totalLeads / rowsPerPage)}
                                handlePageChange={handlePageChange} />
                        </div>
                    </div>
                    <Dialog open={isTagEditing} onClose={() => setIsTagEditing(!isTagEditing)}>
                        <DialogTitle>Add / Edit Tags</DialogTitle>
                        <DialogContent sx={{ paddingTop: '12px !important' }}>
                            <Autocomplete
                                multiple
                                freeSolo
                                options={leadsTags}
                                disableCloseOnSelect
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.inputValue)}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                style={{ width: 378 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Tags" />
                                )}
                                onChange={(event, newValue) => {
                                    const updatedTags = newValue.map((option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        return option.inputValue;
                                    });
                                    handleNewTagsChange(event, updatedTags);
                                }}
                                value={newTags}
                                renderTags={(value, getTagProps) => (
                                    <>
                                        {value.slice(0, 2).map((option, index) => (
                                            <Chip
                                                key={index}
                                                title={option}
                                                label={option}
                                                {...getTagProps({ index })}
                                            />
                                        ))}
                                        {value.length > 2 && (
                                            <Chip
                                                label={`${value.length - 2}+`}
                                                {...getTagProps({ index: value.length - 1 })}
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsTagEditing(false)}>Cancel</Button>
                            <Button onClick={() => { updateLeadTag(newTags, selectedLead.phoneNumber, setIsTagEditing, setTagUpdated, null, null, null, setSelected); }} autoFocus>
                                Add Tags
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Box>
        </div>
    )
}

export default LeadsManagement;
