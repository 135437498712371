import React, { useEffect, useState, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Typography, Skeleton, Tooltip } from '@mui/material';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { getInternalECLeadDaily } from '../ec-leads-helper'; 
import { UserDetailsContext, WindowWidthContext } from '../../context/user-details-context';
import FunctionsIcon from '@mui/icons-material/Functions';


function ECLeadsOverview() {
    const [eCLeadsOverview, setECLeadsOverview] = useState({
        leadsInProgress: 0, leadsWon: 0, leadsLost: 0, newLeads: 0, todaysTotal: 0
    });
    const [loading, setLoading] = useState(false);
    const { loggedUser } = useContext(UserDetailsContext);
    const windowWidth = useContext(WindowWidthContext);
 

    const eCLeadsOverviewMap = [
        { icon: <LocalMallIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: eCLeadsOverview.leadsInProgress, label: 'In Progress', tooltip: 'Leads In Progress' },
        { icon: <MonetizationOnIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: eCLeadsOverview.leadsWon, label: 'Won', tooltip: 'Leads Won' },
        { icon: <ThumbDownAltIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: eCLeadsOverview.leadsLost, label: 'Lost', tooltip: 'Leads Lost' },
        { icon: <HowToRegIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: eCLeadsOverview.newLeads, label: 'New', tooltip: 'New Leads' },
        { icon: <FunctionsIcon sx={{ fontSize: 30, color: '#9c27b0' }} />, value: eCLeadsOverview.todaysTotal, label: 'Todays Total', tooltip: 'Todays Total' }
    ];

    useEffect(() => {
        if (loggedUser) {
            setLoading(true);
            getInternalECLeadDaily(loggedUser.userId, (data) => {
                setECLeadsOverview({
                    leadsInProgress: data.inProgress,
                    leadsWon: data.ecWon,
                    leadsLost: data.ecLost,
                    newLeads: data.newLead,
                    todaysTotal: data.todaysTotal
                });
                setLoading(false);
            });
        }
    }, [loggedUser]);

    return (
        <Grid container spacing={5} sx={{flexWrap: windowWidth <= 799 ?'wrap':'nowrap'}}>
            {eCLeadsOverviewMap.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} md={3}>
                    <div style={{ position: 'relative' }}>
                        {loading && (
                            <Skeleton variant="rectangular" width="100%" height="100%"
                                sx={{
                                    position: 'absolute', top: 0, left: 0, zIndex: 1,
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)'
                                }}
                            />
                        )}
                        <Card sx={{ minWidth: 200, position: 'relative', zIndex: 0, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                            <CardContent>
                                <div className='icon-background'>
                                    {item.icon}
                                </div>
                                <Typography sx={{ mt: 1.5, mb: 1.5, fontSize: '1.3rem' }} color="text.secondary">
                                    {item.value}
                                </Typography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={item.label}>
                                        <Typography variant="body2" sx={{ fontSize: '1.4rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            {item.label}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title={item.tooltip}>
                                        <InfoOutlinedIcon sx={{ m: 1 }} />
                                    </Tooltip>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </Grid>
            ))}
        </Grid>
    )
}

export default ECLeadsOverview;
