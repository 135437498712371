import React, { useContext, useEffect, useState } from "react";
import "../../css/campaign.css";
import { Badge, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { cancelCampaign, closeCampaign, discardCampiagn, useUpdateCampaignStatus } from "./campaign-repository";
import InstagramIcon from '@mui/icons-material/Instagram';
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { downloadFiles, getCampaign, handleApplyCampaign } from "./campaign-repository";
import { UserDetailsContext } from "../context/user-details-context";
import MailIcon from '@mui/icons-material/Mail';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import DialogBox from "../common/dialog-box";
import CustomAlert from "../common/alert";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function CampaignDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const updateCampaignsStatus = useUpdateCampaignStatus();
    const pathname = window.location.pathname;
    const pathSegments = pathname.split('/');
    const startPath = '/' + pathSegments[1];
    const { loggedUser } = useContext(UserDetailsContext);
    const [campaign, setCampaign] = useState();
    const [openInstaHandleAlert, setOpenInstaHandleAlert] = useState(false);
    const [apply, setApply] = useState('');
    const [campaignid] = useState(location.state.campaignid);
    const date = new Date();
    const currentDate = date.toISOString().slice(0, 10);
    const [openClosedCampaign, setOpenClosedCampaign] = useState(false);

    useEffect(() => {
        getCampaign(campaignid, setCampaign);
    }, [apply, campaignid]);

    const handleEditCampaign = (campaignStatus) => {
        const pathSegments = pathname.split('/');
        const startPath = '/' + pathSegments[1];
        navigate(`${startPath}/create`, { state: { campaign, status: campaignStatus, fromPage: 'details' } });
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [openWithdrawConfirmationDialog, setOpenWithdrawConfirmationDialog] = useState(false);

    // const handleRaiseInvoice = (campaignName) => {
    //     const pathSegments = pathname.split('/');
    //     const startPath = '/' + pathSegments[1];
    //     const invoiceId = campaign.invoiceId;
    //     if (invoiceId) {
    //         navigate(`${startPath}/invoice/${invoiceId}`, { state: { campaign, fromPage: 'details' } });
    //     } else {
    //         navigate(`${startPath}/invoice/create-invoice`, { state: { campaign, fromPage: 'details' } });
    //     }
    // };

    const openDialogForCloseCampaign = () => {
        setOpenClosedCampaign(!openClosedCampaign);
    }

    const closedCampaignAction = () => {
        if (campaign.status === 'Live') {
            closeCampaign((campaign && campaign.campaignId), campaign.status, navigate);
        } else if (campaign.status === 'Draft') {
            discardCampiagn((campaign && campaign.campaignId), navigate);
        } else if (campaign.status === 'Pending') {
            cancelCampaign((campaign && campaign.campaignId), campaign.status, navigate);
        }
    }

    const handleCampaignApplication = () => {
        const pathSegments = pathname.split('/');
        const startPath = '/' + pathSegments[1];
        navigate(`${startPath}/applications`, { state: { campaignId: campaign.campaignId, campaignName: campaign.campaignName, noOfCreators: campaign.creators, campaignLastDate: campaign.lastDate, page: 'details' } });
    }
    return (
        <>
            {!campaign ? (
                <div className="data-loading">
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                </div>
            ) : (
                <Box component="main" sx={{ width: "100%" }}>
                    <div className="main-content">
                        <div className="campaign-details">
                            <div className="campaign-details-head">
                                <ArrowBackIcon className="arrow-back" onClick={() => navigate(`${startPath}/${campaign.status.toLowerCase()}`)}></ArrowBackIcon>
                                <h1 className="campaign-header-name">{campaign.campaignName}</h1>
                                <div className="socialmedia-btn lite">
                                    <InstagramIcon sx={{ fontSize: 20 }} />
                                    <label>Instagram</label>
                                </div>
                            </div>
                            <div className="campaign-details-options">
                                {campaign.status === 'Live' &&
                                    <>
                                        <Badge onClick={() => {
                                            if ((campaign.applications > 0 &&
                                                loggedUser.role === 'ADMIN') ||
                                                (campaign.applications > 0 && loggedUser.role === 'ENTERPRISE_CLIENT' && campaign.userId === loggedUser.userId)
                                            ) {
                                                handleCampaignApplication();
                                            }
                                        }}
                                            sx={{ cursor: 'pointer' }} color="secondary" badgeContent={campaign.applications} showZero>
                                            <MailIcon />
                                        </Badge>
                                        {campaign.userId !== loggedUser.userId && loggedUser.role === 'CREATOR' && campaign.lastDate >= currentDate && (
                                            <Button variant="contained" sx={{
                                                backgroundColor: '#e563a4', '&:hover': {
                                                    backgroundColor: '#e326a4'
                                                }
                                            }} onClick={(e) => {
                                                if (campaign.applicationStatus === 'Apply') {
                                                    setOpenWithdrawConfirmationDialog(true);
                                                } else {
                                                    handleApplyCampaign(e, campaign.campaignId, loggedUser.isInstaAccountPrivate, loggedUser.userId, setApply, setOpenInstaHandleAlert, setAlertOpen, setAlertMessage, setAlertSeverity);
                                                }
                                            }}
                                                startIcon={campaign.applicationStatus === null && campaign.applicationStatus !== 'Apply' ? <TouchAppIcon /> : <MoveToInboxIcon />}>
                                                {campaign.applicationStatus != null ? campaign.applicationStatus === 'Apply' ? 'Withdraw' : 'Apply' : 'Apply'}
                                            </Button>
                                        )}
                                    </>
                                }
                                {['Draft', 'Live', 'Pending'].includes(campaign.status) === true && loggedUser.role === 'ADMIN' &&
                                    <>
                                        <Button variant="contained" sx={{
                                            backgroundColor: '#7b5ede', '&:hover': {
                                                backgroundColor: '#5a32e4'
                                            }
                                        }} onClick={() => handleEditCampaign(campaign.status)}
                                            startIcon={<EditIcon />}>Edit</Button>
                                    </>
                                }

                                {campaign.status === 'Pending' && loggedUser.role === 'ADMIN' && (
                                    <Button variant="contained" sx={{
                                        backgroundColor: '#e563a4', '&:hover': {
                                            backgroundColor: '#e326a4'
                                        }
                                    }} onClick={() => updateCampaignsStatus((campaign && campaign.campaignId), 'Live', setAlertOpen, setAlertMessage, setAlertSeverity, navigate)}
                                        startIcon={<CheckCircleIcon />}>Accept Campaign
                                    </Button>
                                )}
                                {(campaign.status === 'Live' || campaign.status === 'Pending' || campaign.status === 'Draft') && loggedUser.role === 'ADMIN' && (
                                    <>
                                        <Button variant="contained" color="error"
                                            onClick={() => openDialogForCloseCampaign()}
                                            startIcon={<HighlightOffIcon />}>{campaign.status === 'Draft' ? 'Discard Campaign' :
                                                campaign.status === 'Pending' ? 'Cancel Campaign' : 'Close Campaign'}
                                        </Button>
                                        <Dialog
                                            open={openClosedCampaign}
                                            onClose={openDialogForCloseCampaign}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Warning"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    Are you sure want to {campaign.status === 'Draft' ? 'discard campaign' :
                                                        campaign.status === 'Pending' ? 'cancel campaign' : 'close campaign'}?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={openDialogForCloseCampaign}>Cancel</Button>
                                                <Button onClick={(e) => { closedCampaignAction(e); }} autoFocus>
                                                    {campaign.status === 'Draft' ? 'Discard Campaign' :
                                                        campaign.status === 'Pending' ? 'Cancel Campaign' : 'Close Campaign'}
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )}
                                {/* {campaign.status === 'Completed' && loggedUser.role === 'ADMIN' &&
                                                    <>

                                                        {campaign.invoiceStatus === 'Draft' ? (
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#7b5ede',
                                                                    '&:hover': {
                                                                        backgroundColor: '#5a32e4',
                                                                    },
                                                                }}
                                                                onClick={() => handleRaiseInvoice(campaign.status)}
                                                            >
                                                                Complete invoice
                                                            </Button>
                                                        ) : campaign.invoiceStatus === 'Completed' || campaign.invoiceStatus === 'Pending' ? (
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#7b5ede',
                                                                    '&:hover': {
                                                                        backgroundColor: '#5a32e4',
                                                                    },
                                                                }}
                                                                onClick={() => handleRaiseInvoice(campaign.invoiceId)}
                                                            >
                                                                View invoice
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                sx={{
                                                                    backgroundColor: '#7b5ede',
                                                                    '&:hover': {
                                                                        backgroundColor: '#5a32e4',
                                                                    },
                                                                }}
                                                                onClick={() => handleRaiseInvoice(campaign.status)}
                                                            >
                                                                Raise invoice
                                                            </Button>
                                                        )}

                                                    </>
                                                } */}

                            </div>
                            <div className="campaign-list-hr"></div>
                            <div className="campaign-overview">
                                <div className="campaign-overview-header">
                                    <h1>Campaign Overview</h1>
                                </div>
                                <div className="campaign-data">
                                    <div>Channel: <span>Instagram</span></div>
                                    <div className="option-row">
                                        <div>Campaign start: <span>{moment(campaign.lastDate).format("ll")}</span></div>
                                        <div>Campaign end: <span>{moment(campaign.lastDate).add(7, 'day').format("ll")}</span></div>
                                    </div>
                                    {pathname.includes('/all-campaigns') && loggedUser.role === 'ADMIN' && (
                                        <div>Created By: <span>{campaign.createdByDetails.includes(null) ? "Unknown" : campaign.createdByDetails}</span></div>
                                    )}
                                    {pathname.includes('/all-campaigns') && loggedUser.role === 'ADMIN' && (
                                        <div>Campaign Owner: <span>{campaign.campaignOwnerName}</span></div>
                                    )}
                                    {pathname.includes('/all-campaigns') && (loggedUser.role === 'ADMIN' || loggedUser.role === 'CREATOR') && (
                                        <div>Brand Handle: <span>@{campaign.brandHandle}</span></div>
                                    )}
                                    <div className="description-outer-container">
                                        <div className="adjust-alignment campaign-brief">Brief:</div>
                                        <div>
                                            <div className="description-content" dangerouslySetInnerHTML={{ __html: campaign.description }} />
                                        </div>
                                    </div>
                                    <div className="adjust-alignment">Hashtags:
                                        <div className="information-badge">i
                                            <div class="tooltip keywords-tooltip">
                                                We will track all the posts and stories with the hashtags you have specified for this campaign.
                                                If creators post includes at least one of the hashtags or other tracking indicators, it will appear in the campaign.
                                            </div>
                                        </div>
                                        <span>{campaign.specifyKeywords.join(', ')}</span>
                                    </div>
                                    <div>Project Type: <span>{campaign.projectType}</span></div>
                                    <div>Category Types: <span>
                                        {campaign.categoryTypes ? campaign.categoryTypes.join(', ') : ''}
                                        {(campaign.categoryTypes && campaign.customCategory && campaign.customCategory.some(item => item !== '')) ? ', ' : ''}
                                        {campaign.customCategory && campaign.customCategory.some(item => item !== '') ? campaign.customCategory.filter(item => item !== '').join(', ') : ''}
                                    </span></div>
                                    <div>Location: <span>{campaign.location ? campaign.location.map(cityState => `${cityState.city} - ${cityState.state}`).join(', ') : ''}</span></div>
                                    <div>Number of creators: <span>{campaign.creators}</span></div>
                                    <div className="option-row">
                                        <div>Production Available: <span>{campaign.productionAvailable}</span></div>
                                        <div>Travel Allowance: <span>{campaign.travelAllowance}</span></div>
                                    </div>
                                    <div className="option-row">
                                        <div>Stay Allowance: <span>{campaign.stayAllowance}</span></div>
                                        <div>Food Allowance: <span>{campaign.foodAllowance}</span></div>
                                    </div>
                                    <div>Remark: <span>{campaign.remark}</span></div>
                                    <div className="campaign-file-outer-container">
                                        <div className="campaign-files-label">Files:</div>
                                        <div className="campaign-file-inner-container">
                                            {campaign.attachments && campaign.attachments.reduce((rows, attachment, index) => {
                                                let filesPerRow;
                                                if (window.matchMedia("(max-width: 768px)").matches) {
                                                    filesPerRow = 2;
                                                } else {
                                                    filesPerRow = 4;
                                                }
                                                if (index % filesPerRow === 0) {
                                                    rows.push([]);
                                                }
                                                const [fileId, fileName] = attachment.split(' / ');
                                                rows[rows.length - 1].push({ fileId, fileName });
                                                return rows;
                                            }, []).map((row, rowIndex) => (
                                                <div key={rowIndex} className="campaign-file-row">
                                                    {row.map(({ fileId, fileName }, columnIndex) => (
                                                        <div key={columnIndex} className="campaign-file-name-container">
                                                            <div className="campaign-tooltip">
                                                                <div className="campaign-file-name" onClick={() => downloadFiles(fileId, fileName)}>
                                                                    {fileName.length > 25 ? (
                                                                        <span>{`${fileName.slice(0, 25)}...`}</span>
                                                                    ) : (
                                                                        fileName
                                                                    )}
                                                                </div>
                                                                {fileName.length > 25 && (
                                                                    <span className="campaign-tooltip-text">{fileName}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="alert-msg">
                                <CustomAlert open={alertOpen} severity={alertSeverity} message={alertMessage} onClose={() => setAlertOpen(false)} />
                            </div>
                        </div>
                    </div>
                </Box>
            )
            }
            <DialogBox userDetails={loggedUser} openAlert={openInstaHandleAlert} setOpenAlert={setOpenInstaHandleAlert} campaignId={campaignid} />
            <Dialog
                open={openWithdrawConfirmationDialog}
                onClose={() => setOpenWithdrawConfirmationDialog(false)}
                aria-labelledby="withdraw-confirmation-dialog-title"
                aria-describedby="withdraw-confirmation-dialog-description"
            >
                <DialogTitle id="withdraw-confirmation-dialog-title" sx={{ color: '#e326a4' }}>Withdraw Application</DialogTitle>
                <DialogContent>
                    <DialogContentText id="withdraw-confirmation-dialog-description" sx={{ color: '#000000' }}>
                        Do you really want to withdraw your application?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenWithdrawConfirmationDialog(false)}>No</Button>
                    <Button onClick={(e) => {
                        handleApplyCampaign(e, campaign.campaignId, loggedUser.isInstaAccountPrivate, loggedUser.userId, setApply, setOpenInstaHandleAlert, setAlertOpen, setAlertMessage, setAlertSeverity);
                        setOpenWithdrawConfirmationDialog(false);
                    }}>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CampaignDetails;
