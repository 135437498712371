import { getConfig } from '../common/env-config';
import { refreshToken } from "../common/refresh-token-repo";

const { baseUrl } = getConfig();

export const getLeads = async (createData, setLoading, currentPage, rowsPerPage, setTotalLeads, setLeads, setApiFailed, campaignFilterValue, dateFilterValue, statusFilterValue, tagsFilterValue, searchString,
    setNewLeadCount, setnotifyCount, setinDiscussionCount, setrejectedCount, setregisteredCount
) => {
    setLoading(true);
    let filters = {
        pageNo: currentPage,
        pageSize: rowsPerPage
    };
    if (campaignFilterValue != null) {
        filters.campaignName = campaignFilterValue;
    }
    if (dateFilterValue != null) {
        filters.dateRange = dateFilterValue;
    }
    if (statusFilterValue != null) {
        filters.status = statusFilterValue;
    }
    if (tagsFilterValue != null) {
        filters.tag = tagsFilterValue;
    }
    if (searchString != null && searchString !== "") {
        filters.searchString = searchString;
    }
    const response = await fetch(`${baseUrl}/admin/get-leads`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify(filters),
    });
    const data = await response.json();
    if (response.ok) {
        const leadsResponse = {
            totalLeads: data.totalLeads,
            newLeadsCount: data.newLeadsCount,
            notifyLeadsCount: data.notifyLeadsCount,
            inDiscussionLeadsCount: data.inDiscussionLeadsCount,
            rejectedCount: data.rejectedCount,
            registeredCount: data.registeredCount,
            leads: data.leads.map((item) => createData(item.id, item.fullName, item.phoneNumber, item.email, item.createdTime, item.status, item.tagList))
        }
        setTotalLeads(leadsResponse.totalLeads);
        setNewLeadCount(leadsResponse.newLeadsCount);
        setnotifyCount(leadsResponse.notifyLeadsCount);
        setinDiscussionCount(leadsResponse.inDiscussionLeadsCount);
        setrejectedCount(leadsResponse.rejectedCount);
        setregisteredCount(leadsResponse.registeredCount);
        setLeads(leadsResponse.leads);
        setApiFailed(leadsResponse.leads.length === 0);
    } else if (response.status === 499) {
        refreshToken();
    } else {
        setApiFailed(true);
    }
    setLoading(false);
}

export const getLeadsCampaigns = async (setLeadsCampaigns) => {
    const response = await fetch(`${baseUrl}/admin/get-leads-campaigns`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    const data = await response.json();
    if (response.ok) {
        setLeadsCampaigns(data);
    } else if (response.status === 499) {
        refreshToken();
    }
}

export const getLeadsStatus = async (setLeadsStatus) => {
    const response = await fetch(`${baseUrl}/admin/get-leads-status`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    const data = await response.json();
    if (response.ok) {
        setLeadsStatus(data);
    } else if (response.status === 499) {
        refreshToken();
    }
}

export const getLeadsTags = async (setLeadsTags) => {
    const response = await fetch(`${baseUrl}/admin/get-leads-tags?pageNo=${1}&pageSize=${1000}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    const data = await response.json();
    if (response.ok) {
        setLeadsTags(data.tags.map(item => item.tag));
    } else if (response.status === 499) {
        refreshToken();
    }
}

export const updateLeadStatus = async (statusValue, phoneNumber, setIsStatusEditing, setUpdateSuccess, setStatusSuccessAlert, setStatusFailedAlert, handleStatusChangeClose, setSelected) => {
    const response = await fetch(`${baseUrl}/admin/update-leads-status?leads=${phoneNumber}&status=${statusValue}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    if (response.ok) {
        setUpdateSuccess(Math.random().toString(36).substring(7));
        setStatusSuccessAlert(true);
    } else if (response.status === 499) {
        refreshToken();
    } else {
        setStatusFailedAlert(true);
    }
    if (setIsStatusEditing != null) {
        setIsStatusEditing(false);
    }
    if (handleStatusChangeClose != null) {
        handleStatusChangeClose();
    }
    setSelected([]);
}

export const updateLeadTag = async (tagValue, phoneNumbers, setIsTagEditing, setTagUpdated, setTagSuccessAlert, setTagFailedAlert, handleBulkUpdateClose, setSelected) => {
    let body = {
        tags: tagValue,
        leads: Array.isArray(phoneNumbers) ? phoneNumbers : [phoneNumbers]
    }
    const response = await fetch(`${baseUrl}/admin/add-lead-tag`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
    });
    if (response.ok) {
        setTagUpdated(Math.random().toString(36).substring(7));
        if (setTagSuccessAlert != null) {
            setTagSuccessAlert(true);
        }
    } else if (response.status === 499) {
        refreshToken();
    } else {
        if (setTagFailedAlert != null) {
            setTagFailedAlert(true);
        }
    }
    setIsTagEditing(false);
    setSelected([]);
    if (handleBulkUpdateClose != null) {
        handleBulkUpdateClose();
    }
}

export const exportLeads = async (campaignFilterValue, dateFilterValue, statusFilterValue, tagsFilterValue, searchString) => {
    let filters = {
    };
    if (campaignFilterValue != null) {
        filters.campaignName = campaignFilterValue;
    }
    if (dateFilterValue != null) {
        filters.dateRange = dateFilterValue;
    }
    if (statusFilterValue != null) {
        filters.status = statusFilterValue;
    }
    if (tagsFilterValue != null) {
        filters.tag = tagsFilterValue;
    }
    if (searchString != null && searchString !== "") {
        filters.searchString = searchString;
    }
    const response = await fetch(`${baseUrl}/admin/export-leads`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        },
        body: JSON.stringify(filters),
    });
    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', "leads.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } else if (response.status === 499) {
        refreshToken();
    }
};

export const editTag = async (tagValue, oldTagValue, tagId, setTagUpdated, setIsEditTag, setUpdatedTag) => {
    const response = await fetch(`${baseUrl}/admin/edit-leads-tag?tagId=${tagId}&tagValue=${tagValue != null ? tagValue : oldTagValue}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    if (response.ok) {
        setTagUpdated(Math.random().toString(36).substring(7));
    } else if (response.status === 499) {
        refreshToken();
    }
    setIsEditTag(false);
    setUpdatedTag(null);
}

export const deleteTag = async (tagId, setTagDeleted, setIsTagDelete) => {
    const response = await fetch(`${baseUrl}/admin/delete-leads-tag?tagId=${tagId}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    if (response.ok) {
        setTagDeleted(Math.random().toString(36).substring(7));
    } else if (response.status === 499) {
        refreshToken();
    }
    setIsTagDelete(false);
}

export const getTags = async (setTags, setApiFailed, setLoading, setTotalTags, currentPage, rowsPerPage) => {
    setLoading(true);
    const response = await fetch(`${baseUrl}/admin/get-leads-tags?pageNo=${currentPage}&pageSize=${rowsPerPage}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    const data = await response.json();
    if (response.ok) {
        const tagsResponse = {
            totalTags: data.totalTags,
            tags: data.tags.map((item) => ({
                id: item.id,
                tag: item.tag
            }))
        };
        setTags(tagsResponse.tags);
        setTotalTags(tagsResponse.totalTags);
        setApiFailed(tagsResponse.tags.length === 0);
    } else if (response.status === 499) {
        refreshToken();
    } else {
        setApiFailed(true);
    }
    setLoading(false);
}