import React, { useContext, useEffect, useState } from "react";
import { Button, FormControlLabel, Tooltip } from "@mui/material";
import CreatorsList from "../home/creators-list";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { exportCampaignApplication, fetchCampaignApplication, getAcceptedApplicationCount } from './campaign-repository';
import { WindowWidthContext } from "../context/user-details-context";
import { useLocation, useNavigate } from "react-router-dom";
import { IOSSwitch } from "../../mui/mui-styling";

export default function CampaignApplication() {
    const location = useLocation();
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const { campaignId, campaignName, noOfCreators, campaignLastDate, page } = location.state;
    const windowWidth = useContext(WindowWidthContext);
    const [creators, setCreators] = useState([]);
    const [apiFailed, setApiFailed] = useState(false);
    const [totalResults, setTotalResults] = useState();
    const [loading, setLoading] = useState(false);
    const [currentApplicationsPage, setCurrentApplicationsPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [accept, setAccept] = useState();
    const [acceptedApplication, setAcceptedApplication] = useState(false);
    const [rejectedApplication, setRejectedApplication] = useState(false);
    const [fitStatus, setFitStatus] = useState(false);
    const [maybeStatus, setMaybeStatus] = useState(false);
    const [notFitStatus, setNotFitStatus] = useState(false);
    const [acceptApplicationCount, setAcceptApplicationCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedApplicants, setSelectedApplicants] = useState([]);
    const [followersFilterValue, setFollowersFilterValue] = useState({ min: '', max: '' });
    const [engagement, setEngagement] = useState(false);

    useEffect(() => {
        getAcceptedApplicationCount(campaignId, setAcceptApplicationCount);
        fetchCampaignApplication(setLoading, campaignId, currentApplicationsPage, rowsPerPage,
            setTotalResults, setApiFailed, setTotalPages, setCreators, acceptedApplication, rejectedApplication, fitStatus, maybeStatus, notFitStatus, followersFilterValue, engagement);
    }, [currentApplicationsPage, campaignId, accept, acceptedApplication, rejectedApplication, rowsPerPage, fitStatus, maybeStatus, notFitStatus, followersFilterValue, engagement]);

    const handleArrowBack = () => {
        const pathSegments = pathname.split('/');
        const startPath = '/' + pathSegments[1];
        navigate(`${startPath}/${page === 'details' ? 'details' : 'live'}`, { state: { campaignid: campaignId } });
    }
    return (
        <>
            <div className="campaign-application-head">
                <ArrowBackIcon className="arrow-back" sx={{ color: "#9c27b0" }} onClick={() => handleArrowBack()}></ArrowBackIcon>
                <h1 className="campaign-appllication-header-name">Applicants</h1>
            </div>
            <div className={windowWidth <= 799 ? "campaign-application campaign-application-mobile" : "campaign-application"}>
                <div className={windowWidth <= 799 ? "campaign-name-div-mobile" : "campaign-name-div"}>
                    <h1 className="campaign-name-text">Campaign Name: <Tooltip title={campaignName}><span style={{ color: '#f48fb1' }}>{campaignName}</span></Tooltip></h1>
                </div>
                <div className={windowWidth <= 799 ? "campaign-application-head-actions-mobile" : "campaign-application-head-actions"}>
                    <div className="no-of-creator-text">Number of selected creators: <span>{acceptApplicationCount} / {noOfCreators}</span></div>
                    <div className="export-application-mobile">
                        <Tooltip title={acceptedApplication ? "" : "Accepted Applications"}>
                            <FormControlLabel
                                control={<IOSSwitch sx={{ m: 1 }} checked={acceptedApplication} onClick={() => { setAcceptedApplication(!acceptedApplication); setSelectedApplicants([]); }} />}
                                label={windowWidth <= 799 && "Accepted Applications"}
                            />
                        </Tooltip>
                        <Button variant="contained" disabled={apiFailed || loading} sx={{
                            backgroundColor: '#e563a4', '&:hover': {
                                backgroundColor: '#e326a4'
                            }
                        }} onClick={() => { exportCampaignApplication(campaignId, acceptedApplication, fitStatus, maybeStatus, notFitStatus) }} startIcon={<FileDownloadIcon />}>Export</Button>
                    </div>
                </div>
            </div>
            <CreatorsList acceptedApplication={acceptedApplication} setAcceptedApplication={setAcceptedApplication} rejectedApplication={rejectedApplication} setRejectedApplication={setRejectedApplication}
                fitStatus={fitStatus} setFitStatus={setFitStatus} maybeStatus={maybeStatus} setMaybeStatus={setMaybeStatus} notFitStatus={notFitStatus} setNotFitStatus={setNotFitStatus}
                selectedApplicants={selectedApplicants} setSelectedApplicants={setSelectedApplicants}
                rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} setAccept={setAccept} applications={true} totalPages={totalPages} loading={loading} totalResults={totalResults} apiFailed={apiFailed}
                creators={creators} currentPage={currentApplicationsPage} setCurrentPage={setCurrentApplicationsPage} campaignLastDate={campaignLastDate} noOfCreators={noOfCreators} acceptApplicationCount={acceptApplicationCount}
                setFollowersFilterValue={setFollowersFilterValue} engagement={engagement} setEngagement={setEngagement}/>
        </>
    )
}
