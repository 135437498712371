import { Autocomplete, Button, Checkbox, Chip, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const renderCityFilterContent = (uniqueCities, handleCityInputChange, inputCityValue, setSelectedCities, handleCityInputKeyPress, selectedCities) => {
  const handleApplyFilterClick = () => {
    handleCityInputKeyPress({ key: 'Enter' });
  };
  return (
    <>
      <Autocomplete
        freeSolo
        multiple
        limitTags={2}
        options={uniqueCities}
        onInputChange={handleCityInputChange}
        inputValue={inputCityValue}
        getOptionLabel={(option) => option || []}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {/* <Checkbox
              style={{ marginRight: 8 }}
              checked={selected}
            /> */}
            {option}

          </li>
        )}
        onChange={(event, newValue) => {
          setSelectedCities(newValue);
        }}
        onKeyPress={handleCityInputKeyPress}
        style={{ width: 378 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="City"
            InputProps={{
              ...params.InputProps,
              style: { width: '100%' }
            }}
          />
        )}
        value={selectedCities}
        renderTags={(value, getTagProps) => (
          <>
            {value.slice(0, 2).map((option, index) => (
              <Chip
                key={index}
                title={option}
                label={option}
                {...getTagProps({ index })}
              />
            ))}
            {value.length > 2 && (
              <Chip
                label={`${value.length - 2}+`}
                {...getTagProps({ index: value.length - 1 })}
              />
            )}
          </>
        )}
      />
      <div className='followers-range-footer'>
        <Button disabled={selectedCities.length === 0} onClick={handleApplyFilterClick}>Apply Filter</Button>
      </div>
    </>
  );
};

export const renderStateFilterContent = (uniqueStates, handleStateInputChange, inputStateValue, setSelectedStates, handleStateInputKeyPress, selectedStates) => {
  return (
    <>
      <Autocomplete
        freeSolo
        multiple
        limitTags={2}
        options={uniqueStates}
        disableCloseOnSelect
        onInputChange={handleStateInputChange}
        inputValue={inputStateValue}
        getOptionLabel={(option) => option || []}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}

          </li>
        )}
        onChange={(event, newValue) => {
          setSelectedStates(newValue);
        }}
        onKeyPress={handleStateInputKeyPress}
        style={{ width: 378 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="State"
            InputProps={{
              ...params.InputProps,
              style: { width: '100%' }
            }}
          />
        )}
        value={selectedStates}
        renderTags={(value, getTagProps) => (
          <>
            {value.slice(0, 2).map((option, index) => (
              <Chip
                key={index}
                title={option}
                label={option}
                {...getTagProps({ index })}
              />
            ))}
            {value.length > 2 && (
              <Chip
                label={`${value.length - 2}+`}
                {...getTagProps({ index: value.length - 1 })}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export const renderInterestFilterContent = (categories, selectedCategories, handleCategoryChange, handleCategoryInputChange, inputValue, handleCategoryInputKeyPress) => {
  const handleApplyFilterClick = () => {
    handleCategoryInputKeyPress({ key: 'Enter' });
  };
  return (
    <>
      <Autocomplete
        freeSolo
        multiple
        limitTags={2}
        id="categories"
        options={categories}
        value={selectedCategories}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        onChange={handleCategoryChange}
        onInputChange={handleCategoryInputChange}
        inputValue={inputValue}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        onKeyPress={handleCategoryInputKeyPress}
        style={{ width: 378 }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Categories"
            InputProps={{
              ...params.InputProps,
              style: { width: '100%' }
            }}
          />
        )}
        renderTags={(value, getTagProps) => (
          <>
            {value.slice(0, 2).map((option, index) => (
              <Chip
                key={index}
                title={option}
                label={option}
                {...getTagProps({ index })}
              />
            ))}
            {value.length > 2 && (
              <Chip
                label={`${value.length - 2}+`}
                {...getTagProps({ index: value.length - 1 })}
              />
            )}
          </>
        )}
      />
      <div className='followers-range-footer'>
        <Button disabled={selectedCategories.length === 0} onClick={handleApplyFilterClick}>Apply Filter</Button>
      </div>
    </>
  );
};
