import React, { useContext, useEffect, useState } from "react";
import "../../css/campaign.css";
import { Box, Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { getCampaignsList } from "./campaign-repository";
import { useLocation, useNavigate } from "react-router-dom";
import { UserDetailsContext } from "../context/user-details-context";
import CommonPagination from "../common/common-pagination";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import { Add } from "@mui/icons-material";
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import VisibilityIcon from '@mui/icons-material/Visibility';

function CampaignPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedUser } = useContext(UserDetailsContext);
  const pathname = window.location.pathname;
  const pathSegments = pathname.split('/');
  const startPath = '/' + pathSegments[1];
  const endPath = pathSegments[2].charAt(0).toUpperCase() + pathSegments[2].slice(1).toLowerCase();
  const [campaignList, setCampaignList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const date = new Date();
  const currentDate = date.toISOString().slice(0, 10);

  useEffect(() => {
    setIsLoading(true);
    getCampaignsList(pathname, setCampaignList, setIsLoading, currentPage, recordsPerPage);
  }, [pathname, currentPage, recordsPerPage]);

  const handleCampaignClick = (campaign) => {
    navigate(`${startPath}/details`, { state: { campaignid: campaign.campaignId } });
  };

  const handleDraftCampaignClick = (campaign) => {
    navigate(`${startPath}/create`, { state: { campaign, fromPage: 'list' } });
  };

  const handleActiveTab = (tab) => {
    setCurrentPage(1);
    const newPath = `${location.pathname.replace(/\/[^/]+\/?$/, '')}/${tab.toLowerCase()}`;
    navigate(newPath);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCampaignApplication = (campaignId, campaignName, noOfCreators, campaignLastDate) => {
    const pathSegments = pathname.split('/');
    const startPath = '/' + pathSegments[1];
    navigate(`${startPath}/applications`, { state: { campaignId, campaignName, noOfCreators, campaignLastDate } });
  }

  return (
    <>
      {!loggedUser ?
        <>
        </>
        : <div className="campaign-page">
          <h1>Campaigns</h1>
          <div className="campaign-list-hr"></div>
          {isLoading || campaignList.length === 0 || !loggedUser ? (
            <div className="data-loading">
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            </div>
          ) : (
            <>
              <div className="campaign-list-options">
                <div className="campaign-status">
                  <div
                    className={"middle-tabs " + (pathname.includes('live') ? "active-tab" : "tab")}
                    onClick={() => handleActiveTab('Live')}
                  >
                    Live ({campaignList.statusCounts["Live"] || 0})
                  </div>
                  {(loggedUser.role === "ADMIN" || (loggedUser.role === "ENTERPRISE_CLIENT" && pathname.includes('/my-campaigns'))) &&
                    <div
                      className={"middle-tabs " + (pathname.includes('pending') ? "active-tab" : "tab")}
                      onClick={() => handleActiveTab('Pending')}
                    >
                      Pending ({campaignList.statusCounts["Pending"] || 0})
                    </div>
                  }
                  {(loggedUser.role === "ADMIN" || (loggedUser.role === "ENTERPRISE_CLIENT" && pathname.includes('/my-campaigns'))) && (
                    <div
                      className={"middle-tabs " + (pathname.includes('draft') ? "active-tab" : "tab")}
                      onClick={() => handleActiveTab('Draft')}
                    >
                      Draft ({campaignList.statusCounts["Draft"] || 0})
                    </div>
                  )}
                  <div
                    className={"middle-tabs " + (pathname.includes('completed') ? "active-tab" : "tab")}
                    onClick={() => handleActiveTab('Completed')}
                  >
                    Completed ({campaignList.statusCounts["Completed"] || 0})
                  </div>
                </div>
                {(loggedUser.role === 'ENTERPRISE_CLIENT' || loggedUser.role === 'ADMIN') &&
                  <Button className="new-campaign" onClick={() => navigate(`${startPath}/create`)}>
                    <Add sx={{ marginRight: 1 }} />
                    New campaign
                  </Button>
                }
              </div><div className="campaign-list-hr"></div>
              <div className="search-box"></div>
              <>
                <div className="campaign-list-holder">
                  {campaignList.campaigns.map((campaign, index) => (
                    <div
                      className="campaign-list-box"
                      key={index}
                    >
                      <div className="campaigns-list">
                        <div className="list-header-content">
                          <div className="list-title" onClick={() =>
                            (pathname.includes('draft') && pathname.includes('/all-campaigns')) ||
                              campaign.status === "Live" || campaign.status === "Completed"
                              || (loggedUser.role === 'ADMIN' && campaign.status === "Pending") ? handleCampaignClick(campaign) : handleDraftCampaignClick(campaign)}>
                            <div className="campaign-name">{campaign.campaignName}</div>
                            <div className="campaign-list-row">
                              <div className="campaign-start-date first-element">
                                <label>Campaign start: </label>{moment(campaign.lastDate).format("ll")}
                              </div>
                              <div>
                                <label>Campaign End: </label>{moment(campaign.lastDate).add(7, 'day').format("ll")}
                              </div>
                            </div>
                            <div className="campaign-list-row">
                              <div className="first-element"><label>Project Type: </label><span>{campaign.projectType}</span></div>
                              <div><label>Category Types: </label><span>
                                {campaign.categoryTypes && campaign.categoryTypes.some(item => item !== '') ? campaign.categoryTypes.join(', ') : ''}
                                {campaign.categoryTypes && campaign.categoryTypes.some(item => item !== '') && campaign.customCategory && campaign.customCategory.some(item => item !== '') ? ', ' : ''}
                                {campaign.customCategory && campaign.customCategory.some(item => item !== '') ? campaign.customCategory.filter(item => item !== '').join(', ') : ''}
                              </span></div>
                            </div>
                          </div>
                          <div className="campaign-list-button">
                            {(pathname.includes('live') || pathname.includes('completed')) &&
                              <>
                                <div className="view-campaign-row">
                                  <Badge onClick={() => {
                                    if ((campaign.applications > 0 && loggedUser.role === 'ADMIN') ||
                                      (campaign.applications > 0 && loggedUser.role === 'ENTERPRISE_CLIENT' && campaign.userId === loggedUser.userId)
                                    ) {
                                      handleCampaignApplication(campaign.campaignId, campaign.campaignName, campaign.creators, campaign.lastDate);
                                    }
                                  }}
                                    sx={{ cursor: 'pointer' }} color="secondary" badgeContent={campaign.applications} showZero>
                                    <MailIcon />
                                  </Badge>
                                  <Tooltip title="View Campaign" arrow>
                                    <IconButton onClick={(e) => { handleCampaignClick(campaign) }}>
                                      <VisibilityIcon sx={{ color: '#9c27b0', fontSize: '1.9rem' }} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div>
                                  {campaign.userId !== loggedUser.userId && (pathname === '/all-campaigns/live') && loggedUser.role === 'CREATOR' && campaign.lastDate >= currentDate && (
                                    <Button variant="contained" sx={{
                                      backgroundColor: '#e563a4', '&:hover': {
                                        backgroundColor: '#e326a4'
                                      }
                                    }} onClick={(e) => { handleCampaignClick(campaign) }}
                                      startIcon={campaign.applicationStatus === null || campaign.applicationStatus !== 'Apply' ? <TouchAppIcon /> : <MoveToInboxIcon />}>
                                      {campaign.applicationStatus != null ? campaign.applicationStatus === 'Apply' ? 'Withdraw' : 'Apply' : 'Apply'}
                                    </Button>
                                  )
                                  }
                                </div>
                              </>
                            }
                            {/* <Tooltip title="View Campaign" arrow>
                              <IconButton onClick={(e) => { handleCampaignClick(campaign) }}>
                                <VisibilityIcon sx={{ color: '#9c27b0', fontSize: '1.9rem' }} />
                              </IconButton>
                            </Tooltip> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {campaignList.statusCounts[endPath] > 5 && (
                  <CommonPagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(campaignList.statusCounts[endPath] / recordsPerPage)}
                    handlePageChange={handlePageChange}
                  />
                )}
              </>
            </>
          )}
        </div>
      }
    </>
  );
}

export default CampaignPage;
