import React, { useContext, useState } from "react";
import {
  Button, CircularProgress, ThemeProvider, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, IconButton, Menu, ListItemIcon, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery, useTheme, Box, Select, FormControl, Paper, TableContainer,
  FormControlLabel, Popover, Typography, TextField
} from "@mui/material";
import ProfileDialog from "./profile-popup";
import CommonPagination from "../common/common-pagination";
import { getConfig } from "../common/env-config";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { acceptCampaignApplication } from "../campaign/campaign-repository";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import { creatorsTableTheme } from "../../mui/mui-theme-component";
import CustomAlert from "../common/alert";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { WindowWidthContext } from "../context/user-details-context";
import Checkbox from '@mui/material/Checkbox';
import ApplicantsTableToolbar from "./applicants-table-toolbar";

export default function CreatorsList({ acceptedApplication, setAcceptedApplication, rejectedApplication, setRejectedApplication,
  fitStatus, setFitStatus, maybeStatus, setMaybeStatus, notFitStatus, setNotFitStatus, selectedApplicants, setSelectedApplicants,
  rowsPerPage, setRowsPerPage, fromPage, setAccept, applications, totalPages, loading, totalResults,
  apiFailed, creators, setInitialLoad, currentPage, setCurrentPage, campaignLastDate, noOfCreators, acceptApplicationCount, setFollowersFilterValue, engagement, setEngagement
}) {
  const windowWidth = useContext(WindowWidthContext);
  const { baseUrl } = getConfig();
  const [username, setUsername] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [anchorStatusElAction, setAnchorStatusElAction] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const date = new Date();
  const currentDate = date.toISOString().slice(0, 10);
  const [openApplicationAlert, setOpenApplicationAlert] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const applicantStatus = ['Fit', 'Maybe', 'Not Fit'];
  const [anchorEl, setAnchorEl] = useState(null);
  const [followersFrom, setFollowersFrom] = useState('');
  const [followersTo, setFollowersTo] = useState('');
  const [followersFilterApply, setFollowersFilterApply] = useState(false);

  const handleRowSelect = (event, creator) => {
    const selectedIndex = selectedApplicants.findIndex(row => row.userId === creator.userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedApplicants, { userId: creator.userId, campaignId: creator.campaignId, applicationStatus: creator.applicationStatus });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedApplicants.slice(1));
    } else if (selectedIndex === selectedApplicants.length - 1) {
      newSelected = newSelected.concat(selectedApplicants.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedApplicants.slice(0, selectedIndex),
        selectedApplicants.slice(selectedIndex + 1),
      );
    }

    setSelectedApplicants(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = creators.map((creator) => ({
        userId: creator.userId,
        campaignId: creator.campaignId,
        applicationStatus: creator.applicationStatus,
      }));
      setSelectedApplicants(newSelecteds);
    } else {
      setSelectedApplicants([]);
    }
  };

  const handleClickOpen = () => {
    setOpenApplicationAlert(true);
  };

  const handleApplicationAlertClose = () => {
    setOpenApplicationAlert(false);
  };

  const handleActionClose = () => {
    setAnchorElAction(null);
    setOpenFilter(false);
  };

  const handleStatusActionClose = () => {
    setAnchorStatusElAction(null);
    setOpenStatusFilter(false);
  };

  const handleProfileOpen = (username) => {
    setUsername(username);
    setOpenProfile(true);
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

  const showAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "K";
    } else {
      const roundedNumber = Math.round(num * 100) / 100;
      if (Number.isInteger(roundedNumber)) {
        return roundedNumber.toString();
      } else {
        return roundedNumber.toFixed(2);
      }
    }
  };

  const handlePageChange = (event, value) => {
    if (setInitialLoad !== undefined) {
      setInitialLoad(true);
    }
    setCurrentPage(value);
    if (setSelectedApplicants) {
      setSelectedApplicants([]);
    }
  };

  const handleAcceptApplication = async (e, campaignId, userId, status) => {
    if (acceptApplicationCount >= noOfCreators && status === 'Accepted') {
      handleClickOpen();
    } else {
      try {
        await acceptCampaignApplication(campaignId, userId, status, setAccept);
        showAlert("success", `Application ${status} successfully.`);
      } catch (error) {
        showAlert("error", `Failed to ${status} application. Please try again.`);
      }
    }
  };

  const creatorColumns = [
    {
      id: 1,
      label: `Showing ${apiFailed || loading ? 0 : ((currentPage - 1) * rowsPerPage) + 1} - ${apiFailed || loading ? 0 : Math.min(currentPage * rowsPerPage, totalResults)} of ${apiFailed || loading ? 0 : totalResults} ${!applications ? "profiles" : "applicants"
        }`,
      minWidth: 260,
    },
    { id: 2, label: "Followers", minWidth: 120, filter: true },
    {
      id: 3,
      label: "Engagement",
      tooltip:
        "Engagement indicates the average number of engagements on each post.",
      minWidth: 130,
      filter: true
    },
    { id: 5, label: "Creator Score", minWidth: 140 },
    { id: 6, label: "Price", minWidth: 140 },
  ];
  if (applications) {
    creatorColumns.push({ id: 7, label: "Action", minWidth: 140, filter: true });
    creatorColumns.push({ id: 8, label: "Rate", minWidth: 140, filter: true });
  }

  const handleRowPerPageChange = (value) => {
    setRowsPerPage(value);
    setCurrentPage(1);
  }

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFollowersFilterChange = () => {
    const min = parseInt(followersFrom, 10);
    const max = parseInt(followersTo, 10);
    setFollowersFilterValue({ min, max });
    setAnchorEl(null);
    setFollowersFilterApply(true);
    setSelectedApplicants([]);
    setEngagement(true);
    setCurrentPage(1);
  };

  const handleClearFilter = () => {
    setFollowersFilterValue({ min: '', max: '' });
    setFollowersFrom('');
    setFollowersTo('');
    setFollowersFilterApply(false);
    setEngagement(false);
    setCurrentPage(1);
  };

  const openFollowersFilter = Boolean(anchorEl);
  const id = openFollowersFilter ? 'simple-popover' : undefined;

  const handleFollowersFromInputChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && value.length <= 10)) {
      setFollowersFrom(value);
    }
  };

  const handleFollowersToInputChange = (e) => {
    const value = e.target.value;
    if (value === '' || (/^\d+$/.test(value) && value.length <= 10)) {
      setFollowersTo(value);
    }
  };

  return (
    <>
      <ThemeProvider theme={creatorsTableTheme}>
        <Box sx={{ width: windowWidth <= 799 ? '100vw' : '100%' }}>
          <Paper sx={{ width: windowWidth <= 799 ? '100vw' : '100%', mb: 2, backgroundColor: !fromPage ? '#fff' : '#f4f5f8', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <TableContainer sx={{ overflowX: 'auto', flexGrow: 1, scrollbarWidth: 'none', '-ms-overflow-style': 'none' }}>
              {selectedApplicants && selectedApplicants.length > 0 && (
                <ApplicantsTableToolbar selectedApplicants={selectedApplicants} />
              )}
              <Table aria-labelledby="tableTitle" className={`influencers-inner-container ${fromPage ? "creators-list-container" : ""
                }`}>
                <TableHead>
                  {fromPage ? (
                    <>
                      <TableRow sx={{ height: "3em" }}></TableRow>
                      <TableRow sx={{ height: "3em" }}>
                        <label className="creators-list-label">Creators List</label>
                      </TableRow>
                    </>
                  ) : null}
                  <TableRow>
                    {applications && campaignLastDate >= currentDate && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="secondary"
                          indeterminate={selectedApplicants.length > 0 && selectedApplicants.length < creators.length}
                          checked={creators.length > 0 && selectedApplicants.length === creators.length}
                          onChange={handleSelectAllClick}
                          inputProps={{ 'aria-label': 'select all creators' }}
                        />
                      </TableCell>
                    )}
                    {creatorColumns.map((column) => (
                      <TableCell
                        sx={{
                          backgroundColor: "transparent",
                          fontWeight: 600,
                          color: "#9a9898",
                        }}
                        key={column.id}
                        style={{
                          minWidth: !applications ? column.minWidth : "auto",
                        }}
                      >
                        <div className="top-campaign-tooltip">
                          {column.label}
                          {column.tooltip && !applications && (
                            <Tooltip title={column.tooltip}>
                              <InfoOutlinedIcon sx={{ m: 1 }} />
                            </Tooltip>
                          )}
                          {column.filter && column.id === 2 && applications && (
                            <>
                              <Tooltip title="Filter by followers">
                                <IconButton onClick={followersFilterApply ? handleClearFilter : handleFilterClick}>
                                  {followersFilterApply ? <FilterAltOffIcon /> : <FilterAltIcon aria-describedby={id} />}
                                </IconButton>
                              </Tooltip>
                              <Popover id={id} open={openFollowersFilter}
                                anchorEl={anchorEl} onClose={handleFilterClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                              >
                                <Typography sx={{ p: 3 }}>
                                  <div className='date-range-div'>
                                    <div className='date-range-col'>
                                      <div className='date-range-text'>Followers From:</div>
                                      <TextField value={followersFrom} placeholder='Followers From' variant="outlined" type='number'
                                        inputProps={{ min: "0", max: "1000000000", pattern: "[0-9]*" }}
                                        onChange={handleFollowersFromInputChange}
                                      />
                                    </div>
                                    <div className='date-range-col'>
                                      <div className='date-range-text'>Followers To:</div>
                                      <TextField value={followersTo} placeholder="Followers To" variant="outlined" type='number'
                                        inputProps={{ min: "0", max: "1000000000", pattern: "[0-9]*" }}
                                        onChange={handleFollowersToInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className='date-range-footer'>
                                    <Button disabled={followersFrom === '' || followersTo === ''} onClick={handleFollowersFilterChange}>
                                      Apply Filter
                                    </Button>
                                  </div>
                                </Typography>
                              </Popover>
                            </>
                          )}
                          {column.filter && column.id === 3 && applications && (
                            <>
                              <Tooltip title="Sort by engagement">
                                <IconButton onClick={() => {
                                  if (engagement) {
                                    setEngagement(false);
                                    setSelectedApplicants([]);
                                  } else {
                                    setEngagement(true);
                                    setSelectedApplicants([]);
                                  }
                                }}>
                                  {engagement ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {column.filter && column.id === 7 && (
                            <Tooltip title="Filter by Accepted and Rejected Applicants">
                              <IconButton>
                                {!acceptedApplication && !rejectedApplication ? (
                                  <FilterAltIcon
                                    onClick={(event) => {
                                      setOpenFilter(true);
                                      setAnchorElAction(event.currentTarget);
                                    }}
                                  />
                                ) : (
                                  <FilterAltOffIcon onClick={() => { setAcceptedApplication(false); setRejectedApplication(false); setAcceptedApplication(false) }} />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}
                          {column.filter && column.id === 8 && (
                            <Tooltip title="Filter by status (Fit/Not Fit/Maybe)">
                              <IconButton onClick={(event) => {
                                setOpenStatusFilter(true);
                                setAnchorStatusElAction(event.currentTarget);
                              }}>
                                {!fitStatus && !notFitStatus && !maybeStatus ?
                                  <FilterAltIcon /> : <FilterAltOffIcon />
                                }
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                        {column.id === 7 && (
                          <Menu
                            id="action-menu"
                            anchorEl={anchorElAction}
                            open={openFilter}
                            onClose={handleActionClose}
                            MenuListProps={{
                              "aria-labelledby": "action-button",
                            }}
                          >
                            <MenuItem onClick={() => { setAcceptedApplication(true); setRejectedApplication(false); handleActionClose(); setCurrentPage(1); setSelectedApplicants([]); setAcceptedApplication(true); }}>
                              <ListItemIcon>
                                <AssignmentTurnedInIcon style={{ color: "#f48fb1" }} fontSize="small" />
                              </ListItemIcon>
                              <span style={{ color: '#9c27b0' }}>Accepted Applications</span>
                            </MenuItem>
                            <MenuItem onClick={() => { setRejectedApplication(true); setAcceptedApplication(false); handleActionClose(); setCurrentPage(1); setSelectedApplicants([]); setAcceptedApplication(false); }}>
                              <ListItemIcon>
                                <DisabledByDefaultIcon style={{ color: "#f48fb1" }} fontSize="small" />
                              </ListItemIcon>
                              <span style={{ color: '#9c27b0' }}>Rejected Applications</span>
                            </MenuItem>
                          </Menu>
                        )}
                        {column.id === 8 && (
                          <Menu
                            id="action-menu"
                            anchorEl={anchorStatusElAction}
                            open={openStatusFilter}
                            onClose={handleStatusActionClose}
                            MenuListProps={{
                              'aria-labelledby': 'action-button',
                            }}
                          >
                            <MenuItem>
                              <ListItemIcon>
                                <FormControlLabel
                                  sx={{ color: '#9c27b0' }}
                                  control={
                                    <Checkbox
                                      sx={{
                                        color: '#f48fb1',
                                        '&.Mui-checked': {
                                          color: '#f48fb1',
                                        },
                                      }}
                                      checked={fitStatus}
                                      onChange={() => { setFitStatus(!fitStatus); setCurrentPage(1); setSelectedApplicants([]); }}
                                    />
                                  }
                                  label={<span style={{ fontWeight: '600' }}>Fit</span>}
                                />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem>
                              <ListItemIcon>
                                <FormControlLabel
                                  sx={{ color: '#9c27b0' }}
                                  control={
                                    <Checkbox
                                      sx={{
                                        color: '#f48fb1',
                                        '&.Mui-checked': {
                                          color: '#f48fb1',
                                        },
                                      }}
                                      checked={maybeStatus}
                                      onChange={() => { setMaybeStatus(!maybeStatus); setCurrentPage(1); setSelectedApplicants([]); }}
                                    />
                                  }
                                  label={<span style={{ fontWeight: '600' }}>Maybe</span>}
                                />
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem>
                              <ListItemIcon>
                                <FormControlLabel
                                  sx={{ color: '#9c27b0' }}
                                  control={
                                    <Checkbox
                                      sx={{
                                        color: '#f48fb1',
                                        '&.Mui-checked': {
                                          color: '#f48fb1',
                                        },
                                      }}
                                      checked={notFitStatus}
                                      onChange={() => { setNotFitStatus(!notFitStatus); setCurrentPage(1); setSelectedApplicants([]); }}
                                    />
                                  }
                                  label={<span style={{ fontWeight: '600' }}>Not Fit</span>}
                                />
                              </ListItemIcon>
                            </MenuItem>
                          </Menu>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={creatorColumns.length} align="center">
                        <div style={{ height: "20vh" }}>
                          <CircularProgress />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {" "}
                      {apiFailed ? (
                        <TableRow>
                          <TableCell colSpan={creatorColumns.length} align="center">
                            <div className="nodata-found">
                              {!applications
                                ? "No data found"
                                : "No Application found"}
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {creators.map((creator, index) => {
                            const isItemSelected = selectedApplicants && selectedApplicants.some(row => row.userId === creator.userId);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow key={creator.userId} selected={isItemSelected} className="top-campaign-row">
                                {applications && campaignLastDate >= currentDate && (
                                  <TableCell sx={{ padding: "0.3rem" }}>
                                    <Checkbox color="secondary"
                                      checked={isItemSelected}
                                      onChange={(event) => handleRowSelect(event, creator)}
                                      inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                  </TableCell>
                                )}
                                <TableCell
                                  sx={{
                                    maxWidth: !applications
                                      ? "auto"
                                      : "-webkit-fill-available",
                                  }}
                                  className="top-creator-name"
                                >
                                  <div
                                    className="influencers-profile-outer-container"
                                    onClick={() =>
                                      handleProfileOpen(creator.userName)
                                    }
                                  >
                                    <div
                                      className="influencers-profile-container avatar-box"
                                      style={{
                                        backgroundImage: `url(${creator.profilePictureId ? (
                                          `${baseUrl}/download-insta-profile/${creator.profilePictureId}`
                                        ) : (
                                          <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 23 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="_verified_dbbth_91"
                                          >
                                            <path
                                              d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z"
                                              fill="#4AABED"
                                            ></path>
                                          </svg>
                                        )
                                          })`,
                                      }}
                                    ></div>
                                  </div>
                                  <div className="influencer-data-block">
                                    <div
                                      className="influencer-name-block"
                                      onClick={() =>
                                        handleProfileOpen(creator.userName)
                                      }
                                    >
                                      <div className="influencer-name">
                                        {creator.fullName}
                                      </div>
                                    </div>
                                    <a
                                      className="influencer-handle-block influencer-handle"
                                      href={`https://www.instagram.com/${creator.userName}`}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <span>@{creator.userName}</span>
                                    </a>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: !applications ? "auto" : 130,
                                    overflowWrap: "break-word",
                                  }}
                                  onClick={() => handleProfileOpen(creator.userName)}
                                >
                                  <div className="followers-outer-box followers-data-block">
                                    <div
                                      className={`followers-count ${fromPage ? "followers-count-highlight" : ""
                                        }`}
                                    >
                                      {formatNumber(creator.followersCount)}
                                    </div>
                                    <div className="followers-label">Followers</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: !applications ? "auto" : 100,
                                    overflowWrap: "break-word",
                                  }}
                                  onClick={() => handleProfileOpen(creator.userName)}
                                >
                                  <div className="followers-outer-box followers-data-block">
                                    <div
                                      className={`followers-count ${fromPage ? "followers-count-highlight" : ""
                                        }`}
                                    >
                                      {formatNumber(creator.engagement)} ({parseFloat(creator.engagementRate).toFixed(2)}%)
                                    </div>
                                    <div className="followers-label">Engagements</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: !applications ? "auto" : 150,
                                    overflowWrap: "break-word",
                                  }}
                                  onClick={() => handleProfileOpen(creator.userName)}
                                >
                                  <div className="followers-outer-box followers-data-block">
                                    <div
                                      className={`followers-count ${fromPage ? "followers-count-highlight" : ""
                                        }`}
                                    >
                                      Coming Soon
                                      {/* {creator.creatorScore === null ? '0' : formatNumber(Math.round(creator.creatorScore))} */}
                                    </div>
                                    <div className="followers-label">
                                      Creator Score
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: !applications ? "auto" : 150,
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  <div className="followers-outer-box followers-data-block">
                                    {!applications ? (
                                      <Button
                                        variant="contained"
                                        className="ask-for-price-btn"
                                      >
                                        Ask For Price
                                      </Button>
                                    ) : (
                                      <>
                                        <div
                                          className={`followers-count ${fromPage
                                            ? "followers-count-highlight"
                                            : ""
                                            }`}
                                        >
                                          Ask For Price
                                        </div>
                                        <div className="followers-label">
                                          Creator Price
                                        </div>
                                        {/* <div className="followers-label">Starts at</div>
                                  <div className="followers-count starts-at-count">
                                    <CurrencyRupeeIcon sx={{fontSize: '0.8rem'}}/> 
                                    <span className="followers-label per-pc-label">/- per post</span>
                                  </div> */}
                                      </>
                                    )}
                                  </div>
                                </TableCell>
                                {applications && (
                                  <>
                                    <TableCell
                                      sx={{
                                        minWidth: 100,
                                        padding: "0.7rem",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      <div className="application-action-button">
                                        {campaignLastDate <= currentDate ? (
                                          <>
                                            <IconButton sx={{ color: "#4caf50" }} disabled>
                                              {creator.applicationStatus === "Accepted"
                                                ? <CheckCircleIcon />
                                                : <CheckCircleOutlinedIcon />
                                              }
                                            </IconButton>
                                            <IconButton sx={{ color: "#f44336" }} disabled>
                                              {creator.applicationStatus === "Rejected"
                                                ? <CancelIcon />
                                                : <HighlightOffIcon />
                                              }
                                            </IconButton>
                                          </>
                                        ) : (
                                          <>
                                            {(creator.applicationStatus === "Pending" || creator.applicationStatus === "Fit" ||
                                              creator.applicationStatus === "Maybe" || creator.applicationStatus === "Not Fit") && (
                                                <>
                                                  <IconButton
                                                    sx={{ '&:hover': { color: "#4caf50" } }}
                                                    onClick={(e) =>
                                                      handleAcceptApplication(
                                                        e,
                                                        creator.campaignId,
                                                        creator.userId,
                                                        "Accepted"
                                                      )
                                                    }
                                                  >
                                                    <Tooltip title="Accept" placement="top">
                                                      <CheckCircleOutlinedIcon />
                                                    </Tooltip>
                                                  </IconButton>
                                                  <IconButton
                                                    sx={{ '&:hover': { color: "#f44336" } }}
                                                    onClick={(e) =>
                                                      handleAcceptApplication(
                                                        e,
                                                        creator.campaignId,
                                                        creator.userId,
                                                        "Rejected"
                                                      )
                                                    }
                                                  >
                                                    <Tooltip title="Reject" placement="top">
                                                      <HighlightOffIcon />
                                                    </Tooltip>
                                                  </IconButton>
                                                </>
                                              )}
                                            {creator.applicationStatus === "Accepted" && (
                                              <>
                                                <IconButton sx={{ color: "#4caf50", pointerEvents: "none" }}>
                                                  <CheckCircleIcon />
                                                </IconButton>
                                                <IconButton
                                                  sx={{ '&:hover': { color: "#f44336" } }}
                                                  onClick={(e) =>
                                                    handleAcceptApplication(
                                                      e,
                                                      creator.campaignId,
                                                      creator.userId,
                                                      "Rejected"
                                                    )
                                                  }
                                                >
                                                  <Tooltip title="Reject" placement="top">
                                                    <HighlightOffIcon />
                                                  </Tooltip>
                                                </IconButton>
                                              </>
                                            )}
                                            {creator.applicationStatus === "Rejected" && (
                                              <>
                                                <IconButton
                                                  sx={{ '&:hover': { color: "#4caf50" } }}
                                                  onClick={(e) =>
                                                    handleAcceptApplication(
                                                      e,
                                                      creator.campaignId,
                                                      creator.userId,
                                                      "Accepted"
                                                    )
                                                  }
                                                >
                                                  <Tooltip title="Accept" placement="top">
                                                    <CheckCircleOutlinedIcon />
                                                  </Tooltip>
                                                </IconButton>
                                                <IconButton sx={{ color: "#f44336", pointerEvents: "none" }}>
                                                  <CancelIcon />
                                                </IconButton>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        padding: "0.7rem",
                                        overflowWrap: "break-word"
                                      }}
                                    >
                                      <FormControl sx={{ width: 126 }}>
                                        <Select
                                          value={creator.applicationStatus}
                                          onChange={(event) => {
                                            const newValue = event.target.value;
                                            if (newValue !== null) {
                                              handleAcceptApplication(
                                                event,
                                                creator.campaignId,
                                                creator.userId,
                                                newValue
                                              );
                                            }
                                          }}
                                          displayEmpty
                                          inputProps={{ 'aria-label': 'Without label' }}
                                          disabled={
                                            creator.applicationStatus === "Accepted" ||
                                            creator.applicationStatus === "Rejected" ||
                                            campaignLastDate <= currentDate
                                          }
                                        >
                                          {!applicantStatus.includes(creator.applicationStatus) && (
                                            <MenuItem value={creator.applicationStatus} style={{ display: 'none' }}>
                                              {creator.applicationStatus}
                                            </MenuItem>
                                          )}

                                          {applicantStatus.map((status) => (
                                            <MenuItem key={status} value={status}>
                                              {status}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            )
                          })}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </ThemeProvider>
      {!loading ? (
        <div className={windowWidth <= 799 ? "pagination-container-mobile pagination-container" : "pagination-container"}>
          <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', columnGap: '13px' }}>
            <div>Records Per page :</div>
            <FormControl>
              <Select sx={{ backgroundColor: '#fff', width: 'fit-content' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage}
                onChange={(e) => handleRowPerPageChange(e.target.value)}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <CommonPagination currentPage={currentPage} totalPages={totalPages}
            handlePageChange={handlePageChange} />
          {openProfile && (
            <ProfileDialog
              username={username}
              openProfile={openProfile}
              onClose={handleProfileClose}
            />
          )}
        </div>
      ) : null}
      <Dialog
        fullScreen={fullScreen}
        open={openApplicationAlert}
        onClose={handleApplicationAlertClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Creators Required: {noOfCreators}. You have already accepted {noOfCreators} applications, To accept a new application, please reject an existing one first.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApplicationAlertClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        onClose={() => setAlertOpen(false)}
      />
    </>
  );
}

CreatorsList.propTypes = {
  fromPage: PropTypes.any,
};
