import React, { useState, useEffect, useContext } from 'react';
import "../../css/login.css";
import TextField from '@mui/material/TextField';
import { getConfig } from '../common/env-config';
import { refreshToken } from '../common/refresh-token-repo';
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoginAutocomplete from './login-autocomplete';
import { fetchCities } from '../common/common-apis-repo';
import LoginCreatorComponent from './login-creator-component';
import { UserDetailsContext } from '../context/user-details-context';

const LoginPageForm = ({ onUserDetailDataChange }) => {
    const { baseUrl } = getConfig();
    const location = useLocation();
    const navigate = useNavigate();
    const { loggedUser } = useContext(UserDetailsContext);
    const { fetchUserDetails } = useContext(UserDetailsContext);
    const userDetails = location.state?.userDetails;
    const enterpriseClient = location.state?.enterpriseClient;
    const billingDetails = location.state?.billingDetails;
    const adminUpdate = location.state?.adminUpdate;
    const campaignId = location.state?.campaignId;

    const [userDetailData, setUserDetailData] = useState({
        firstName: userDetails != null ? userDetails.firstName : '',
        lastName: userDetails != null ? userDetails.lastName : '',
        email: userDetails != null ? userDetails.email : '',
        selectedCategories: userDetails != null && userDetails.areaOfInterests != null && userDetails.areaOfInterests.length > 0 ? userDetails.areaOfInterests : [],
        selectedCity: userDetails != null && userDetails.location != null ? userDetails.location : '',
        instaHandle: userDetails != null && userDetails.instaHandle != null ? userDetails.instaHandle : null,
        manager: {
            managerId: userDetails != null ? userDetails.manager.managerId : '',
            managerName: userDetails != null ? userDetails.manager.name : '',
            managerPhoneNumber: userDetails != null && userDetails.manager.phoneNumber !== 0 ? userDetails.manager.phoneNumber : '',
            managerEmail: userDetails != null && userDetails.manager.email != null ? userDetails.manager.email : ''
        },
        phoneNumber: userDetails != null ? userDetails.phoneNumber : null,
        bussinessName: userDetails != null && userDetails.billingDetails.bussinessName != null ? userDetails.billingDetails.bussinessName : '',
        billingCity: userDetails != null && userDetails.billingDetails.billingCity != null ? userDetails.billingDetails.billingCity : '',
        zipCode: userDetails != null && userDetails.billingDetails.zipCode != null ? userDetails.billingDetails.zipCode : null,
        legalBusinessName: userDetails != null && userDetails.billingDetails.legalBusinessName != null ? userDetails.billingDetails.legalBusinessName : null,
        panNumber: userDetails != null && userDetails.billingDetails.panNumber != null ? userDetails.billingDetails.panNumber : null,
        gstIn: userDetails != null && userDetails.billingDetails.gstIn != null ? userDetails.billingDetails.gstIn : null,
        registeredBillingAddress: userDetails != null && userDetails.billingDetails.registeredBillingAddress != null ? userDetails.billingDetails.registeredBillingAddress : null,
        billingEmailAddress: userDetails != null && userDetails.billingDetails.billingEmailAddress != null ? userDetails.billingDetails.billingEmailAddress : '',
        additionalRemarks: userDetails != null && userDetails.billingDetails.additionalRemarks != null ? userDetails.billingDetails.additionalRemarks : null,
        salesPersonName: userDetails != null && userDetails.billingDetails.salesPersonName != null ? userDetails.billingDetails.salesPersonName : null
    });
    onUserDetailDataChange(userDetailData);
    const [errorMessage, setErrorMessage] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [categoriesError, setCategoriesError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [instaHandleError, setInstaHandleError] = useState(false);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [phoneNoErrorMsg, setPhoneNoErrorMsg] = useState('');
    const [instaHandleErrorMsg, setInstaHandleErrorMsg] = useState('');
    const [next, setNext] = useState(billingDetails ? true : false);
    const [skip, setSkip] = useState(false);
    const [cities, setCities] = useState([]);
    const [bussinessNameError, setBussinessNameError] = useState(false);
    const [legalBusinessNameError, setLegalBusinessNameError] = useState(false);
    const [panNumberError, setPanNumberError] = useState(false);
    const [regBillingAddressError, setRegBillingAddressError] = useState(false);
    const [billingCityError, setBillingCityError] = useState(false);
    const [zipCodeError, setZipCodeError] = useState(false);
    const [billingEmailAddressError, setBillingEmailAddressError] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const zipCodeRegex = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;

    const handleLogin = async (e) => {
        const buttonText = e.target.textContent.trim();
        e.preventDefault();
        let hasError = false;
        if (userDetailData.firstName.trim() === '') {
            setFirstNameError(true);
            hasError = true;
        } else {
            setFirstNameError(false);
        }
        if (userDetailData.lastName.trim() === '') {
            setLastNameError(true);
            hasError = true;
        } else {
            setLastNameError(false);
        }
        if (!emailRegex.test(userDetailData.email.trim())) {
            setEmailError(true);
            setErrorMessage('Please enter a valid Email');
            hasError = true;
        } else {
            setEmailError(false);
        }
        if (enterpriseClient) {
            if (userDetailData.phoneNumber === null) {
                setPhoneNumberError(true);
                setPhoneNoErrorMsg('Please enter a phone number');
                hasError = true;
            } else {
                setPhoneNumberError(false);
            }
        }
        if (userDetailData.selectedCategories.length === 0) {
            setCategoriesError(true);
            hasError = true;
        } else {
            setCategoriesError(false);
        }
        if (userDetailData.selectedCity === '') {
            setCityError(true);
            hasError = true;
        } else {
            setCityError(false);
        }
        if (!enterpriseClient && (userDetailData.instaHandle === null || userDetailData.instaHandle === "")) {
            setInstaHandleError(true);
            setInstaHandleErrorMsg('Insta handle required for content creator');
            hasError = true;
        } else {
            setInstaHandleError(false);
        }
        if ((buttonText === 'Update Profile' || buttonText === 'Finish Sign up') && enterpriseClient !== undefined) {
            if (userDetailData.bussinessName.trim() === '' && next) {
                setBussinessNameError(true);
                hasError = true;
            } else {
                setBussinessNameError(false);
            }
            if (userDetailData.legalBusinessName === null && next && !skip) {
                setLegalBusinessNameError(true);
                hasError = true;
            } else {
                setLegalBusinessNameError(false);
            }
            if (userDetailData.panNumber === null && next && !skip) {
                setPanNumberError(true);
                hasError = true;
            } else {
                setPanNumberError(false);
            }
            if (userDetailData.registeredBillingAddress === null && next && !skip) {
                setRegBillingAddressError(true);
                hasError = true;
            } else {
                setRegBillingAddressError(false);
            }
            if (userDetailData.zipCode === null && next && !skip) {
                setZipCodeError(true);
                hasError = true;
            } else {
                setZipCodeError(false);
            }
            if (userDetailData.billingCity === '' && next && !skip) {
                setBillingCityError(true);
                hasError = true;
            } else {
                setBillingCityError(false);
            }
            if (!emailRegex.test(userDetailData.billingEmailAddress.trim()) && next && !skip) {
                setBillingEmailAddressError(true);
                setErrorMessage('Please enter a valid Email');
                hasError = true;
            } else {
                setBillingEmailAddressError(false);
            }
        }

        if (hasError) {
            return;
        } else {
            if (enterpriseClient) {
                setNext(true);
            }
        }
        /*----save user profile--- -*/
        const userData = {
            prevInstaHandle: userDetails !== null && userDetails !== "" && userDetails !== undefined ? userDetails.instaHandle !== userDetailData.instaHandle ? userDetails.instaHandle : null : null,
            firstName: userDetailData.firstName,
            lastName: userDetailData.lastName,
            phoneNumber: enterpriseClient ? userDetailData.phoneNumber : userDetails != null ? userDetailData.phoneNumber : localStorage.getItem('phoneNumber'),
            email: userDetailData.email,
            instaHandle: userDetailData.instaHandle,
            role: userDetails !== null && userDetails !== "" && userDetails !== undefined ? userDetails.role : enterpriseClient ? 'ENTERPRISE_CLIENT' : 'CREATOR',
            location: userDetailData.selectedCity,
            areaOfInterests: userDetailData.selectedCategories,
            additionalDetails: userDetailData.legalBusinessName === null || skip ? false : true,
            manager: {
                name: userDetailData.manager.managerName,
                email: userDetailData.manager.managerEmail,
                phoneNumber: userDetailData.manager.managerPhoneNumber,
            },
            billingDetails: {
                bussinessName: userDetailData.bussinessName,
                legalBusinessName: skip ? null : userDetailData.legalBusinessName,
                panNumber: skip ? null : userDetailData.panNumber,
                gstIn: skip ? null : userDetailData.gstIn,
                registeredBillingAddress: skip ? null : userDetailData.registeredBillingAddress,
                billingCity: skip ? null : userDetailData.billingCity,
                zipCode: skip ? null : userDetailData.zipCode,
                billingEmailAddress: skip ? null : userDetailData.billingEmailAddress,
                additionalRemarks: skip ? null : userDetailData.additionalRemarks,
                salesPersonName: skip ? null : userDetailData.salesPersonName
            }
        };

        if (buttonText === 'Update Profile' || buttonText === 'Finish Sign up') {
            await saveProfile(userData);
        }
    };

    const saveProfile = async (userData) => {
        try {
            setIsFormDirty(false);
            const response = await fetch(`${baseUrl}/user/save-user-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                body: JSON.stringify(userData)
            });
            const responseBody = await response.json();
            if (!response.ok) {
                if (responseBody.message && responseBody.message && responseBody.message.includes("Email")) {
                    setEmailError(true);
                    setErrorMessage("Email already exist");
                    setNext(false);
                } else if (responseBody.message && responseBody.message && responseBody.message.includes("Insta handel")) {
                    setInstaHandleError(true);
                    setInstaHandleErrorMsg("Insta handle already exist");
                    setNext(false);
                } else if (response.status === 400) {
                    setInstaHandleError(true);
                    setInstaHandleErrorMsg("Insta handle is required for creator");
                    setNext(false);
                } else if (response.status === 499) {
                    refreshToken();
                } else {
                    console.error('Error saving user profile:', responseBody.message);
                    throw new Error('Failed to save user profile');
                }
            } else {
                if (!enterpriseClient && adminUpdate) {
                    navigate("/creator/admin-panel/user-list", { state: { enterpriseClient: false } })
                } else if (enterpriseClient && adminUpdate) {
                    navigate("/enterprise-client/admin-panel/enterprise-list", { state: { enterpriseClient: true } })
                } else if (campaignId) {
                    await fetchUserDetails();
                    navigate("/all-campaigns/details", { state: { campaignid: campaignId } });
                } else {
                    localStorage.setItem("profileExist", true);
                    localStorage.setItem("role", loggedUser.role);
                    localStorage.setItem("access", loggedUser.access);
                    if (loggedUser.role === 'ADMIN') {
                        await fetchUserDetails();
                        navigate("/admin-dashboard");
                    } else if (localStorage.getItem('role') === 'CREATOR') {
                        await fetchUserDetails();
                        navigate("/dashboard");
                    } else if (localStorage.getItem('role') === 'ENTERPRISE_CLIENT') {
                        await fetchUserDetails();
                        navigate("/discovery");
                    } else if (localStorage.getItem('role') === 'LEAD_AGENT') {
                        await fetchUserDetails();
                        navigate("/enterprise-client/ec-leads-dashboard");
                    } else if (localStorage.getItem('role') === 'LEAD_MANAGER') {
                        await fetchUserDetails();
                        navigate("/enterprise-client/ec-leads-dashboard");
                    }
                }
            }
        } catch (error) {
            alert("Failed to save user data");
            console.error('Error saving user profile:', error);
        }
    }

    useEffect(() => {
        fetchCities(setCities);
    }, []);

    const handleChange = (fieldName, value) => {
        const isSameValue = userDetailData[fieldName] === value;
        const isNotEmptyOrNull = value !== '' && value !== null;

        setUserDetailData((userDetailData) => {
            if (fieldName.startsWith('manager.')) {
                const managerField = fieldName.split('.')[1];
                return {
                    ...userDetailData,
                    manager: {
                        ...userDetailData.manager,
                        [managerField]: value,
                    },
                };
            }
            return {
                ...userDetailData,
                [fieldName]: value,
            };
        });
        if (isNotEmptyOrNull && !isSameValue) {
            setIsFormDirty(true);
        } else if (isSameValue) {
            setIsFormDirty(false);
        } else {
            setIsFormDirty(false);
        }
    };

    const disabledButton =
        (userDetailData.bussinessName !== '') &&
        (!skip) &&
        (userDetailData.legalBusinessName === null || userDetailData.panNumber === null ||
            userDetailData.registeredBillingAddress === null || userDetailData.billingCity === '' ||
            userDetailData.zipCode === null || userDetailData.billingEmailAddress === '');

    useEffect(() => {
        if (isFormDirty) {
            const handleBeforeUnload = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    e.returnValue = message;
                    return message;
                }
            };

            const handleNavClick = (e) => {
                if (isFormDirty) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    if (!window.confirm(message)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            const handlePopState = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    const userConfirmed = window.confirm(message);
                    if (!userConfirmed) {
                        window.history.pushState(null, '', window.location.pathname);
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            window.history.pushState(null, '', window.location.pathname);

            const navLinks = document.querySelectorAll('.nav-link');
            navLinks.forEach((link) => {
                link.addEventListener('click', handleNavClick);
            });

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
                navLinks.forEach((link) => {
                    link.removeEventListener('click', handleNavClick);
                });
            };
        }
    }, [isFormDirty]);

    const filterOptions = (options, { inputValue }) => {
        const filtered = options.filter((option) => {
            if (typeof option === 'string') {
                return option.toLowerCase().includes(inputValue.toLowerCase());
            }
            return `${option.city} - ${option.state}`.toLowerCase().includes(inputValue.toLowerCase());
        });

        if (inputValue !== '') {
            filtered.push({
                city: `Add "${inputValue}"`,
                state: '',
                inputValue,
            });
        }

        return filtered;
    };

    return (
        <>
            {!next
                ? <>
                    <TextField className="custom-textfield" id="outlined-basic" label="First Name" variant="outlined" value={userDetailData.firstName} onChange={(e) => { handleChange('firstName', e.target.value.replace(/[^A-Za-z]/g, '')); setFirstNameError(false); }}
                        error={firstNameError} helperText={firstNameError ? 'Please fill First Name' : ''} style={{ width: 378 }} inputProps={{ maxLength: 25 }} />
                    <TextField className="custom-textfield" id="outlined-basic" label="Last Name" variant="outlined" value={userDetailData.lastName} onChange={(e) => { handleChange('lastName', e.target.value.replace(/[^A-Za-z]/g, '')); setLastNameError(false) }}
                        error={lastNameError} helperText={lastNameError ? 'Please fill Last Name' : ''} style={{ width: 378 }} inputProps={{ maxLength: 25 }} />
                    <TextField className="custom-textfield" id="outlined-basic" label="Email" variant="outlined" type="email" value={userDetailData.email} onChange={(e) => { handleChange('email', e.target.value); setEmailError(false) }}
                        error={emailError || (userDetailData.email != null && userDetailData.email !== '' && !emailRegex.test(userDetailData.email))} helperText={emailError ? errorMessage : (userDetailData.email != null && userDetailData.email !== '' && !emailRegex.test(userDetailData.email)) ? 'Please enter valid email address' : ''} style={{ width: 378 }} />
                    {enterpriseClient &&
                        <TextField className="custom-textfield" id="outlined-basic" label="Phone Number" variant="outlined" value={userDetailData.phoneNumber} onChange={(e) => {
                            const phoneNumberinput = e.target.value;
                            const phoneNumberRegex = phoneNumberinput.replace(/\D/g, '');
                            handleChange('phoneNumber', phoneNumberRegex.slice(0, 12));
                            setPhoneNumberError(false);
                        }} error={(!/^91/.test(userDetailData.phoneNumber) && userDetailData.phoneNumber != null) || (phoneNoErrorMsg !== '' && phoneNumberError)}
                            helperText={!/^91/.test(userDetailData.phoneNumber) && userDetailData.phoneNumber != null
                                ? 'Phone number should start with 91'
                                : phoneNumberError
                                    ? phoneNoErrorMsg
                                    : ''
                            } style={{ width: 378 }} />
                    }
                    <LoginAutocomplete handleChange={handleChange} categoriesError={categoriesError} selectedCategories={userDetailData.selectedCategories}
                        selectedCity={userDetailData.selectedCity} cityError={cityError} />
                    <LoginCreatorComponent handleChange={handleChange} enterpriseClient={enterpriseClient}
                        instaHandle={userDetailData.instaHandle}
                        managerName={userDetailData.manager.managerName} managerEmail={userDetailData.manager.managerEmail}
                        managerPhoneNumber={userDetailData.manager.managerPhoneNumber}
                        managerId={userDetailData.manager.managerId}
                        instaHandleError={instaHandleError} instaHandleErrorMsg={instaHandleErrorMsg} setInstaHandleError={setInstaHandleError} setInstaHandleErrorMsg={setInstaHandleErrorMsg} />
                    <div className="logMeUp">
                        <button type='submit' className="log-me-up-button" onClick={(e) => { handleLogin(e) }}>
                            {userDetails != null ? enterpriseClient ? 'Next' : 'Update Profile' : enterpriseClient ? 'Next' : 'Finish Sign up'}
                        </button>
                    </div>
                    {!enterpriseClient &&
                        <span>By Signing in, you agree to <a href="/terms-of-service" target="_blank" className="privacy-policy-link-text">Terms</a> and <a href="/privacy-policy" target="_blank" className="privacy-policy-link-text">Policy</a></span>
                    }
                </>
                : <>
                    <TextField className="custom-textfield" id="outlined-basic" label="Bussiness Name" variant="outlined" value={userDetailData.bussinessName}
                        onChange={(e) => { handleChange('bussinessName', e.target.value.replace(/[^A-Za-z0-9\s&_@]/g, '')); setBussinessNameError(false) }} error={bussinessNameError} helperText={bussinessNameError ? 'Please enter bussiness name' : ''} style={{ width: 378 }} inputProps={{ maxLength: 50 }} />
                    <Divider />
                    <Button variant="text" sx={{ color: '#e563a4' }} onClick={() => { setSkip(skip ? false : true); }}>{!skip ? 'Skip Additional Details' : 'Add Additional Details'}</Button>
                    {!skip &&
                        <>
                            <TextField className="custom-textfield" id="outlined-basic" label="Legal Business Name" variant="outlined" value={userDetailData.legalBusinessName} onChange={(e) => { handleChange('legalBusinessName', e.target.value.replace(/[^A-Za-z0-9\s&_@]/g, '')); setLegalBusinessNameError(false) }}
                                error={legalBusinessNameError} helperText={legalBusinessNameError ? 'Please enter legal bussiness name' : ''} style={{ width: 378 }} inputProps={{ maxLength: 50 }} />
                            <TextField className="custom-textfield" id="outlined-basic" label="Pan Number" variant="outlined" value={userDetailData.panNumber} onChange={(e) => { handleChange('panNumber', e.target.value.replace(/[^A-Z0-9]/g, '')); setPanNumberError(false) }} error={panNumberError || (userDetailData.panNumber !== null && userDetailData.panNumber !== '' && !panRegex.test(userDetailData.panNumber))}
                                helperText={panNumberError ? 'Please enter a valid PAN number' : (userDetailData.panNumber !== null && userDetailData.panNumber !== '' && !panRegex.test(userDetailData.panNumber)) ? 'Please enter a valid PAN number' : ''} style={{ width: 378 }} inputProps={{ maxLength: 10 }} />
                            <TextField className="custom-textfield" id="outlined-basic" label="GSTIN" variant="outlined" value={userDetailData.gstIn} onChange={(e) => { handleChange('gstIn', e.target.value.replace(/[^A-Z0-9]/g, '')) }} error={userDetailData.gstIn !== null && userDetailData.gstIn !== '' && !gstinRegex.test(userDetailData.gstIn)}
                                helperText={userDetailData.gstIn !== null && userDetailData.gstIn !== '' && !gstinRegex.test(userDetailData.gstIn) ? 'Please enter a valid GSTIN number' : ''} style={{ width: 378 }} inputProps={{ maxLength: 15 }} />
                            <TextField className="custom-textfield" id="outlined-basic" label="Registered Billing Address" variant="outlined" value={userDetailData.registeredBillingAddress} onChange={(e) => { handleChange('registeredBillingAddress', e.target.value.replace(/[^A-Za-z0-9&@, ]/g, '')); setRegBillingAddressError(false) }}
                                error={regBillingAddressError} helperText={regBillingAddressError ? 'Please enter registered billing address' : ''} style={{ width: 378 }} inputProps={{ maxLength: 50 }} />
                            <div className='city'>
                                <Autocomplete
                                    className='city-dropdown'
                                    freeSolo
                                    options={cities}
                                    getOptionLabel={(option) => {
                                        if (!option || (Array.isArray(option) && option.length === 0)) {
                                            return '';
                                        }
                                        return typeof option === 'string' ? option : `${option.city} - ${option.state}`;
                                    }}
                                    filterOptions={filterOptions}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {typeof option === 'string'
                                                ? option
                                                : `${option.city}${option.state ? ` - ${option.state}` : ''}`}
                                        </li>
                                    )}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            if (typeof newValue === 'string') {
                                                handleChange('billingCity', newValue);
                                            } else if (newValue.inputValue) {
                                                handleChange('billingCity', newValue.inputValue);
                                            } else {
                                                const selectedValue = `${newValue.city} - ${newValue.state}`;
                                                handleChange('billingCity', selectedValue);
                                            }
                                        } else {
                                            handleChange('billingCity', '');
                                        }
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    style={{ width: 378 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Billing City"
                                            InputProps={{
                                                ...params.InputProps,
                                                style: { width: '100%' }
                                            }}
                                            error={billingCityError}
                                            helperText={billingCityError && !userDetailData.billingCity ? 'Please select a billing city' : ''}
                                        />
                                    )}
                                    value={userDetailData.billingCity}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ maxHeight: '120px', overflowY: 'hidden' }}>
                                            {children}
                                        </Paper>
                                    )}
                                />
                            </div>
                            <TextField className="custom-textfield" id="outlined-basic" label="Zip Code" variant="outlined" value={userDetailData.zipCode} onChange={(e) => { handleChange('zipCode', e.target.value.replace(/\D/, '')); setZipCodeError(false) }} error={zipCodeError || (userDetailData.zipCode !== null && userDetailData.zipCode !== '' && !zipCodeRegex.test(userDetailData.zipCode))} helperText={zipCodeError ? 'Please enter zip code' : (userDetailData.zipCode !== null && userDetailData.zipCode !== '' && !zipCodeRegex.test(userDetailData.zipCode)) ? 'Please enter valid zipcode' : ''}
                                style={{ width: 378 }} inputProps={{ maxLength: 6 }} />
                            <TextField className="custom-textfield" id="outlined-basic" label="Billing Email Address" variant="outlined" type="email" value={userDetailData.billingEmailAddress}
                                onChange={(e) => { handleChange('billingEmailAddress', e.target.value); setBillingEmailAddressError(false) }} error={billingEmailAddressError || (userDetailData.billingEmailAddress !== null && userDetailData.billingEmailAddress !== '' && !emailRegex.test(userDetailData.billingEmailAddress))}
                                helperText={billingEmailAddressError ? errorMessage : (userDetailData.billingEmailAddress !== null && userDetailData.billingEmailAddress !== '' && !emailRegex.test(userDetailData.billingEmailAddress)) ? 'Please enter valid billing email address' : ''}
                                style={{ width: 378 }} />
                            <TextField className="custom-textfield" id="outlined-basic" label="Additional Remarks" variant="outlined" value={userDetailData.additionalRemarks} onChange={(e) => { handleChange('additionalRemarks', e.target.value.replace(/[^A-Za-z0-9&, ]/g, '')); }} style={{ width: 378 }} inputProps={{ maxLength: 50 }} />
                            <TextField className="custom-textfield" id="outlined-basic" label="Sales Person Name" variant="outlined" value={userDetailData.salesPersonName} onChange={(e) => { handleChange('salesPersonName', e.target.value.replace(/[^A-Za-z\s]/g, '')); }} style={{ width: 378 }} inputProps={{ maxLength: 25 }} />
                        </>
                    }
                    <div className="logMeUp">
                        <button type='submit' className={disabledButton ? 'log-me-up-disable' : 'log-me-up-button'} disabled={disabledButton} onClick={(e) => {
                            handleLogin(e);
                        }}>
                            {userDetails != null ? 'Update Profile' : 'Finish Sign up'}
                        </button>
                        <button type='submit' className="back-button" onClick={() => { setNext(false); }}>Back</button>
                    </div>
                    <span>By Signing in, you agree to <a href="/terms-of-service" target="_blank" className="privacy-policy-link-text">Terms</a> and <a href="/privacy-policy" target="_blank" className="privacy-policy-link-text">Policy</a></span>
                </>
            }
        </>
    );
};

export default LoginPageForm;
