/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../css/user-list.css";
import { CircularProgress, Table, TableHead, TableBody, TableCell, Chip, Box, TextField, Paper, InputBase, IconButton, FormControl, Select, Tooltip, Typography, TableContainer, Popover, Dialog, DialogTitle, DialogContent } from "@mui/material";
import CommonPagination from "../common/common-pagination";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import CachedIcon from "@mui/icons-material/Cached";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { reloadInstaInsights } from "../common/user-details-repo";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import DialogBox from "../common/dialog-box";
import { StyledTableRow, TableCellStyledBox, TableRowStyledBox } from "../../mui/mui-styling";
import { WindowWidthContext } from "../context/user-details-context";
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import debounce from 'lodash/debounce';
import { downloadUserDetailsAsCSV, fetchEnterpriseClients, fetchUserList } from "./admin-repository";
import { fetchCategories, fetchCities } from './../common/common-apis-repo';
import { renderCityFilterContent, renderInterestFilterContent } from "./admin-filter";
import ProfileDialog from "../home/profile-popup";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getConfig } from '../common/env-config';

const { baseUrl } = getConfig();

function AdminTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const enterpriseClient = location.state?.enterpriseClient;
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElAction, setAnchorElAction] = useState(null);
  const [openAction, setOpenAction] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reloading, setReloading] = useState(false);
  const [openBillingDetailAlert, setOpenBillingDetailAlert] = useState(false);
  const [privateInstaAccounts, setPrivateInstaAccounts] = useState(false);
  const [incompleteRegistration, setIncompleteRegistration] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [apiFailed, setApiFailed] = useState(false);
  const [goToPage, setGoToPage] = useState(1);
  const [csvLoading, setCsvLoading] = useState(false);
  const [showFilterListOffIcon, setShowFilterListOffIcon] = useState(false);
  const windowWidth = useContext(WindowWidthContext);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedStates] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [followersCountFrom, setFollowersCountFrom] = useState();
  const [followersCountTo, setFollowersCountTo] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [creationTimeRangeFrom, setCreationTimeRangeFrom] = useState();
  const [creationTimeRangeTo, setCreationTimeRangeTo] = useState();
  const [showCityFilterAltOffIcon, setCityShowFilterAltOffIcon] = useState(false);
  const [showDateFilterAltOffIcon, setDateShowFilterAltOffIcon] = useState(false);
  const [showCategoryFilterAltOffIcon, setCategoryShowFilterAltOffIcon] = useState(false);
  const [showFollowerFilterAltOffIcon, setFollowerFilterAltOffIcon] = useState(false);
  const [showDateRangeFilterAltOffIcon, setDateRangeFilterAltOffIcon] = useState(false);
  const [anchorElCityFilter, setAnchorElCityFilter] = useState(null);
  const [anchorElCategoryFilter, setAnchorElCategoryFilter] = useState(null);
  const [anchorElFollowerFilter, setAnchorElFollowerFilter] = useState(null);
  const [anchorElDateRangeFilter, setAnchorElDateRangeFilter] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [inputCityValue, setInputCityValue] = useState('');
  const [userId, setUserId] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const uniqueCities = [...new Set(cities.map(city => `${city.city}${city.state ? ` - ${city.state}` : ''}`))];
  const [openCategoryFilter, setOpenCategoryFilter] = useState(false);
  console.log(openCategoryFilter);
  console.log(showDateFilterAltOffIcon);

  const handleButtonClick = (event, user) => {
    setSelectedUser(user);

  };

  const handleClose = () => {
    setSelectedUser(null);

  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = (event, user) => {
    handleButtonClick(event, user);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    handleClose();
    setDialogOpen(false);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setGoToPage(value);
  };

  useEffect(() => {
    if (enterpriseClient) {
      fetchEnterpriseClients(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
    } else {
      fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
    }
    // eslint-disable-next-line
  }, [enterpriseClient, currentPage, recordsPerPage, searchString, privateInstaAccounts, incompleteRegistration]);

  const handleActionClick = (event, user) => {
    setAnchorElAction(event.currentTarget);
    setOpenAction(true);
    setSelectedUser(user);
  };

  const handleActionClose = () => {
    setAnchorElAction(null);
    setOpenAction(false);
  };

  const handleReload = async (instaHandle) => {
    setReloading(true);
    await reloadInstaInsights(instaHandle);
    setReloading(false);
  };

  const createCampaigns = async (user) => {
    if (user.additionalDetails) {
      const userName = user.firstName + " " + user.lastName;
      navigate("/all-campaigns/create", {
        state: { userId: user.userId, userName, enterpriseClient: true },
      });
    } else {
      setOpenBillingDetailAlert(true);
    }
  };

  const editUserProfile = () => {
    if (enterpriseClient) {
      navigate("/enterprise-client/registration", {
        state: {
          userDetails: selectedUser,
          enterpriseClient: true,
          adminUpdate: true,
        },
      });
    } else {
      navigate("/login", {
        state: {
          userDetails: selectedUser,
          enterpriseClient: selectedUser.role === "ENTERPRISE_CLIENT",
          adminUpdate: true,
        },
      });
    }
  };
  const addEnterpriseClient = async () => {
    navigate("/enterprise-client/registration", { state: { enterpriseClient: true, adminUpdate: true } })
  }

  const handleRowPerPageChange = (value) => {
    setRecordsPerPage(value);
    setGoToPage(1);
    setCurrentPage(1);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setCurrentPage(goToPage === '' ? 1 : goToPage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value.length === 1) {
      setGoToPage(1);
      setCurrentPage(1);
    }
  };

  const handleBlur = () => {
    if (goToPage === '') {
      setGoToPage(1);
      setCurrentPage(1);
    }
  };

  const handleFilterOffClick = (event) => {
    if (showFilterListOffIcon) {
      setShowFilterListOffIcon(false);
      setPrivateInstaAccounts(false);
      setIncompleteRegistration(false);
      setCurrentPage(1);
      setGoToPage(1);
      fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
    } else {
      setAnchorElFilter(event.currentTarget);
    }
  };

  const debouncedHandleChange = useMemo(
    () => debounce((value) => setSearchString(value), 300),
    [setSearchString]
  );

  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel();
    };
  }, [debouncedHandleChange]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    debouncedHandleChange(value);
    setCurrentPage(1);
    setGoToPage(1);
  };


  // city
  const handleCityFilter = (event) => {
    if (showCityFilterAltOffIcon) {
      setCityShowFilterAltOffIcon(false);
      setSelectedCities([]);
      if (enterpriseClient) {
        fetchEnterpriseClients(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, [], selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      } else {
        fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, [], selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      }
      setCurrentPage(1);
      setGoToPage(1);
      setSelectedCities([]);
    } else {
      setAnchorElCityFilter(event.currentTarget);
    }
  };

  const handleCityFilterClose = () => {
    setAnchorElCityFilter(null);
  };


  const handleCityInputChange = (event, value) => {
    setInputCityValue(value);
  };
  const handleCityInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (enterpriseClient) {
        fetchEnterpriseClients(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      } else {
        fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      }
      setCurrentPage(1);
      setGoToPage(1);
      setAnchorElCityFilter(null);
    }
  };

  // categories
  const handleCategoryFilter = (event) => {
    if (showCategoryFilterAltOffIcon) {
      setCategoryShowFilterAltOffIcon(false);
      setSelectedCategories([]);
      if (enterpriseClient) {
        fetchEnterpriseClients(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, [], followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      } else {
        fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, [], followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      }
      setCurrentPage(1);
      setGoToPage(1);
      setSelectedCategories([]);
    } else {
      setAnchorElCategoryFilter(event.currentTarget);
    }
  };

  const handleCategoryFilterClose = () => {
    setAnchorElCategoryFilter(null);
  };

  const handleCategoryInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategories(newValue);
    setOpenCategoryFilter(false);
  };
  const handleCategoryInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (enterpriseClient) {
        fetchEnterpriseClients(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      } else {
        fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      }
      setCurrentPage(1);
      setGoToPage(1);
      setAnchorElCategoryFilter(null);
    }
  };

  // follower
  const handleFollowerFilter = (event) => {
    if (showFollowerFilterAltOffIcon) {
      setFollowerFilterAltOffIcon(false);
      fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, null, null, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      setCurrentPage(1);
      setGoToPage(1);
      setFollowersCountFrom(null);
      setFollowersCountTo(null)
    } else {
      setAnchorElFollowerFilter(event.currentTarget);
    }
  };
  const handleFollowerFilterClose = () => {
    setAnchorElFollowerFilter(null);
  };

  const rendeFollowerFilterContent = () => {
    const applyFollowersFilter = () => {
      fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      setCurrentPage(1);
      setGoToPage(1);
      setAnchorElFollowerFilter(null);
      setFollowerFilterAltOffIcon(true);
    };

    const isDisabled = !followersCountFrom || !followersCountTo;
    return (
      <>
        <div className='followers-range-div'>
          <div className='followers-range-col'>
            <div className='followers-range-text'>Followers From:</div>
            <TextField
              id="outlined-basic"
              value={followersCountFrom}
              placeholder='Followers From'
              variant="outlined"
              type='number'
              inputProps={{ min: "0", pattern: "[0-9]*" }}
              onChange={(e) => setFollowersCountFrom(e.target.value)}
            />
          </div>
          <div className='followers-range-col'>
            <div className='followers-range-text'>Followers To:</div>
            <TextField
              id="outlined-basic"
              value={followersCountTo}
              placeholder="Followers To"
              variant="outlined"
              type='number'
              inputProps={{ min: "0", pattern: "[0-9]*" }}
              onChange={(e) => setFollowersCountTo(e.target.value)}
            />
          </div>
        </div>
        <div className='followers-range-footer'>
          <Button disabled={isDisabled} onClick={applyFollowersFilter}>Apply Filter</Button>
        </div>
      </>
    );
  };



  const handleProfileOpen = (userId) => {
    setUserId(userId);
    setOpenProfile(true);
  };

  const handleProfileClose = () => {
    setOpenProfile(false);
  };

  const handleFromDateChange = (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
    setCreationTimeRangeFrom(formattedDate);
    setFromDate(formattedDate);
    if (toDate && newDate && dayjs(toDate).isBefore(newDate)) {
      setFromDate(null);
    }
  };

  const handleToDateChange = (newDate) => {
    const formattedDate = newDate ? dayjs(newDate).format('YYYY-MM-DD') : null;
    setToDate(formattedDate);
    setCreationTimeRangeTo(formattedDate);
  };

  useEffect(() => {
    fetchCategories(setCategories);
    fetchCities(setCities);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };
  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      const roundedNumber = Math.round(num * 100) / 100;
      if (Number.isInteger(roundedNumber)) {
        return roundedNumber.toString();
      } else {
        return roundedNumber.toFixed(2);
      }
    }
  };

  //date range
  const handleDateRangeFilter = (event) => {
    if (showDateRangeFilterAltOffIcon) {
      setDateRangeFilterAltOffIcon(false);
      setFromDate(null);
      setToDate(null);
      if (enterpriseClient) {
        fetchEnterpriseClients(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, null, null, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      } else {
        fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, null, null, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      }
      setCurrentPage(1);
      setGoToPage(1);
      setCreationTimeRangeFrom(null);
      setCreationTimeRangeTo(null);
    } else {
      setAnchorElDateRangeFilter(event.currentTarget);
    }
  };
  const handleDateRangeFilterClose = () => {
    setAnchorElDateRangeFilter(null);
  };

  const rendeDateRangeFilterContent = () => {
    const applyDateRangeFilter = () => {
      if (enterpriseClient) {
        fetchEnterpriseClients(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      } else {
        fetchUserList(setIsLoading, setApiFailed, currentPage, recordsPerPage, privateInstaAccounts, incompleteRegistration, selectedCities, selectedStates, selectedCategories, followersCountFrom, followersCountTo, creationTimeRangeFrom, creationTimeRangeTo, searchString, setCityShowFilterAltOffIcon, setDateShowFilterAltOffIcon, setCategoryShowFilterAltOffIcon, setUserList, setTotalCount);
      }
      setCurrentPage(1);
      setGoToPage(1);
      setAnchorElDateRangeFilter(null);
      setDateRangeFilterAltOffIcon(true);
    };

    const isDisabled = !fromDate || !toDate;
    return (
      <>
        <div className='followers-range-div'>
          <div className='followers-range-col'>
            <div className='followers-range-text'>From Date:</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={fromDate ? dayjs(fromDate) : null}
                onChange={handleFromDateChange}
                format="YYYY-MM-DD"
                renderInput={(params) => (<TextField {...params} variant="outlined" />)}
              />
            </LocalizationProvider>
          </div>
          <div className='followers-range-col'>
            <div className='followers-range-text'>To Date:</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={toDate ? dayjs(toDate) : null}
                onChange={handleToDateChange}
                format="YYYY-MM-DD"
                minDate={fromDate ? dayjs(fromDate) : null}
                renderInput={(params) => (<TextField {...params} variant="outlined" />)}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className='followers-range-footer'>
          <Button disabled={isDisabled} onClick={applyDateRangeFilter}>Apply Filter</Button>
        </div>
      </>
    );
  };


  return (
    <>
      <Box sx={{ width: windowWidth <= 799 ? '100vw' : '100%' }}>
        <Paper sx={{ width: windowWidth <= 799 ? '100vw' : '100%', mb: 2, backgroundColor: '#f4f5f8', padding: '30px 20px 20px 20px', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <div className="userslist-header">
            <Typography sx={{
              flex: '1 1 100%',
              color: '#3b4758',
              fontFamily: 'DM Sans',
              fontSize: '24px',
              fontWeight: 600,
              opacity: 1,
              paddingBottom: '12px'
            }}>{enterpriseClient ? 'Enterprise Clients' : 'Creators List'}</Typography>

            <div className="hearder-filters">
              <div className='userslist-searchbar'>
                <div style={{ marginRight: '8px' }}>
                  <SearchIcon />
                </div>
                <Tooltip title={enterpriseClient ? "Search By Name, Business Name, Email Id, Phone Number..." : "Search By Name, Insta Handle, Email Id, Phone Number..."} arrow>
                  <InputBase
                    placeholder={enterpriseClient ? "Search By Name, Business Name, Email Id, Phone Number..." : "Search By Name, Insta Handle, Email Id, Phone Number..."}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={handleSearchChange}
                    style={{ width: '100%' }}
                  />
                </Tooltip>
              </div>
              <div className="userlist-filter">
                {enterpriseClient ?
                  <Button variant="contained" className="enterprise-client-btn" sx={{
                    backgroundColor: '#e563a4', '&:hover': {
                      backgroundColor: '#e326a4'
                    }
                  }} onClick={() => { addEnterpriseClient(); }}
                    startIcon={<AddIcon />}>Enterprise Client
                  </Button> :
                  <>
                    <Tooltip title="Filter" arrow>
                      <IconButton onClick={handleFilterOffClick}>
                        {showFilterListOffIcon ? <FilterListOffIcon /> : <FilterListIcon />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download CSV" arrow>
                      <IconButton onClick={() => { downloadUserDetailsAsCSV(privateInstaAccounts, incompleteRegistration, setCsvLoading, selectedCities, selectedStates, followersCountFrom, followersCountTo, selectedCategories, searchString, fromDate, toDate) }}>
                        {csvLoading ? <CircularProgress size={24} /> : <FileDownloadIcon />}
                      </IconButton>
                    </Tooltip>
                  </>
                }
                <Menu
                  anchorEl={anchorElFilter}
                  open={Boolean(anchorElFilter)}
                  onClose={() => setAnchorElFilter(null)}
                >
                  <MenuItem onClick={() => {
                    setPrivateInstaAccounts(true);
                    setIncompleteRegistration(false);
                    setAnchorElFilter(null);
                    setShowFilterListOffIcon(true);
                  }}
                  >
                    Private accounts
                  </MenuItem>
                  <MenuItem onClick={() => {
                    setIncompleteRegistration(true);
                    setPrivateInstaAccounts(false);
                    setAnchorElFilter(null);
                    setShowFilterListOffIcon(true);
                  }}
                  >
                    Incomplete Registrations
                  </MenuItem>
                </Menu>
              </div>
            </div>

          </div>
          <TableContainer sx={{ padding: '0px 15px 25px 15px' }}>
            <Table>
              <TableHead>
                <TableRowStyledBox>
                  <TableCell className="userlist-tablehead">Sl.No</TableCell>
                  <TableCell className="userlist-tablehead">{enterpriseClient ? ' Full Name(Business Name)' : 'Creator'}</TableCell>
                  {!enterpriseClient && (
                    <TableCell className="userlist-tablehead">
                      <div className="creator-header-filter-icon">
                        Followers
                        <IconButton onClick={handleFollowerFilter}>
                          {showFollowerFilterAltOffIcon ? <FilterAltOffIcon /> : <FilterAltIcon />}
                        </IconButton>
                      </div>
                    </TableCell>
                  )}
                  {!enterpriseClient && (
                    <TableCell className="userlist-tablehead">
                      E(ER)
                    </TableCell>
                  )}
                  {/* {!enterpriseClient && (

                      <TableCell className="userlist-tablehead">
                        Creator's Score
                      </TableCell>
                    )} */}
                  <TableCell className="userlist-tablehead">
                    <div className="creator-header-filter-icon">
                      City
                      <IconButton onClick={handleCityFilter}>
                        {showCityFilterAltOffIcon ? <FilterAltOffIcon /> : <FilterAltIcon />}
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell className="userlist-tablehead">
                    <div className="creator-header-filter-icon">
                      Date
                      <IconButton onClick={handleDateRangeFilter}>
                        {showDateRangeFilterAltOffIcon ? <FilterAltOffIcon /> : <FilterAltIcon />}
                      </IconButton>

                    </div>
                  </TableCell>

                  <TableCell className="userlist-tablehead">
                    <div className="creator-header-filter-icon">
                      AOI
                      <IconButton onClick={handleCategoryFilter}>
                        {showCategoryFilterAltOffIcon ? <FilterAltOffIcon /> : <FilterAltIcon />}
                      </IconButton>
                    </div>
                  </TableCell>
                  {/* <TableCell className="userlist-tablehead">Contact</TableCell> */}
                  <TableCell className="userlist-tablehead">Actions</TableCell>
                </TableRowStyledBox>
                <Popover
                  open={Boolean(anchorElCityFilter)}
                  anchorEl={anchorElCityFilter}
                  onClose={handleCityFilterClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                  <Typography sx={{ p: 3 }}>
                    {renderCityFilterContent(uniqueCities, handleCityInputChange, inputCityValue, setSelectedCities, handleCityInputKeyPress, selectedCities)}
                  </Typography>
                </Popover>

                <Popover
                  open={Boolean(anchorElCategoryFilter)}
                  anchorEl={anchorElCategoryFilter}
                  onClose={handleCategoryFilterClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Typography sx={{ p: 3 }}>
                    {renderInterestFilterContent(categories, selectedCategories, handleCategoryChange, handleCategoryInputChange, inputValue, handleCategoryInputKeyPress)}
                  </Typography>
                </Popover>

                <Popover
                  open={Boolean(anchorElFollowerFilter)}
                  anchorEl={anchorElFollowerFilter}
                  onClose={handleFollowerFilterClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Typography sx={{ p: 3 }}>
                    {rendeFollowerFilterContent()}
                  </Typography>
                </Popover>

                <Popover
                  open={Boolean(anchorElDateRangeFilter)}
                  anchorEl={anchorElDateRangeFilter}
                  onClose={handleDateRangeFilterClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Typography sx={{ p: 3 }}>
                    {rendeDateRangeFilterContent()}
                  </Typography>
                </Popover>
              </TableHead>
              {isLoading ? (
                <TableCell align="center" colSpan={8} style={{ borderBottom: 'none', padding: 0 }}>
                  <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {isLoading ? <CircularProgress /> : null}
                  </div>
                </TableCell>
              ) : userList.length === 0 ? (
                <TableCell align="center" colSpan={8} style={{ borderBottom: 'none', padding: 0 }}>
                  <div style={{ height: "20vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="no-data">
                      {enterpriseClient ? "No Enterprise Clients Found" : "No Creator Found"}
                    </div>
                  </div>
                </TableCell>
              ) : (
                <TableBody>
                  {userList.map((user, index) => (
                    <StyledTableRow className='top-campaign-row'
                      tabIndex={-1} key={user.userId} sx={{ cursor: 'pointer' }}
                    >
                      <TableCell sx={{ ...TableCellStyledBox }}>{index + 1}</TableCell>
                      <TableCell onClick={() => handleProfileOpen(user.userId)} sx={{ maxWidth: 254, ...TableCellStyledBox }} >
                        <div className="profile-picture">
                          <div
                            className="influencers-profile-container avatar-box"
                            style={{
                              backgroundImage: `url(${user.profilePictureId ? (
                                `${baseUrl}/download-insta-profile/${user.profilePictureId}`
                              ) : (
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 23 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="_verified_dbbth_91"
                                >
                                  <path
                                    d="M22.5 11L20.06 8.21L20.4 4.52L16.79 3.7L14.9 0.5L11.5 1.96L8.1 0.5L6.21 3.69L2.6 4.5L2.94 8.2L0.5 11L2.94 13.79L2.6 17.49L6.21 18.31L8.1 21.5L11.5 20.03L14.9 21.49L16.79 18.3L20.4 17.48L20.06 13.79L22.5 11ZM8.88 15.01L6.5 12.61C6.11 12.22 6.11 11.59 6.5 11.2L6.57 11.13C6.96 10.74 7.6 10.74 7.99 11.13L9.6 12.75L14.75 7.59C15.14 7.2 15.78 7.2 16.17 7.59L16.24 7.66C16.63 8.05 16.63 8.68 16.24 9.07L10.32 15.01C9.91 15.4 9.28 15.4 8.88 15.01Z"
                                    fill="#4AABED"
                                  ></path>
                                </svg>
                              )
                                })`,
                            }}
                          ></div>
                          <div className='creators-row-text'>
                            <Tooltip title={<span className="tooltip-text"> {user.firstName || user.lastName
                              ? `${user.firstName || ''} ${user.lastName || ''} ${!enterpriseClient && user.role
                                ? `(${user.role.substring(0, 2)})`
                                : ""
                              }`
                              : ""}
                            </span>} arrow>
                              <div className='creators-row-text' style={{ width: '18ch' }}>
                                {user.firstName || user.lastName
                                  ? `${user.firstName || ''} ${user.lastName || ''} ${!enterpriseClient && user.role
                                    ? `(${user.role.substring(0, 2)})`
                                    : ""
                                  }`
                                  : ""}
                              </div>
                            </Tooltip>
                            <Tooltip title={<span className="tooltip-text">  {enterpriseClient
                              ? ( user.billingDetails.bussinessName
                                ? user.billingDetails.bussinessName
                                : '')
                              : (user.instaHandle ? `@${user.instaHandle}` : '')
                            } </span>} arrow>
                              <div className='creators-row-text business-name-text' style={{ width: '18ch' }}>
                                {enterpriseClient ? `(${user.billingDetails.bussinessName})` :
                                  <a
                                    className="influencer-handle-block influencer-handle"
                                    href={`https://www.instagram.com/${user.instaHandle}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <span>{user.instaHandle ? `@${user.instaHandle}` : ''}</span>
                                  </a>
                                }
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </TableCell>

                      {!enterpriseClient && (
                        <TableCell sx={{ maxWidth: 100, ...TableCellStyledBox }}>
                          <Tooltip title={<span className="tooltip-text">{formatNumber(user.instaInsights.followersCount)} </span>} arrow>
                            <div  >{formatNumber(user.instaInsights.followersCount)}</div>
                          </Tooltip>
                        </TableCell>

                      )}
                      {!enterpriseClient && (

                        <TableCell sx={{ maxWidth: 100, ...TableCellStyledBox }}>
                          <Tooltip title={<span className="tooltip-text">{formatNumber(user.instaInsights.engagement)}({formatNumber(user.instaInsights.engagementRate)}) </span>} arrow>
                            <div  >{formatNumber(user.instaInsights.engagement)}({formatNumber(user.instaInsights.engagementRate)})</div>
                          </Tooltip>
                        </TableCell>
                      )}
                      {/* {!enterpriseClient && (
                          <TableCell sx={{ maxWidth: 100, ...TableCellStyledBox }}>
                            <Tooltip title={<span className="tooltip-text">{formatNumber(user.instaInsights.creatorScore)} </span>} arrow>
                              <div  >{formatNumber(user.instaInsights.creatorScore)}</div>
                            </Tooltip>
                            <Tooltip title={<span className="tooltip-text">{user.instaInsights.creatorRates !== 0
                              ? `(Rs. ${formatNumber(user.instaInsights.creatorRates)})`
                              : '(Ask for price)'} </span>} arrow>
                              <div>
                                {user.instaInsights.creatorRates !== 0
                                  ? `(Rs. ${formatNumber(user.instaInsights.creatorRates)})`
                                  : '(Ask for price)'}
                              </div>
                            </Tooltip>
                          </TableCell>
                        )} */}
                      <TableCell sx={{ maxWidth: 100, ...TableCellStyledBox }}>
                        <Tooltip title={<span className="tooltip-text">{user.location ? user.location.split("-")[0] : ""} </span>} arrow>
                          <div className='creators-row-text'>
                            {user.location ? user.location.split("-")[0] : ""}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ maxWidth: 100, overflowWrap: "break-word", ...TableCellStyledBox }}>
                        {formatDate(user.creationDate)}
                      </TableCell>
                      <TableCell sx={{ maxWidth: 100, ...TableCellStyledBox }}>

                        <div>
                          {user.areaOfInterests && user.areaOfInterests.length > 0 && (
                            <>
                              <Chip label={user.areaOfInterests[0]} />
                              {user.areaOfInterests.length > 1 && (
                                <Tooltip title={<span style={{ fontSize: '14px' }}>
                                  {user.areaOfInterests.slice(0).join(', ')}
                                </span>} arrow>
                                  <Chip label={`${user.areaOfInterests.length - 1}+`} />
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="View Details" arrow>
                          <VisibilityIcon sx={{ color: '#586a84' }} onClick={(event) => handleDialogOpen(event, user)} />
                        </Tooltip>
                        <Dialog
                          open={dialogOpen && user === selectedUser}
                          onClose={handleDialogClose}
                          aria-labelledby="user-info-dialog-title"
                        >
                          <DialogTitle id="user-info-dialog-title" >
                            <div className="dialog-title"> User Info</div>
                          </DialogTitle>
                          <DialogContent>
                            <Tooltip title={<span className="tooltip-text">{user.email}</span>} arrow>
                              <div className='creators-row-text dialog-content'>{user.email}</div>
                            </Tooltip>
                            <Tooltip title={<span className="tooltip-text">{user.phoneNumber}</span>} arrow>
                              <div className="influencer-handle-block influencer-handle creators-row-text dialog-content">{user.phoneNumber}</div>
                            </Tooltip>
                          </DialogContent>
                        </Dialog>
                        <MoreVertIcon sx={{ color: '#586a84' }} 
                          onClick={(event) => handleActionClick(event, user)}
                          className="creators-list-actions-icon"
                        />
                        <Menu
                          id="action-menu"
                          anchorEl={anchorElAction}
                          open={openAction && user === selectedUser}
                          onClose={handleActionClose}
                          MenuListProps={{
                            "aria-labelledby": "action-button",
                          }}
                        >
                          <MenuItem onClick={editUserProfile}>
                            <ListItemIcon>
                              <EditIcon fontSize="small" />
                            </ListItemIcon>
                            Edit Profile
                          </MenuItem>
                          {!enterpriseClient && (
                            <MenuItem
                              onClick={() => {
                                handleReload(user.instaHandle);
                              }}
                            >
                              <ListItemIcon>
                                {reloading ? (
                                  <CachedIcon
                                    fontSize="small"
                                    className="rotating"
                                  />
                                ) : (
                                  <CachedIcon fontSize="small" />
                                )}
                              </ListItemIcon>
                              Reload Insta Insights
                            </MenuItem>
                          )}
                          {enterpriseClient && (
                            <MenuItem
                              onClick={() => {
                                createCampaigns(user);
                              }}
                            >
                              <ListItemIcon>
                                <TrendingUpIcon fontSize="small" />
                              </ListItemIcon>
                              Create Campaigns
                            </MenuItem>
                          )}
                        </Menu>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <div className={windowWidth <= 799 ? 'userlist-table-mobile' : 'userlist-table-footer'}>
            <div className='userlist-table-footer'>
              <div>
                Showing {isLoading ? 0 : ((currentPage - 1) * recordsPerPage) + 1} - {isLoading ? 0 : Math.min(currentPage * recordsPerPage, totalCount)} of {isLoading ? 0 : totalCount}
              </div>
              <div className={windowWidth <= 799 ? 'go-to-page-mobile' : 'go-to-page'}>
                Go to page :
                <TextField type="number" id="outlined-size-normal" defaultValue="1" style={{ width: 'fit-content', backgroundColor: '#fff' }}
                  variant="outlined" value={goToPage} onKeyPress={handleKeyPress} onKeyDown={handleKeyDown}
                  onBlur={handleBlur} onChange={(e) => {
                    let value = parseInt(e.target.value, 10);
                    if (isNaN(value)) {
                      setGoToPage('');
                    } else if (value < 1 || value > Math.ceil(totalCount / recordsPerPage)) {
                      setGoToPage('');
                    } else {
                      setGoToPage(value);
                    }
                  }}
                  inputProps={{ min: 1, max: apiFailed || isLoading ? 1 : Math.ceil(totalCount / recordsPerPage) }} />
              </div>
            </div>
            <div className='userlist-table-footer'>
              <Box sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', columnGap: '13px' }}>
                <div>Records Per page :</div>
                <FormControl>
                  <Select sx={{ backgroundColor: '#fff', width: 'fit-content' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={recordsPerPage}
                    onChange={(e) => handleRowPerPageChange(e.target.value)}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <CommonPagination
                currentPage={currentPage}
                totalPages={Math.ceil(totalCount / recordsPerPage)}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </Paper>
      </Box>
      <DialogBox
        userDetails={selectedUser}
        openAlert={openBillingDetailAlert}
        setOpenAlert={setOpenBillingDetailAlert}
        enterpriseClient={true}
      />
      {openProfile && (
        <ProfileDialog
        userId={userId}
          openProfile={openProfile}
          onClose={handleProfileClose}
        />
      )}
    </>
  );
}

export default AdminTable;
