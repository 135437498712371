import React, { useContext } from 'react';
import ECLeadsOverview from './ec-leads-overview';
import ECLeadsManagement from './ec-leads-management';
import '../../../css/leads.css';
import { UserDetailsContext } from '../../context/user-details-context';

function ECLeadsDashboard() {
    const { loggedUser } = useContext(UserDetailsContext);
    return (
        <>
            <div className='ec-leads-management-text-row'>
                <div className='platform-growth-text'>EC Leads Management</div>
            </div>
            <div className='ec-leads-dashboard-divider'></div>
            {loggedUser && loggedUser.access.includes('Lead Dashboard:View') && <ECLeadsOverview />}
            <div className='ec-leads-dashboard-divider'></div>
            {loggedUser && loggedUser.access.includes('Lead:View') && <ECLeadsManagement />}
        </>
    )
}

export default ECLeadsDashboard;
