import { getConfig } from '../common/env-config';
import {refreshToken} from "./refresh-token-repo";

const { baseUrl } = getConfig();

export const getUserInsights = async (userId, setUserInsights, setLoading) => {
    const response = await fetch(`${baseUrl}/influencer/${userId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });
    const data = await response.json();
    if (response.ok) {
        setUserInsights(data);
    } else if (response.status === 499) {
        refreshToken();
    } else {
        console.error("Error occure while fetching user insights");
    }
    if (setLoading != null) {
        setLoading(false);
    }
}

export const reloadInstaInsights = async (username) => {
    const response = await fetch(`${baseUrl}/save-insta-insights/${username}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    });

    if (response.status === 499) {
        refreshToken();
    }
};
