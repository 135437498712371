import { Alert, Autocomplete, Button, ButtonGroup, Card, CardActions, CardContent, Checkbox, Dialog, DialogActions, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Snackbar, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { WindowWidthContext } from '../../context/user-details-context';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchECLead, fetchLeadSource, fetchLeadStatus, fetchLeadTypes, fetchLocations } from '../ec-leads-helper';
import { refreshToken } from '../../common/refresh-token-repo';
import { getConfig } from '../../common/env-config';
import ReactQuill from 'react-quill';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from '@mui/icons-material/Clear';
import PackageOfferDialog from './package-offer';
import { useLeadFilters } from '../../context/eclead-filter-context';

function InternalECleadForm() {
    const { baseUrl } = getConfig();
    const location = useLocation();
    const navigate = useNavigate();
    const windowWidth = useContext(WindowWidthContext);
    const { setLeadFilters } = useLeadFilters();
    const reactQuillRef = useRef();
    const ecLeadId = location.state?.ecLeadId;
    const fromPage = location.state?.fromPage;
    const [loading, setLoading] = useState(false);
    const [expanded, setSetExpanded] = useState(false);
    const [leadSource, setLeadSource] = useState([]);
    const [leadType, setLeadType] = useState([]);
    const [dbLocation, setDBLocations] = useState([]);
    const [leadStatus, setLeadStatus] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailMessage, setErrorMessage] = useState(null);
    const [packageOfferDialogOpen, setPackageOfferDialogOpen] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openReopenLeadDialog, setOpenReopenLeadDialog] = useState(false);
    const [disableStatus, setDisableStatus] = useState(false);
    const [leadDetails, setLeadsDetails] = useState({
        fullName: "", contactMobile: "", contactEmail: "", leadType: "", companyName: "",
        location: null, source: "", proposalSent: false, leadPackageId: null, status: "Prospect",
        probabilityStatus: null, mobileVerified: false, emailVerified: false, agentId: null, notes: [{ id: null, note: null, deleted: false, updatedOn: null, createdBy: null }], newNote: null,
        packageOffer: {
            packageId: null, packageName: "", packageDescription: "", updatedBy: null, packageOfferBy: "",
            updatedOn: null, deleted: false, leadPackageProducts: [{
                productType: { productTypeId: null, productTypeName: "" },
                product: {
                    productId: null, productName: "", productDescription: "",
                    productPrice: null, productPriceOffered: null
                }
            }
            ]
        }
    });

    const leadsDetailsTextField = [
        { id: 1, type: 'text', label: 'Client Full Name', value: leadDetails.fullName, name: 'fullName', length: 30, regex: /[^A-Za-z\s]/g },
        { id: 2, type: 'text', label: 'Contact Mobile', value: leadDetails.contactMobile, name: 'contactMobile', length: 10, regex: /\D/g, editable: leadDetails.mobileVerified },
        { id: 3, type: 'email', label: 'Email', value: leadDetails.contactEmail, name: 'contactEmail', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, editable: leadDetails.emailVerified }
    ];

    useEffect(() => {
        fetchLeadSource(setLeadSource);
        fetchLeadTypes(setLeadType);
        fetchLeadStatus(setLeadStatus);
        fetchLocations(setDBLocations);
        if (ecLeadId != null && ecLeadId !== undefined) {
            fetchECLead(setLeadsDetails, ecLeadId, setLoading, handleReopenLeadOpen);
        }
    }, [ecLeadId]);

    function getTextLengthWithoutHtml(html) {
        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        let text = tempDiv.textContent || tempDiv.innerText || "";
        return text.length;
    }

    const truncateTextWithoutHtml = (html, maxLength) => {
        let tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        let text = tempDiv.textContent || tempDiv.innerText || "";
        let truncatedText = text.substring(0, maxLength);
        let truncatedDiv = document.createElement('div');
        truncatedDiv.textContent = truncatedText;
        return truncatedDiv.innerHTML;
    };

    const handleLeadChange = (fieldName, value) => {
        const isSameValue = leadDetails[fieldName] === value;
        const isNotEmptyOrNull = value !== '' && value !== null;

        setLeadsDetails((leadDetails) => {
            if (fieldName === 'newNote') {
                const textLength = getTextLengthWithoutHtml(value);
                if (textLength <= 300) {
                    return {
                        ...leadDetails,
                        [fieldName]: value,
                    }
                } else {
                    const truncatedValue = truncateTextWithoutHtml(value, 300);
                    const editor = reactQuillRef.current.getEditor();
                    const length = getTextLengthWithoutHtml(truncatedValue);
                    setTimeout(() => editor.setSelection(length, length), 0);

                    return {
                        ...leadDetails,
                        [fieldName]: truncatedValue,
                    };
                }
            }

            if (fieldName === 'packageOffer') {
                if (value.packageId !== "") {
                    return {
                        ...leadDetails,
                        [fieldName]: value,
                        'proposalSent': true
                    };
                } else {
                    return {
                        ...leadDetails,
                        [fieldName]: value,
                        'proposalSent': false
                    };
                }
            }

            return {
                ...leadDetails,
                [fieldName]: value,
            };
        });

        if (isNotEmptyOrNull && !isSameValue) {
            setIsFormDirty(true);
        } else if (isSameValue) {
            setIsFormDirty(false);
        } else {
            setIsFormDirty(false);
        }
    };

    const disableButton = leadDetails.fullName === "" || leadDetails.contactMobile === "" ||
        leadDetails.companyName === "" || leadDetails.source === "" || leadDetails.leadType === "" ||
        leadDetails.contactEmail === "" || leadDetails.location === null || leadDetails.status === "" ||
        leadDetails.probabilityStatus === null || (leadDetails.contactMobile !== "" && leadDetails.contactMobile.length < 10) ||
        (leadDetails.fullName !== "" && leadDetails.fullName.length < 2) || (leadDetails.companyName !== "" && leadDetails.companyName.length < 2) ||
        (leadDetails.contactEmail !== "" && !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(leadDetails.contactEmail))

    const handleBack = () => {
        if (fromPage === 'View') {
            navigate("/enterprise-client/view-internal-ec-lead", { state: { ecLeadId } });
        } else {
            navigate("/enterprise-client/ec-leads-dashboard");
        }
    }

    useEffect(() => {
        if (isFormDirty) {
            const handleBeforeUnload = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    e.returnValue = message;
                    return message;
                }
            };

            const handleNavClick = (e) => {
                if (isFormDirty) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    if (!window.confirm(message)) {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            const handlePopState = (e) => {
                const currentPath = window.location.pathname;
                const monitoredPaths = ['/login', '/all-campaigns/create', '/my-campaigns/create', '/enterprise-client/registration', '/enterprise-client/create-internal-ec-lead'];
                if (isFormDirty && monitoredPaths.includes(currentPath)) {
                    const message = 'You have unsaved changes. Are you sure you want to leave?';
                    const userConfirmed = window.confirm(message);
                    if (!userConfirmed) {
                        window.history.pushState(null, '', window.location.pathname);
                    } else {
                        setIsFormDirty(false);
                    }
                }
            };

            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('popstate', handlePopState);
            window.history.pushState(null, '', window.location.pathname);

            const navLinks = document.querySelectorAll('.nav-link');
            navLinks.forEach((link) => {
                link.addEventListener('click', handleNavClick);
            });

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('popstate', handlePopState);
                navLinks.forEach((link) => {
                    link.removeEventListener('click', handleNavClick);
                });
            };
        }
    }, [isFormDirty]);

    const handleSuccessAlert = () => {
        setOpenSuccessAlert(true);
    };

    const saveInternalECLead = async () => {
        try {
            setIsFormDirty(false);
            const { fullName, contactMobile, contactEmail, leadType, companyName, location, source,
                proposalSent, packageName, status, probabilityStatus, newNote, packageOffer } = leadDetails;

            const response = await fetch(`${baseUrl}/admin/save-internal-ec-lead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                body: JSON.stringify({
                    leadId: ecLeadId, fullName, contactMobile, contactEmail, leadType, companyName, location, source,
                    proposalSent, packageName, status, probabilityStatus, newNote, packageOffer
                })
            });
            const responseBody = await response.json();
            if (!response.ok) {
                if (responseBody.message && responseBody.message && responseBody.message.includes("Email")) {
                    setEmailError(true);
                    setErrorMessage("Contact Email already exist");
                } else if (response.status === 499) {
                    refreshToken();
                } else {
                    console.error('Error saving internal ec lead details:', responseBody.message);
                }
            } else {
                handleSuccessAlert();
                if (!ecLeadId) {
                    setLeadFilters({
                        searchString: "", searchType: "", leadStatus: "", leadSource: "", leadType: "",
                        proposalSent: "", statusFrom: null, location: "", probability: "", agent: "", filterApply: false
                    });
                }
                setTimeout(() => {
                    if (fromPage === 'View') {
                        navigate("/enterprise-client/view-internal-ec-lead", { state: { ecLeadId } });
                    } else {
                        navigate("/enterprise-client/ec-leads-dashboard");
                    }
                }, 3000);
            }
        } catch (error) {
            alert("Failed to save internal ec lead details");
            console.error('Error saving internal ec lead details:', error);
        }
    }

    const handleToggleExpand = () => {
        setSetExpanded(!expanded);
    }

    const modules = {
        toolbar: [
            [{ 'header': [] }, { 'font': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link']
        ]
    }

    const formats = [
        'header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'link', 'color', 'background'
    ]

    const handleOpenPackageOfferDialog = () => {
        setPackageOfferDialogOpen(true);
    };

    const handleClosePackageOfferDialog = () => {
        setPackageOfferDialogOpen(false);
    };

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessAlert(false);
    };

    const handleReopenLeadOpen = () => {
        setOpenReopenLeadDialog(true);
    };

    const handleReopenLeadClose = () => {
        setOpenReopenLeadDialog(false);
        navigate('/enterprise-client/ec-leads-dashboard');
    };

    const changeLeadStatus = () => {
        handleLeadChange('status', 'Re-open');
        setOpenReopenLeadDialog(false);
        setDisableStatus(true);
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && (
                <Skeleton variant="rectangular" width="100%" height="100%"
                    sx={{
                        position: 'absolute', top: 0, left: 0, zIndex: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)'
                    }}
                />
            )}
            <Card sx={{ minWidth: 275, padding: '20px', position: 'relative', zIndex: 0, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <CardContent>
                    <Typography variant="body2" sx={{
                        flex: windowWidth <= 799 ? '1 1 100%' : '1 1 auto', color: '#3b4758', fontFamily: 'DM Sans',
                        fontSize: windowWidth <= 799 ? '24px' : '1.5rem', fontWeight: 600, opacity: 1,
                        paddingBottom: windowWidth <= 799 ? '12px' : '20px'
                    }}>
                        {ecLeadId ? 'Edit Lead' : 'Create Lead'}
                    </Typography>
                    <div className={windowWidth <= 799 ? 'create-lead-row-1 create-lead-row-1-mobile' : 'create-lead-row-1'}>
                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            {leadsDetailsTextField.map((leadDetail, index) => (
                                <TextField key={index} id="outlined-required" label={<span>
                                    {leadDetail.label}
                                    <span style={{ color: 'red' }}>*</span></span>} value={leadDetail.value}
                                    InputProps={{
                                        readOnly: leadDetail.editable,
                                        endAdornment: leadDetail.editable && (
                                            <InputAdornment position="end">
                                                <IconButton sx={{ color: "#4caf50", pointerEvents: "none" }}>
                                                    <CheckCircleIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const textInput = e.target.value;
                                        const textInputRegex = textInput.replace(leadDetail.regex, '');
                                        handleLeadChange(leadDetail.name, leadDetail.name === 'contactEmail' ? textInput : textInputRegex);
                                    }} inputProps={{ maxLength: leadDetail.length }}
                                    error={(leadDetail.name === 'contactMobile' && leadDetails.contactMobile !== "" && (!ecLeadId && leadDetails.contactMobile.length !== 10)) ||
                                        (leadDetail.name === 'contactEmail' && (emailError || (leadDetails.contactEmail != null && leadDetails.contactEmail !== '' && !leadDetail.regex.test(leadDetails.contactEmail)))) ||
                                        (leadDetail.name === 'fullName' && leadDetails.fullName !== "" && leadDetail.value.length < 2)
                                    }
                                    helperText={leadDetail.name === 'contactMobile' && leadDetails.contactMobile !== "" && (!ecLeadId && leadDetails.contactMobile.length !== 10)
                                        ? 'Enter a valid 10-digit number'
                                        : (leadDetail.name === 'fullName' && leadDetails.fullName !== "" && leadDetail.value.length < 2)
                                            ? 'Length must be at least 2 characters'
                                            : leadDetail.name === 'contactEmail' && emailMessage != null && emailError
                                                ? emailMessage
                                                : leadDetail.name === 'contactEmail' && leadDetails.contactEmail != null && leadDetails.contactEmail !== '' && !leadDetail.regex.test(leadDetails.contactEmail)
                                                    ? 'Please enter valid email address'
                                                    : ''
                                    } />
                            ))}
                        </div>

                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <TextField id="outlined-required" name="companyName" label={<span>Company/ Brand Name<span style={{ color: 'red' }}>*</span></span>}
                                value={leadDetails.companyName}
                                onChange={(e) => handleLeadChange('companyName', e.target.value)} inputProps={{ maxLength: 50 }}
                                error={leadDetails.companyName !== "" && leadDetails.companyName.length < 2}
                                helperText={leadDetails.companyName !== "" && leadDetails.companyName.length < 2 ? 'Length must be at least 2 characters' : ''} />

                            <Autocomplete disablePortal id="combo-box-demo" name="location" value={dbLocation.find(
                                (option) => `${option.city} - ${option.state}` === leadDetails.location
                            ) || null} options={dbLocation}
                                onChange={(e, value) => handleLeadChange('location', value ? `${value.city} - ${value.state}` : null)}
                                getOptionLabel={(option) => `${option.city} - ${option.state}`}
                                renderInput={(params) => <TextField {...params} label={<span>Location<span style={{ color: 'red' }}>*</span></span>} />}
                            />
                        </div>
                    </div>
                    <Divider sx={{ margin: '7px 0px', borderWidth: '1px', borderColor: 'rgb(0 0 0 / 21%)' }} />
                    <div className={windowWidth <= 799 ? 'create-lead-row-1 create-lead-row-1-mobile' : 'create-lead-row-1'}>
                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{<span>Source<span style={{ color: 'red' }}>*</span></span>}</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Source"
                                    value={leadDetails.source} name="source" onChange={(e) => handleLeadChange('source', e.target.value)} >
                                    {leadSource.map((source) => (
                                        <MenuItem key={source.id} value={source.source}>
                                            {source.source}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{<span>Lead Type<span style={{ color: 'red' }}>*</span></span>}</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={leadDetails.leadType} name="leadType" label="Lead Type" onChange={(e) => handleLeadChange('leadType', e.target.value)}>
                                    {leadType.map(type => (
                                        <MenuItem key={type.id} value={type.leadtype}>{type.leadtype}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={windowWidth <= 799 ? 'create-lead-col create-lead-col-mobile' : 'create-lead-col'}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{<span>Status<span style={{ color: 'red' }}>*</span></span>}</InputLabel>
                                <Select disabled={disableStatus} labelId="demo-simple-select-label" id="demo-simple-select"
                                    value={leadDetails.status} name="status" label="Status"
                                    onChange={(e) => handleLeadChange('status', e.target.value)}>
                                    {leadStatus
                                        .filter(status => status.status !== "Re-open" || disableStatus || leadDetails.status === "Re-open")
                                        .map(status => (
                                            <MenuItem key={status.id} value={status.status}>
                                                {status.status}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className={windowWidth <= 799 ? 'create-lead-row-2 create-lead-row-2-mobile' : 'create-lead-row-2'}>
                        <div className='proposal-sent-col-1'>
                            {leadDetails.proposalSent
                                ? <FormControlLabel control={<Checkbox name="proposalSent" checked={leadDetails.proposalSent} sx={{
                                    color: '#f48fb1', '&.Mui-checked': { color: '#f48fb1' },
                                }} />} label="Proposal Sent" />
                                : ecLeadId && <FormControlLabel control={<Checkbox name="proposalSent" checked={!leadDetails.proposalSent} checkedIcon={<ClearIcon />} sx={{
                                    color: '#f48fb1', '&.Mui-checked': { color: '#f48fb1' },
                                }} />} label="Proposal Not Sent" />
                            }
                            <Button variant="contained" sx={{
                                backgroundColor: '#e563a4', '&:hover': {
                                    backgroundColor: '#e326a4'
                                }
                            }} onClick={handleOpenPackageOfferDialog}>Select Proposal To Send</Button>
                        </div>
                        {leadDetails.proposalSent &&
                            <div className={windowWidth <= 799 ? 'proposal-sent-col-2 proposal-sent-col-2-mobile' : 'proposal-sent-col-2'}>
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <Typography variant="h6">
                                            {leadDetails.packageOffer.packageName}
                                        </Typography>
                                        {leadDetails.packageOffer.leadPackageProducts.map((item) => (
                                            <Typography variant="body2" color="text.secondary">
                                                {`${item.productType.productTypeName}, ${item.product.productName}, ${item.product.productPriceOffered}`}
                                            </Typography>
                                        )
                                        )}
                                    </CardContent>
                                </Card>
                            </div>
                        }
                    </div>
                    <div className='create-lead-row-3'>
                        <Typography variant="body2" sx={{ fontSize: '1rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            Notes
                        </Typography>
                    </div>
                    <div className='create-lead-row-3'>
                        {(leadDetails.notes
                            .filter(note => !note.deleted))
                            .sort((a, b) => b.id - a.id)
                            .slice(0, expanded ? leadDetails.notes.length : 2).map((notes, index) => (
                                notes.note != null
                                    ? (
                                        <Card sx={{ minWidth: 275, marginBottom: '12px' }}>
                                            <CardContent sx={{ padding: '12px 12px 0px 12px', '&.MuiCardContent-root:last-child': { paddingBottom: '0px !important' } }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                                        {notes.updatedOn}
                                                    </Typography>
                                                    <Typography color="text.secondary">
                                                        {notes.createdBy}
                                                    </Typography>
                                                </div>
                                                <Typography dangerouslySetInnerHTML={{ __html: notes.note }} />
                                            </CardContent>
                                        </Card>
                                    ) : (
                                        <Typography key={index} variant="body2" sx={{
                                            fontSize: 'x-large', display: 'flex', justifyContent: 'center', color: 'gray',
                                            textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'
                                        }} >
                                            No Notes Added
                                        </Typography>
                                    )
                            ))}
                        {leadDetails.notes.length > 2 && <Button sx={{
                            disaplay: 'flex', justifyContent: 'flex-end', color: '#e563a4', '&:hover': { color: '#e326a4' }
                        }} onClick={() => handleToggleExpand()}>{expanded ? 'Show Less' : 'Read More'}</Button>}
                    </div>
                    <div className='create-lead-note-row'>
                        <ReactQuill className="note-input-box" theme="snow"
                            ref={reactQuillRef} name="newNote" value={leadDetails.newNote}
                            onChange={(value) => handleLeadChange("newNote", value)}
                            placeholder="Type here.." modules={modules} formats={formats}
                        />
                    </div>
                    <div className='create-lead-row-4'>
                        <Typography variant="body2" sx={{ fontSize: '1rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingBottom: '12px' }}>
                            Probability<span style={{ color: 'red' }}>*</span>
                        </Typography>
                        <ButtonGroup color="secondary" variant="outlined" aria-label="Basic button group">
                            <Button onClick={() => handleLeadChange("probabilityStatus", "High")} variant={leadDetails.probabilityStatus === 'High' ? 'contained' : 'outlined'}
                                sx={{
                                    '&.Mui-disabled': {
                                        color: leadDetails.probabilityStatus === 'High' ? 'white' : '#9c27b0',
                                        backgroundColor: leadDetails.probabilityStatus === 'High' ? '#9c27b0' : 'transparent'
                                    }
                                }} >High</Button>
                            <Button onClick={() => handleLeadChange("probabilityStatus", "Medium")} variant={leadDetails.probabilityStatus === 'Medium' ? 'contained' : 'outlined'}
                                sx={{
                                    '&.Mui-disabled': {
                                        color: leadDetails.probabilityStatus === 'Medium' ? 'white' : '#9c27b0',
                                        backgroundColor: leadDetails.probabilityStatus === 'Medium' ? '#9c27b0' : 'transparent'
                                    }
                                }} >Medium</Button>
                            <Button onClick={() => handleLeadChange("probabilityStatus", "Low")} variant={leadDetails.probabilityStatus === 'Low' ? 'contained' : 'outlined'}
                                sx={{
                                    '&.Mui-disabled': {
                                        color: leadDetails.probabilityStatus === 'Low' ? 'white' : '#9c27b0',
                                        backgroundColor: leadDetails.probabilityStatus === 'Low' ? '#9c27b0' : 'transparent'
                                    }
                                }} >Low</Button>
                        </ButtonGroup>
                    </div>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button className="nav-link" variant="outlined" sx={{ color: '#e563a4', '&:hover': { color: '#e326a4' } }} onClick={handleBack}>Back</Button>
                    <Button variant="contained" sx={{
                        backgroundColor: '#e563a4', '&:hover': { backgroundColor: '#e326a4' }
                    }} disabled={disableButton} onClick={saveInternalECLead}>{ecLeadId ? 'Update Lead' : 'Create Lead'}</Button>
                </CardActions>
            </Card>
            <PackageOfferDialog fromPage={ecLeadId ? 'Edit Lead' : 'Create Lead'} open={packageOfferDialogOpen} onClose={handleClosePackageOfferDialog} handleLeadChange={handleLeadChange} packageOfferDetails={leadDetails.packageOffer} />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openSuccessAlert} autoHideDuration={3000} onClose={handleSuccessAlertClose}
            >
                <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
                    Record saved successfully!
                </Alert>
            </Snackbar>
            <Dialog open={openReopenLeadDialog} onClose={handleReopenLeadClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure want to reopen lead?"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleReopenLeadClose}>No</Button>
                    <Button onClick={changeLeadStatus} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default InternalECleadForm;
