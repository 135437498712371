import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { refreshToken } from '../common/refresh-token-repo';

function Protected(props) {
    const { Component } = props;
    const navigate = useNavigate();
    const pathname = window.location.pathname;

    const UserProfile = localStorage.getItem('profileExist');
    const UserRefreshToken = localStorage.getItem('refreshToken');
    const UserRole = localStorage.getItem('role');

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (!UserRefreshToken) {
            console.log('object')
            navigate('/');
        } else if (UserRefreshToken === "null") {
            console.log('object2')
            navigate('/signin')
        } else if (!UserProfile || UserProfile === "false") {
            console.log('object3')
            navigate('/login')
        } else if (UserRefreshToken && currentPath === '/') {
            console.log('object4')
            refreshToken();
            const UserRefreshTokenExpired = localStorage.getItem('refreshTokenExpired');

            if (UserRefreshTokenExpired === true) {
                navigate('/signin');
            } else {
                if (UserRole === 'ADMIN') {
                    navigate('/admin-dashboard');
                } else if (UserRole === 'CREATOR') {
                    navigate('/dashboard');
                } else if (UserRole === 'CREATOR') {
                    navigate('/discovery');
                } else if (UserRole === 'LEAD_AGENT') {
                    navigate('/enterprise-client/ec-leads-dashboard');
                } else if (UserRole === 'LEAD_MANAGER') {
                    navigate('/enterprise-client/ec-leads-dashboard');
                } else {
                    navigate('/error');
                }
            }
        }
    }, [UserRefreshToken, UserProfile, UserRole, pathname, navigate]);
    return (
        <Component />
    );
}
Protected.propTypes = {
    Component: PropTypes.any
};
export default Protected;